//
//
//
//
//

export default {
  props: {
    color: {
      default: '#212A4B',
      type: String
    }
  }
}
