//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    dotOpacity: {
      type: Number,
      default: 0.5,
    },
    dotColor: {
      type: String,
      default: '#212A4B',
    },
    dotHoverColor: {
      type: String,
      default: '#00a769',
    },
    active: {
      type: Boolean,
      default: false,
    },
    isVertical: {
      type: Boolean,
      default: true,
    },
    crmStyle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      style: {
        '--dot-color': this.dotColor,
        '--dot-opacity': this.dotOpacity,
        '--dot-hover-color': this.dotHoverColor,
      },
    };
  },
  methods: {
    onClickOutside(event) {
    },
  },
};
