//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    size: {
      type: [String, Number],
      default: () => '18'
    }
  }
}
