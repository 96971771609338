//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
name: "ArrowCodeIcon"
}
