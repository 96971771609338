//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import provideDetails from "~/components/mixins/providePaymentMixin";

export default {
  mixins: [ provideDetails ],
  data() {
    return {
      card: {
        brand: null,
        last4: null,
        exp_month: null,
        exp_year: null
      }
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    async show(newVal, oldVal){
      if (newVal && !this.card.last4) {
        await this.getCard();
      }
    },
  },
  methods: {
    async getCard() {
      try {
        const { data } = await this.$axios.get(`/api/v1/payment/get-card`);
        this.card = data.result;
        this.card.exp_year = this.card.exp_year.toString().slice(2);
      } catch (e) {
        console.log(e);
      }
    },
  }
}
