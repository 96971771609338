//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {cloneDeep} from 'lodash/fp';
import {mapActions, mapGetters} from "vuex";
import CloseIcon from '~/assets/icons/cross/CloseIcon.svg?inline'

export default {
  name: "WorkBookAdditionalQuestions",
  components: {
    CloseIcon,
  },
  props: {
    numberTop: {
      type: Number,
      default: 0
    },
    blockIndex: {
      type: Number,
      default: 0
    },
    updateTop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
    }),
    questionsList() {
      return this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers
    },
  },
  methods: {
    ...mapActions({
      changeAnswer: 'account/workbook/changeAnswer',
      addAnswer: 'account/workbook/addAnswer',
      deleteAnswer: 'account/workbook/deleteAnswer',
    }),
    getQuestion(questionIndex) {
      return this.questionsList[questionIndex].value.question
    },
    getValue(questionIndex, answerIndex) {
      return this.questionsList[questionIndex].value.answers[answerIndex]
    },
    handlerUpdateQuestion(questionIndex, value) {
      const savedValue = cloneDeep(this.questionsList[questionIndex])

      savedValue.value.question = value
      this.changeAnswer({blockIndex: this.blockIndex, questionIndex, value: savedValue.value})
    },

    handlerUpdateValue(questionIndex, answerIndex, value) {
      const savedValue = cloneDeep(this.questionsList[questionIndex])

      savedValue.value.answers[answerIndex] = value
      this.changeAnswer({blockIndex: this.blockIndex, questionIndex, value: savedValue.value})
    },

  }
}
