//
//
//
//
//
//
//

import logo from "~/assets/images/fdp-logo-pages.svg?inline"

export default {
  name: "LogoWithTradeMark",
  components: {
    logo,
  }
}
