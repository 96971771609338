//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StatusDot from "@/components/icons/StatusDot";
import CheckOutlinedIcon from '~/assets/images/svg/check-outlined.svg?inline'
import {
  STATUS_TODO,
  STATUS_IN_PROGRESS,
  STATUS_DONE,
} from '~/store/constants'

export default {
  components: {
    StatusDot,
    CheckOutlinedIcon,
  },
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      STATUS_TODO,
      STATUS_IN_PROGRESS,
      STATUS_DONE,
    };
  },
  computed: {
    statusTodo() {
      return this.step.status === this.STATUS_TODO
    },
    statusInProgress() {
      return this.step.status === this.STATUS_IN_PROGRESS
    },
    statusDone() {
      return this.step.status === this.STATUS_DONE
    },
    messageLabel() {
      if (this.statusTodo) {
        return '';
      }

      if (this.statusInProgress) {
        if (this.step.count_new_messages) {
          return `${this.step.count_new_messages} New Message${
            this.step.count_new_messages !== 1 ? 's' : ''
          }`;
        }
      }

      return `${this.step.count_messages} Message${this.step.count_messages !== 1 ? 's' : ''}`
    },
  },
  methods: {
    /**
     * Go to schedule call
     */
    goToScheduleCall() {
      window.open(this.step.schedule_call_link, '_blank')
    },
  },
};
