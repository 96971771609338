import { render, staticRenderFns } from "./PendingList.vue?vue&type=template&id=ffe378c4&scoped=true&"
import script from "./PendingList.vue?vue&type=script&lang=js&"
export * from "./PendingList.vue?vue&type=script&lang=js&"
import style0 from "./PendingList.vue?vue&type=style&index=0&id=ffe378c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffe378c4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Confetti: require('/root/repo/components/Confetti.vue').default,VButton: require('/root/repo/components/VButton.vue').default})
