//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  CONTACT_ACTIONS_MENU
} from "~/store/constants"

export default {
  props: {
    activeId: {
      type: String,
      default: CONTACT_ACTIONS_MENU.tasks.id,
    }
  },
  data() {
    return {
      menu: CONTACT_ACTIONS_MENU,
    }
  },
  methods: {
    openItem(id) {
      this.$emit('open', id)
    }
  }
}
