//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Popper from 'vue-popperjs';
import ThreeDotMenuItem from '~/components/menu/ThreeDotMenuItem'
import 'vue-popperjs/dist/vue-popper.css';
import ThreeDotButton from "@/components/buttons/ThreeDotButton";

export default {
  components: {
    ThreeDotButton,
    ThreeDotMenuItem,
    Popper,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
    isVertical: {
      type: Boolean,
      default: true,
    },
    crmStyle: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * Click three dot button handler
     */
    handleClickThreeDot() {
      this.$emit('click')
    },
    /**
     * Click item handler
     */
    handleClickItem() {
      this.$emit('toggle')
    },
    /**
     * Hide menu event
     */
    onHide() {
      this.$emit('hide')
    }
  },
};
