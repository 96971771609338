//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowCodeIcon from "@/assets/icons/arrows/ArrowCodeIcon.svg?inline"
import {
  ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS,
  ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS_ITEMS_LIST
} from "@/store/constants"
import DateRangePicker from "vue2-daterange-picker"
import "vue2-daterange-picker/dist/vue2-daterange-picker.css"

export default {
  name: "VSelectDateReminderDefault",
  components: {
    ArrowCodeIcon,
    DateRangePicker,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    dueDateTime: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    toggleShowDropdown: false,
    toggleShowDatePicker: false,
    dateRange: {
      startDate: '',
      endDate: '',
    },
  }),
  computed: {
    itemsArr() {
      const items = ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS_ITEMS_LIST.filter(item => {
        const findIndex = this.items.findIndex(dataItem => dataItem === item.value)

        return findIndex >= 0
      })

      return items
    },
    todayDate() {
      const today = new Date();
      return today.setDate(today.getDate() - 1);
    },
    selectedText() {
      if (this.value === '')
        return 'None'

      const dateTime = new Date(this.value)
      const date = dateTime.toDateString().substring(4,16)
      const time = dateTime.toTimeString().substring(0,5)
      return `${date}, ${time}`
    },
    showDropdown() {
      return this.toggleShowDropdown || this.toggleShowDatePicker
    },
    localeConfig() {
      return {
        direction: 'ltr',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        firstDay: 0
      }
    },
  },
  methods: {
    generateByPresetRules(item, day) {
      if (item.value === ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS.ONE_HOUR_BEFORE)
        day.setHours(day.getHours() - 1)

      if (item.value === ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS.ONE_DAY_BEFORE)
        day.setDate(day.getDate() - 1)

      if (item.value === ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS.ONE_WEEK_BEFORE)
        day.setDate(day.getDate() - 7)

      return day
    },
    getText(item) {
      return item.text
    },
    isDisabled(item) {
      if (this.dueDateTime === '')
        return true

      const dueTime = new Date(this.dueDateTime)
      const itemTime = this.generateByPresetRules(item, dueTime)
      const currentTime = new Date()
      return itemTime.getTime() <= currentTime.getTime()
    },
    handlerToggleOpen(hover = false) {
      if (this.disabled)
        return

      if (this.toggleShowDropdown) {
        this.toggleShowDropdown = false
        return
      }

      if (this.toggleShowDatePicker) {
        this.toggleShowDatePicker = false
        return
      }

      if (hover && !this.toggleShowDropdown)
        return

      this.toggleShowDropdown = true
    },
    handlerSelectValue(item) {
      if (item.value === ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS.CUSTOM_DATE) {
        this.toggleShowDropdown = false
        this.toggleShowDatePicker = true
        return
      }

      let day = new Date(this.dueDateTime)
      day = this.generateByPresetRules(item, day)
      this.$emit('change', this.mutateDateValue(day))
      this.toggleShowDropdown = false
    },
    handlerUpdateDatePicker(time) {
      if (time.startDate === null)
        return

      this.toggleShowDatePicker = false
      this.$emit('change', this.mutateDateValue(time.startDate))
    },
    mutateDateValue(value) {
      const mm = value.getMonth() + 1
      const dd = value.getDate()
      return `${value.getFullYear()}-${(mm > 9 ? '' : '0')}${mm}-${(dd > 9 ? '' : '0')}${dd} ${value.getHours()}:${value.getMinutes()}`
    },
  }
}
