//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseButton from "@/components/buttons/BaseButton";
import FranchisorIcon from "~/assets/images/sign-up/franchisor.svg?inline";
import FranchiseeIcon from "~/assets/images/sign-up/franchisee.svg?inline";
import CloseIcon from "~/assets/images/sign-up/close.svg?inline";

import { USER_TYPE_FRANCHISOR, USER_TYPE_FRANCHISEE } from '~/store/constants';
import storeMixin from "../../mixins/storeMixin";

export default {
  components: {
    BaseButton,
    FranchisorIcon,
    FranchiseeIcon,
    CloseIcon,
  },
  mixins: [
    storeMixin
  ],
  props: {
    nextStage: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    USER_TYPE_FRANCHISOR,
    USER_TYPE_FRANCHISEE,
  }),
  methods: {
    closeModal () {
      this.$store.dispatch('registration/closeModal');
    },
    selectUserType(userType) {
      if (userType === USER_TYPE_FRANCHISOR) {
        this.$router.push({ path: this.$route.path, query: { step_register_franchisor: '1' }})
        this.$store.dispatch('registration/setUserType', userType);
        this.$store.dispatch('registration/setStage', this.nextStage);
      } else {
        this.showFranchiseeFastRegistration()
        this.$emit('close')
      }
    },
    showLoginModal() {
      this.$store.dispatch('registration/closeModal', false);
      this.$store.dispatch('login/openModal');
    },
  },
};
