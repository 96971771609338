//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "AccountButtons",
  props: {
    type: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#00A769'
    }
  }
}

