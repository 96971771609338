//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClearIcon from "@/components/icons/ClearIcon";
import BaseModal from './BaseModal';
import ModalAddDocument from './ModalAddDocument';
import DocumentList from "@/components/lists/DocumentList";
import LinkButton from "@/components/buttons/LinkButton";

export default {
  name: 'ModalDocumentsList',
  components: {LinkButton, DocumentList, ClearIcon, BaseModal, ModalAddDocument },
  props: {
    items: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bodyClass: 'modal-document-scoped',
      contentClass: 'modal-content-class',
      modalClass: 'modal-content-class',
      modalSize: 'xs',
      mobileTabs: {
        messages: {
          title: 'Messages',
          id: 'chat__contacts',
          active: true,
        },
        documents: {
          title: 'Documents',
          id: 'chat__documents',
          active: false,
        },
      },
      documentList: [],
      documentsLoading: false,
      total: 0,
      showModalNewDocument: false,
    };
  },
  methods: {
    /**
     * Click add new document handler
     */
    async handleClickAddNew() {
      this.showModalNewDocument = !this.showModalNewDocument;
    },
    /**
     * Adding document event
     * @param {Array} documents uploaded document
     */
    onDocumentAdd() {
      this.showModalNewDocument = false;
      this.fetchDocuments()
    },
    /**
     * Fetch business documents by business step id
     */
    async fetchDocuments() {
      this.documentsLoading = true;
      const { data } = await this.$axios.get(
        `/api/v1/business-step-docs/${this.items.business_step_id}`
      );
      this.documentList = data;
      this.documentsLoading = false;
    },
  },
};
