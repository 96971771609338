//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    arrowFill: {
      type: String,
      default: '#FFFFFF',
    },
    textColor: {
      type: String,
      default: '#FFFFFF',
    },
    linkTo: {
      type: String,
      default: '/',
    },
    isButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  },
};
