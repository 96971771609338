//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import { DOCUMENT_TYPE_PRESENTATION } from '~/store/constants';

export default {
  components: {
    Popper,
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
    editedName: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    document: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showMenuList: false,
      renameButtonText: 'Rename',
      renameButton: false,
      DOCUMENT_TYPE_PRESENTATION,
    };
  },
  computed: {
    ...mapGetters({
      subtypes: 'documents/subtypes',
    }),
  },
  methods: {
    /**
     * handle click outside
     */
    hideMenu() {
      if (this.$refs.rename.classList.contains('save') && !this.focused) {
        this.renameButton = !this.editedName;
      }
      if (this.renameButton) {
        this.showMenuList = false;
      }
    },
    showMenu() {
      this.renameButton = false;
      this.changeRenameButton();
      this.showMenuList = !this.showMenuList;
      this.$emit('dot-selected');
    },
    rename() {
      this.changeRenameButton();
      if (this.renameButton) {
        this.showMenuList = false;
      }
      this.$emit('rename', this.renameButton);
    },
    changeRenameButton() {
      const rename = this.$refs.rename;
      if (!this.renameButton) {
        rename.classList.remove('save');
        this.renameButtonText = 'Rename';
      } else {
        rename.classList.add('save');
        this.renameButtonText = 'Save';
      }
      this.renameButton = !this.renameButton;
    },
    remove() {
      this.showMenuList = false;
      this.$emit('remove');
    },
    /**
     * Click drop down item
     * @param {string} subtype
     */
    handleClickItem(subtype) {
      let docSubtype = subtype
      if (typeof this.document.doc_subtype !== 'undefined') {
        if (subtype === this.document.doc_subtype) {
          docSubtype = 'none'
        }
      }
      this.$emit('set-step-file-type', docSubtype)
    }
  },
};
