//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex"

import {
  MODAL_EDIT_FRANCHISE_ID,
} from "@/store/constants"
import storeMixin from "~/components/mixins/storeMixin"

export default {
  mixins: [
    storeMixin,
  ],
  data: () => ({
    MODAL_EDIT_FRANCHISE_ID,
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      franchise: 'business-v2/franchise',
      versionApproved: 'account-franchisor/versionApproved',
      paymentPermissions: 'payment/permissions'
    }),
    editorUrl() {
      const params = {
        franchiseId: this.franchise.id,
        versionId: this.franchise.version_id
      }

      return `/new/editor?franchise_id=${params.franchiseId}&version_id=${params.versionId}`
    },
  },
  methods: {
   async clickTasks(e) {
      e.preventDefault()
      const permissionType = this.paymentPermissions.CRM
      let allowAction = true
      let message = ''

      if (!this.versionApproved) {
        allowAction = false
        message = this.$t('accountFranchisor.accessMessages.notApprovedFranchisor')
      }

      if (!permissionType.access) {
        allowAction = false
        message = permissionType.message
      }

      if (!allowAction) {
        await this.fireModalMessage({ message })
        e.preventDefault()
        return false
      }
      if (this.user.isMasterAdmin) {
        window.open(`/admin/tasks/?franchise_id=${this.franchise.id}`, `_blank`).focus()
      } else {
        await this.$router.push(this.buildAccountFranchisorUri(`/account-franchisor/tasks/`))
      }
    },
    async clickContacts(e) {
      e.preventDefault()
      const permissionType = this.paymentPermissions.CRM
      let allowAction = true
      let message = ''

      if (!this.versionApproved) {
        allowAction = false
        message = this.$t('accountFranchisor.accessMessages.notApprovedFranchisor')
      }

      if (!permissionType.access) {
        allowAction = false
        message = permissionType.message
      }

      if (!allowAction) {
        await this.fireModalMessage({ message })
        e.preventDefault()
        return false
      }

      await this.$router.push(this.buildAccountFranchisorUri(`/account-franchisor/contacts`))
    },
    clickEditCompany () {
      if (this.user.isFranchisor) {
        if (!this.versionApproved) {
          this.fireModalMessage(
            { message: this.$t('accountFranchisor.accessMessages.notApprovedFranchisor') }
          )
          return
        }

        if (!this.paymentPermissions.editCompany.access) {
          this.fireModalMessage({ message: this.paymentPermissions.editCompany.message })
          return
        }
      }

      this.$bvModal.show(MODAL_EDIT_FRANCHISE_ID)
    }
  }
}
