export default {
  methods: {
    checkValueProps(value, isMoney = false, isPercent = false, isYear = false, isNumber = false) {
      if (value === '' || Number(value) === 0 || typeof value === "undefined")
        return 'N/A'

      if (isMoney)
        return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`

      if (isPercent)
        return `${value} %`

      if (isNumber)
        return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`

      if (isYear)
        return new Date(value).getFullYear()

      return value
    },
    getFranchiseDetailUrl(item){
      return `/franchise-directory/industries/${item.slug_industry}/${item.slug}`
    },
  }
}
