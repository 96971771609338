//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import {WORK_BOOK} from "@/store/constants";

export default {
  name: "WorkBookTableTotal",
  components: {},
  props: {
    blockIndex: {
      type: Number,
      default: 0
    },
  },
  data: () => ({
    currentFranchiseIndex: 0,
    totalCounter: {},
  }),
  watch: {
    workBooksStackCounter() {
      this.setTotalCounter()
    },
  },
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      workBooksStack: 'account/workbook/workBooksStack',
      workBooksStackCounter: 'account/workbook/workBooksStackCounter',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
    }),
    questionsBlock() {
      return WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex].questionsBlocks[this.blockIndex]
    },
    questionBlockId() {
      return `${this.currentSectionIndex}_${this.currentChapterIndex}_${this.blockIndex}`
    },
    totalName() {
      return this.questionsBlock.totalName ?? `Total`
    },
    blockList() {
      return this.questionsBlock.blockList
    },
  },
  created() {
    this.setTotalCounter()
  },
  methods: {
    setTotalCounter() {
      const totalFields = {}
      let block = {}
      this.blockList.forEach(blockId => {
        block = this.workBooksStack[blockId]
        if (!block) {
          return
        }
        Object.entries(block).forEach(([fieldId, fieldVal]) => {
          if (!totalFields[fieldId]) {
            totalFields[fieldId] = 0
          }
          totalFields[fieldId] += Number(fieldVal) ?? 0
        })
      })
      this.totalCounter = totalFields
    },
    getFieldStyle(field) {
      return {
        minWidth: field.minWidth ?? `auto`
      }
    },
  }
}
