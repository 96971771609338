var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[ { editable: _vm.editable } ]},[_c('div',{ref:"textBlock",class:[
      'edit-wrapper', {cutting:_vm.showReadMoreButton,open:_vm.showCollapsedBlock,editing: _vm.editing}
    ],style:({'--collapse-height':_vm.blockStyles.height,'--full-height':_vm.blockStyles.maxHeight})},[_c("div",{tag:"component",staticClass:"to-edit",class:{
        'content--hero_title':_vm.isHeroTitle,
        'content--hero_text':_vm.isHeroText,
        'content--title':_vm.isTitle,
        'content--text':_vm.isText,
        'content--text_big':_vm.isTextBig,
        'content--text_small':_vm.isTextSmall,
        'content--button':_vm.isButton},style:(_vm.css),domProps:{"innerHTML":_vm._s(_vm.content)}}),_vm._v(" "),_c('div',{staticClass:"by-edit"},[(_vm.editable)?_c('editor',{attrs:{"api-key":_vm.apiKey,"init":_vm.configEditor},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}):_vm._e(),_vm._v(" "),(!_vm.editable)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.content)}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"tools"},[_c('EditPencil',{staticClass:"edit-pencil edit-await",on:{"click":_vm.start}}),_vm._v(" "),_c('span',{staticClass:"edit-btn edit-progress",on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_vm._v(" "),_c('span',{staticClass:"edit-btn edit-progress",on:{"click":_vm.save}},[_vm._v("Save")])],1)],1),_vm._v(" "),(_vm.showReadMoreButton)?_c('button',{staticClass:"landing__read_more",class:{open:_vm.showCollapsedBlock},style:({'--color':_vm.mainStyles.backgroundColor}),on:{"click":function($event){_vm.showCollapsedBlock = !_vm.showCollapsedBlock}}},[_vm._v("\n    "+_vm._s(_vm.textReadMore)+"\n    "),_c('arrow-code-icon',{style:({'--fill':_vm.mainStyles.backgroundColor})})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }