//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Arrow from "@/assets/images/icons/arrow-right.svg?inline"

export default {
  name: 'BlogPostsSidebarCategory',
  components: {
    Arrow,
  },
  props: {
    categories: {
      type: Array,
      default: null,
    }
  },
  methods: {
    async enableLoader() {
      await this.$store.commit("blog/SET_STATUS_FETCH_DATA", true)
    },
  },
}
