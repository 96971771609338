//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BlogCategoryList',
  props: {
    categories: {
      type: Array,
      default: null,
    }
  },
  data() {
    return {
      classList: {
        long: `item-long`,
        short: `item-short`,
      },
    }
  },
  computed: {
    amount() {
      return this.categories.length
    }
  },
  methods: {
    isEven(e) {
      return e % 2 === 0
    },
    getClass(index) {
      const indexShifted = index + 1
      const indexList = [index, indexShifted]
      const lastRowLimit = 3

      let className = this.classList.long

      indexList.forEach( val => {
        if (
          this.isEven(val) &&
          !this.isEven(val / 2)
        ) {
          className = this.classList.short
        }
      })

      if (indexShifted === 1) className = this.classList.long

      if (
        !this.isEven(this.amount) &&
        this.amount - indexShifted < lastRowLimit
      ) className = this.classList.short

      return className
    },
    getCategoryLogo(category, index) {
      return this.getClass(index) === this.classList.short ? category.logo_short : category.logo_long
    }
  },
}
