//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowCodeIcon from "@/assets/icons/arrows/ArrowCodeIcon.svg?inline"

export default {
  name: "VSelectDefault",
  components: {
    ArrowCodeIcon,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: ''
    },
    items: {
      type: [Array, Object],
      default: () => ([]),
    },
    label: {
      type: String,
      default: '',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    itemText: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tasksField: {
      type: Boolean,
      default: false,
    },
    statusFilter: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    toggleShowDropdown: false,
  }),
  computed: {
    selectedText() {
      if (this.value === '')
        return this.placeholder

      const selectedItem = this.items.find(item => {
        const value = typeof item[this.itemValue] !== "undefined" ? item[this.itemValue] : ''
        return String(this.value) === String(value)
      })
      if (typeof selectedItem === "undefined")
        return this.placeholder

      return selectedItem[this.itemText]
    },
    showDropdown() {
      return this.toggleShowDropdown
    },
  },
  methods: {
    handlerClickOpen() {
      if (this.disabled)
        return

      this.toggleShowDropdown = !this.toggleShowDropdown
    },
    getText(item) {
      return typeof item[this.itemText] !== "undefined" ? item[this.itemText] : ''
    },
    handlerSelectValue(item) {
      const value = typeof item[this.itemValue] !== "undefined" ? item[this.itemValue] : ''
      this.$emit('change', value)
    },
    checkSelected(item) {
      const value = typeof item[this.itemValue] !== "undefined" ? item[this.itemValue] : ''
      return String(this.value) === String(value)
    },
  }
}
