//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  components: {},
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    businessStepId: {
      type: Number,
      default: 0
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  mounted() {
    this.getDocuments()
  },
  methods: {
    /**
     *
     * @param document
     * @returns {Promise<void>}
     */
    async download(document) {
      window.open(
        `${process.env.BACKEND_BASE_URL}/api/v1/business-step-docs/download/${document.step_doc_id}`
      );
    },
    async getDocuments() {
      try {
        const url = `/api/v1/business-step-docs/${this.businessStepId}`;
        const { data } = await this.$axios.get(url);
        this.documents = data;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
