//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    show: { type: Boolean, default: false },
    title: { type: String, default: null },
    description: { type: String, default: null }
  }
}
