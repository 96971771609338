//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from "vuex";
import storeMixin from "@/components/mixins/storeMixin";
import VButton from "@/components/VButton";

export default {
  name: "ApproveFranchiseVersionBtn",
  components: {
    VButton
  },
  mixins: [
    storeMixin
  ],
  data: () => ({
    isLoading: false
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      versionApproved: 'account-franchisor/versionApproved',
      versionId: 'account-franchisor/versionId',
    }),
    buttonText() {
      return `Approve${this.versionApproved ? 'd' : ''}`
    }
  },
  methods: {
    ...mapActions({
      approve: 'account-franchisor/approveVersion',
      fetchVersionInfo: 'account-franchisor/fetchVersionInfo',
    }),
    async handleApprove() {
      this.isLoading = true

      await this.approve()

      if (this.versionApproved) {
        await this.fetchVersionInfo({ versionId: this.versionId })
        this.fireModalMessage({ message: this.$t('accountFranchisor.versionApproved') })
      } else {
        this.fireModalMessage({ message: this.$t('error_alert_text') })
      }

      this.isLoading = false
    }
  }
}
