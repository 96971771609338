var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"three-dot-menu-wrapper"},[_c('div',{staticClass:"three-dot-menu"},[_c('popper',{attrs:{"trigger":"clickToToggle","append-to-body":true,"root-class":"three-dot-menu-popper","options":{
        placement: 'bottom',
        positionFixed: true,
        modifiers: { offset: { offset: '20,10' } },
      }},on:{"hide":_vm.onHide}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],class:[
          'three-dot-menu__list',
          'three-dot-menu-list',
          'popper',
          { 'crm-style' : _vm.crmStyle } ]},_vm._l((_vm.items),function(item,index){return _c('li',{key:index,staticClass:"three-dot-menu-list__item-wrapper"},[_c('three-dot-menu-item',{attrs:{"item":item},nativeOn:{"click":function($event){return _vm.$emit('action', index)}}})],1)}),0),_vm._v(" "),_c('three-dot-button',{staticClass:"three-dot-menu__button",attrs:{"slot":"reference","dot-opacity":1,"is-vertical":_vm.isVertical,"active":_vm.visible,"crm-style":_vm.crmStyle},on:{"click":_vm.handleClickThreeDot},slot:"reference"})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }