//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'vform'
import VInput from '~/components/form/VInput'

export default {
  components: {
    VInput
  },
  data: () => ({
    form: new Form({
      email: ''
    }),
    remember: false,
    status: '',
    message: ''
  }),
  methods: {
    async reset () {
      try {
        const { data } = await this.form.post('/api/password/email')

        this.setAlertFields(data.message, data.status)
        this.form.reset()
        this.$refs.resetForm.reset()
      } catch (e) {
        if (e.hasOwnProperty('response')) {
          if (e.response.status === 400 && e.response.data.status === 'fail') {
            this.setAlertFields(
              e.response.data.message,
              e.response.data.status
            )
          } else if (e.response.status !== 422) {
            this.$emit('close')
          }
        }
      }
    },
    cancel () {
      this.form.reset()
      this.$refs.resetForm.reset()
      this.$emit('cancel')
    },
    setAlertFields (message = '', status = '') {
      this.message = message
      this.status = status
    }
  }
}
