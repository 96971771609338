//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EyeIcon from '@/assets/icons/EyeIcon.svg?inline'
import EyeNotIcon from '@/assets/icons/EyeNotIcon.svg?inline'

export default {
  name: "VInputDefault",
  components: {
    EyeIcon,
    EyeNotIcon,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [String,Number],
      default: '',
    },
    label: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
    },
    mask: {
      type: String,
      default: '',
    }
  },
  data: () => ({
    inFocus: false,
    toggleShowPass: false,
  }),
  computed: {
    inputType() {
      return this.toggleShowPass ? 'text' : this.type
    },
  },
}
