//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseModal from "@/components/modals/BaseModal";
import {mapGetters, mapState} from "vuex";
import SearchFranchises from "~/components/SearchFranchises";

export default {
  components: {
    BaseModal,
    SearchFranchises
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      modalClass: 'modal-franchises-search',
      bodyClass: 'body-franchises-search',
      modalSize: 'xs',
    }
  },
  computed: {
    ...mapGetters({
      search: "search/type",
    }),
    ...mapState({
      searchType: state => state.search.type,
    }),
    modalClassGeneral() {
      return `${this.modalClass} ${this.searchType}`;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    }
  },
};
