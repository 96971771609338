export default {
  methods: {
    pmIsSeeFranchisesPage() {
      return this.$route.path === `/see-franchises`
    },
    pmIsOwnFranchiseStepsPage() {
      return this.$route.path === `/own-franchise-steps`
    },
    pmIsFranchisorContactTaskTab() {
      return this.$route.path.indexOf("/account-franchisor/contacts") !== -1 && this.$route.query.section === 'tasks'
    },
  },
}
