//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import landingStyles from "@/components/mixins/landingStyles";
import PlusIcon from "@/assets/icons/landing/PlusIcon.svg?inline"
import LandingImage from "@/components/landing/elements/LandingImage";
import SectionTools from "@/components/landing/elements/SectionTools";
import LandingText from "@/components/landing/elements/LandingText";
import LandingButton from "@/components/landing/elements/LandingButton";

export default {
  name: "LandingSectionCollapse",
  components: {
    LandingText,
    SectionTools,
    LandingImage,
    PlusIcon,
    LandingButton,
  },
  mixins: [
    landingStyles
  ],
  props: {
    section: {
      type: Object,
      default: () => {
      }
    },
    editable: {
      type: Boolean,
      default: false,
    },
    currentIndex: {
      type: Number,
      default: 0
    },
  },
  data: () => {
    return {
      showTools:false,
      containerSize: {
        width: 0,
        height: 0
      },
    }
  },
  computed: {
    backgroundImage(){
      const imageId = this.section.styles.backgroundImage ?? ''

      return (imageId === '')? '' :`${process.env.BACKEND_BASE_URL}/api/v2/landing/${imageId}/image`
    },
  },
  mounted() {
    if (this.editable)
      this.updateSectionSizes()
  },
  methods:{
    updateSectionSizes(){
      this.containerSize.width = this.$refs[`container_${this.section.id}`].clientWidth
      this.containerSize.height = this.$refs[`section_${this.section.id}`].clientHeight
    },
    updateSection() {
      this.updateSectionSizes()
      this.$store.dispatch('landing/updateSection', {section: this.section, index: this.currentIndex})
    },
  },
}
