//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowCodeBolderIcon from "@/assets/icons/arrows/ArrowCodeBolderIcon.svg?inline";

export default {
name: "LandingStickyButton",
  components:{
    ArrowCodeBolderIcon,
  },
  props:{
    editable:{
      type: Boolean,
      default: false
    }
  },
  data:() =>({
    isOpen: false
  }),
  methods:{
    handleToggle(){
      if (window.innerWidth < 1200){
        this.isOpen = !this.isOpen
        return
      }
      this.handleButton()
    },
    handleButton() {
      this.$router.push('/')
    },
  }
}
