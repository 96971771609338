//
//
//
//
//
//
//

export default {
name: "LikeIconV4"
}
