//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WorkBookInput from "@/components/workbook/elements/WorkBookInput";
import {mapActions, mapGetters} from "vuex"
import {cloneDeep} from 'lodash/fp'
import {
  WORK_BOOK,
  WORK_BOOK_FIELD_TYPES,
} from "@/store/constants"
import WorkBookTabLine from '~/components/workbook/elements/WorkBookTabLine'


export default {
  name: "WorkBookQuestionsOpenCustomTopEntry",
  components: {
    WorkBookInput,
    WorkBookTabLine,
  },
  props: {
    numberTop: {
      type: Number,
      default: 0
    },
    blockIndex: {
      type: Number,
      default: 0
    },
  },
  data: () => ({
    currentFranchiseIndex: 0,
    totalCounter: {},
    totalCalculated: [],
    differenceList: [],
    WORK_BOOK_FIELD_TYPES,
  }),
  computed: {
    ...mapGetters({
      topList: 'account/workbook/topList',
      franchise: 'account/workbook/franchise',
      workBooks: 'account/workbook/workBooks',
      workBooksStack: 'account/workbook/workBooksStack',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
      franchiseeNameList: 'account/workbook/franchiseeNameList',
    }),
    questionsBlock() {
      return WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex].questionsBlocks[this.blockIndex]
    },
    questionBlockId() {
      return `${this.currentSectionIndex}_${this.currentChapterIndex}_${this.blockIndex}`
    },
    questionBlockClassList() {
      return this.questionsBlock.classList ?? ``
    },
    itemsListAmount() {
      return this.questionsBlock.itemsListAmount ?? this.numberTop ?? 0
    },
    totalName() {
      return this.questionsBlock.calc.name ?? `Total`
    },
    totalFieldsAllowed() {
      const fields = []
      this.questionsBlock.fields.forEach(item => {
        if (item.hideTotal) {
          return
        }
        fields.push(item)
      })
      return fields
    },
    totalFields() {
      const fields = []
      this.questionsBlock.calc.fields.forEach(item => {
        fields.push(item.slug)
      })
      return fields
    },
  },
  created() {
    this.updateTotalCounter()
  },
  methods: {
    ...mapActions({
      changeAnswer: 'account/workbook/changeAnswer',
      setStack: 'account/workbook/setStack',
      resetStack: 'account/workbook/resetStack',
    }),
    getTabList(itemsListAmount, questionId) {
      const franchiseeNameList = this.franchiseeNameList(questionId)
      const tabList = []

      for (let index = 0; index < itemsListAmount; index += 1) {
        if(franchiseeNameList[index].length){
          tabList[index] = {
            name: franchiseeNameList[index],
          }
        }
      }
      return tabList
    },
    getTableFooterStyle() {
      return this.questionsBlock.calc.styleList ?? ``
    },
    getQuestionType(type) {
      return type ?? this.WORK_BOOK_FIELD_TYPES.DEFAULT
    },
    getQuestionIndex(index, questionIndex) {
      return index ?? questionIndex + 1
    },
    getTotalItemClass(field) {
      return field.classList ?? null
    },
    getFieldFranchiseName(field, fieldIndex) {
      const name = `Franchise #${fieldIndex + 1}`

      if (!field.relation) return ``

      if (
        !field.relation.key &&
        !field.relation.index &&
        field.relation.id === false
      ) return name

      if (!this.topList[field.relation.key]) return name
      if (!this.topList[field.relation.key][field.relation.id]) return name

      const currentFranchise = this.franchise.find(item => Number(item.id) === Number(this.topList[field.relation.key][field.relation.id]))
      if (typeof currentFranchise === "undefined") return name

      return currentFranchise.franchise
    },
    getPlaceholder(fieldIndex, itemIndex) {
      const placeholder = this.questionsBlock.fields[fieldIndex].placeholder
      const counter = this.isPlaceholderCounter(fieldIndex) ? `#${itemIndex}` : ``
      return placeholder ? `${placeholder} ${counter}` : ``
    },
    isPlaceholderCounter(fieldIndex) {
      return this.questionsBlock.fields[fieldIndex].placeholderCounter ?? true
    },
    getFieldStyle(field) {
      return field.styleList ?? ``
    },
    getCellStyle(fieldIndex) {
      return this.questionsBlock.fields[fieldIndex].cellStyleList ?? ``
    },
    getCellClass(fieldIndex) {
      return this.questionsBlock.fields[fieldIndex].cellClassList ?? ``
    },
    getInputStyle(fieldIndex) {
      return this.questionsBlock.fields[fieldIndex].inputStyleList ?? ``
    },
    getDollarSign(fieldIndex) {
      return this.questionsBlock.fields[fieldIndex].signDollar ?? ``
    },
    setFranchiseIndex(tabIndex) {
      this.currentFranchiseIndex = tabIndex
    },
    getValue(questionId, itemIndex, fieldIndex) {
      const slug = this.questionsBlock.fields[fieldIndex].slug
      const savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)

      return savedValue.value[itemIndex][slug]
    },
    getDifference(questionId, itemIndex, fieldIndex) {
      const fieldList = this.getField(fieldIndex).fieldList ?? []

      let slug = 0
      let savedValue = 0
      let numberValue = 0
      let difference = 0

      fieldList.forEach(field => {
        slug = this.questionsBlock.fields[field.id].slug
        savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)
        numberValue = Number(savedValue.value[itemIndex][slug]) ? Number(savedValue.value[itemIndex][slug]) : 0
        numberValue = field.negative ? numberValue * -1 : numberValue
        difference += numberValue
      })

      if (this.differenceList[questionId] !== difference)
        this.handlerUpdateValue(questionId, itemIndex, fieldIndex, difference)

      this.differenceList[questionId] = difference

      return difference
    },
    handlerUpdateValue(questionId, itemIndex, fieldIndex, value) {
      const slug = this.questionsBlock.fields[fieldIndex].slug
      const savedValue = cloneDeep(this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId))
      const questionIndex = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.findIndex(item => item.id === questionId)
      savedValue.value[itemIndex][slug] = value
      this.changeAnswer({blockIndex: this.blockIndex, questionIndex, value: savedValue.value})
      this.updateTotalCounter()
    },
    getField(fieldIndex) {
      return this.questionsBlock.fields[fieldIndex] ?? null
    },
    getFieldSlug(fieldIndex) {
      return this.questionsBlock.fields[fieldIndex].slug
    },
    getFieldType(fieldIndex) {
      return this.questionsBlock.fields[fieldIndex].type ?? ``
    },
    getCustomTotal(index, question) {
      const answersList = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers
      const questionType = this.getQuestionType(question.type)

      const fields = question.fields
      const id = question.id

      let total = 0
      let val = 0
      let shiftedAnswerId = 0
      let answerItem = {}

      this.totalCalculated[id] = 0

      answersList.forEach((answer, answerId) => {
        answerItem = this.questionsBlock.questions[answerId]
        shiftedAnswerId = answerId + 1

        if (fields.includes(shiftedAnswerId)) {
          answer.value.forEach((item, itemId) => {

            val = 0

            if (questionType === WORK_BOOK_FIELD_TYPES.TOTAL) {
              val = Number(item[this.getFieldSlug(index)]) ?? 0
            }

            if (questionType === WORK_BOOK_FIELD_TYPES.TOTAL_CALCULATED &&
              index === itemId
            ) {
              val = this.totalCalculated[shiftedAnswerId]
            }

            if (answerItem.negative) {
              val *= -1
            }

            total += val
          })
        }
      })

      this.totalCalculated[id] = total

      return total
    },
    getCustomTotalLine(question) {
      const answersList = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers
      const fields = question.fields

      let total = 0
      let shiftedAnswerId = 0
      let itemVal = 0
      let answerItem = null

      answersList.forEach((answer, answerId) => {
        shiftedAnswerId = answerId + 1
        answerItem = this.questionsBlock.questions[answerId]

        if (fields.includes(shiftedAnswerId)) {
          answer.value.forEach(item => {

            Object.values(item).forEach(val => {
              itemVal = Number(val) ? Number(val) : 0
              if (answerItem.negative) {
                itemVal *= -1
              }
              total += itemVal
            })

          })
        }
      })

      return total
    },
    updateTotalCounter() {
      const answersList = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers
      const counter = []
      let answerId = 0

      answersList.forEach(answer => {

        answer.value.forEach((item, itemIndex) => {
          answerId = itemIndex

          if (!counter[answerId]) {
            counter[answerId] = {}
          }

          Object.keys(item).forEach(key => {
            if (!counter[answerId][key]) {
              counter[answerId][key] = 0
            }
            counter[answerId][key] += Number(item[key]) ?? 0

          });

        })

      })


      this.totalCounter = counter[this.currentFranchiseIndex]
      if (this.questionsBlock.tableTotal) {
        this.setStack({key: this.questionBlockId, val: counter[this.currentFranchiseIndex]})
      }
    },
  }
}
