//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex'
import Form from 'vform'
import AutoCompleteCustom from "@/components/form/customField/AutoCompleteCustom"
import VAutoComplete from "@/components/form/VAutoComplete"

import {
  FRANCHISOR_FLOW_OPTIONS
} from '~/store/constants'

export default {
  components: {
    AutoCompleteCustom,
  },
  props: {
    stepIndex: {
      type: Number,
      default: 0,
    },
    disabled:{
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedBusiness: {
      id: '',
      name: '',
    },
    form: new Form({
      franchise_id: null,
      option: null,
    }),
    options: {
      add: FRANCHISOR_FLOW_OPTIONS.add,
      claim: FRANCHISOR_FLOW_OPTIONS.claim,
    },
    showConfirm: false,
    prevSubmittedOption: null,
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    ...mapState({
      option: (state) => state.registration.option,
      franchiseId: (state) => state.registration.franchiseId,
      franchise: (state) => state.registration.franchise,
    }),
  },
  watch: {
    selectedBusiness(value) {
      this.form.franchise_id = value.id ? value.id : null
    },
    option(newVal){
      this.form.option = newVal
    },
  },
  mounted() {
    this.form.franchise_id = this.franchiseId ?? null
    this.form.option = this.option ?? null
  },
  methods: {
    ...mapActions({
      fetchFranchise: 'registration/fetchFranchise',
    }),
    businessNameAutocompleteSource(search) {
      return `${this.$axios.defaults.baseURL}/api/v1/search/?business_name=${search}&autocomplete=1`
    },
    async submit() {
      try {
        const { data } = await this.form.post('/api/v1/business/flow/options')

        await this.$store.dispatch('registration/setFranchiseId', data.result.franchiseId)
        await this.$store.dispatch('registration/setOption', this.form.option)
        await this.fetchFranchise()

        this.showConfirm = true
        this.prevSubmittedOption = this.form.option

        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async selectOption(option) {
      if (this.form.busy) {
        return
      }

      if (this.prevSubmittedOption !== null && this.prevSubmittedOption !== option) {
        if (this.showConfirm) {
          const alertOptions = {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            animation: true,
          }

          try {
            await this.$confirm(
              'Your previous changes to brand info and files will be discarded.',
              'Are you sure?',
              'question',
              alertOptions
            )

            this.form.option = this.form.option === option ? null : option
          } catch (e) {}
        }
      } else {
        this.form.option = this.form.option === option ? null : option
      }

      if (this.form.option === this.options.add) {
        await this.$store.dispatch('registration/setAllowReclaim', true)
        this.selectedBusiness = {
          id: '',
          name: '',
        }
        this.form.franchise_id = null
        this.showConfirm = true

        this.$emit('nextStep')
      } else {
        await this.$store.dispatch('registration/setAllowReclaim', false)
      }
    },
  },
}
