import { render, staticRenderFns } from "./WorkBookQuestionnaire.vue?vue&type=template&id=084be970&scoped=true&"
import script from "./WorkBookQuestionnaire.vue?vue&type=script&lang=js&"
export * from "./WorkBookQuestionnaire.vue?vue&type=script&lang=js&"
import style0 from "./WorkBookQuestionnaire.vue?vue&type=style&index=0&id=084be970&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "084be970",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VButton: require('/root/repo/components/VButton.vue').default})
