//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VInput from "@/components/form/VInput";
import Form from 'vform'
import ClearIcon from "@/components/icons/ClearIcon";
import { mapGetters } from "vuex"
import { MODAL_CHANGE_EMAIL_ID } from "~/store/constants";

export default {
  name: "ModalChangeEmail",
  components: {
    VInput,
    ClearIcon
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  data: () => ({
    MODAL_CHANGE_EMAIL_ID,
    form: new Form({
      busy: false,
      email: '',
      email_confirmation: ''
    })
  }),
  methods: {
    async change() {
      try {
        await this.form.post('/api/email/change')
        this.$bvModal.hide(MODAL_CHANGE_EMAIL_ID)
        this.form.reset()
      } catch (e) {
        console.log(e)
      }
    },
  }
}
