//
//
//
//


import MoneyFormat from 'vue-money-format'

export default {
  components: {
    MoneyFormat
  },
  props: {
    value: {
      value: {
        type: Number,
        default: 0
      }
    }
  },
  data () {
    return {
      id: null,
      moneyElement: null,
      clearOffset: 1
    }
  },
  created () {
    this.id = this._uid
  },
  updated () {
    this.moneyElement = document.getElementById(this.id)
    this.moneyElement.innerText = this.moneyElement.innerText.substr(this.clearOffset)
  }
}

