//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BlogPostImage from "@/components/blog/BlogPostImage"
import DownloadContactForm from "@/components/modals/DownloadContactForm"

export default {
  name: 'BlogPost',
  components: {
    DownloadContactForm,
    BlogPostImage,
  },
  props: {
    released: {
      type: Number,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    author: {
      type: String,
      default: '',
    },
    logo: {
      type: String,
      default: '',
    },
    created: {
      type: String,
      default: '',
    },
    categories: {
      type: Array,
      default: null,
    },
    tags: {
      type: Array,
      default: null,
    },
    content: {
      type: String,
      default: '',
    },
    embedVideoUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentUrl: '',
      showDonwloadContactModal: false,
      formFileUrl: '',
    }
  },
  created() {
    if (process.client) {
      this.currentUrl = document.location.href
    }
  },
  mounted() {
    this.updateAllChildElement('post-content', 'a', 'target', '_blank')
    this.updateAllChildElement('post-content', 'img', 'style', '')
    this.setDownloadContactForm('post-content', 'a[rel~=download_contact_form]')
  },
  methods: {
    updateAllChildElement(parentId, element, propertyName, propertyVal) {
      const links = document.querySelectorAll(`#${parentId} ${element}`)
      const len = links.length;
      for (let i = 0; i < len; i++) {
        links[i][propertyName] = propertyVal
      }
    },
    setDownloadContactForm(parentId, element) {
      const links = document.querySelectorAll(`#${parentId} ${element}`)
      const len = links.length;
      for (let i = 0; i < len; i++) {
        links[i].addEventListener('click', e => {
          this.formFileUrl = e.currentTarget.href
          this.openDownloadContactModal()
          e.preventDefault()
        })
      }
    },
    openDownloadContactModal() {
      this.showDonwloadContactModal = true
    },
    closeDownloadContactModal() {
      this.showDonwloadContactModal = false
    },
  },
}
