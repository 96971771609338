export default {
	mounted() {
		this.checkHeight()
	},
	created() {
		if (process.client)
			window.addEventListener('scroll', this.handleScroll);
	},
	destroyed() {
		if (process.client)
			window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		handleScroll() {
			const screenWidth = window.innerWidth
			const elementPosition = this.$refs[`section_${this.section.id}`].getBoundingClientRect().top

			if (screenWidth > elementPosition)
				this.checkHeight()
		},
	}
}