//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseModal from "./BaseModal";
import BaseButton from "../buttons/BaseButton";
import Warning from "~/assets/images/svg/expired-membership-warning.svg?inline";

export default {
  components: {BaseButton, BaseModal, Warning},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
};
