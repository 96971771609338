var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account-steps-card",on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"account-steps-card__head account-steps-card-head"},[_c('h1',{staticClass:"account-steps-card-head__title"},[_vm._v("\n      "+_vm._s(_vm.item.name)+"\n    ")]),_vm._v(" "),_c('h2',{class:[
        'account-steps-card-head__statistic',
        { 'access-denied' : !_vm.isAccessAllowed } ]},[_vm._v("\n      "+_vm._s(_vm.validateAccess(_vm.item.count_franchisee))+"\n      "),_c('span',{staticClass:"account-steps-card-head__percentage"},[_vm._v(" "+_vm._s(_vm.validateAccess(_vm.item.percent_passed))+"%")])])]),_vm._v(" "),_c('div',{staticClass:"account-steps-card-head__chat"},[(_vm.item.count_new_messages)?_c('span',{class:[
        'account-steps-card-head__chat_number',
        { 'access-denied' : !_vm.isAccessAllowed } ]},[_c('StatusDot',{staticClass:"account-steps-card-head__dot"}),_vm._v(" "),_c('span',{class:[
          'new-message-txt',
          { 'access-denied' : !_vm.isAccessAllowed } ]},[_vm._v("\n        "+_vm._s(_vm.validateAccess(_vm.item.count_new_messages))+"\n        New msg\n      ")])],1):_c('span',{staticClass:"account-steps-card-head__chat_link"},[((_vm.user.isMasterAdmin && _vm.versionApproved)
          || (_vm.versionApproved && _vm.user.isFranchisor && _vm.paymentPermissions.chat.access))?_c('nuxt-link',{attrs:{"to":_vm.buildAccountFranchisorUri(("/account-franchisor/" + _vm.franchiseId + "/steps/" + (_vm.item.slug)))}},[_vm._v("\n        Chat\n      ")]):_c('span',{on:{"click":_vm.showMessage}},[_vm._v("Chat")])],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"documents-link"},[_c('DocumentsIcon'),_vm._v(" "),_c('span',{staticClass:"account-steps-card-head__chat__documents",on:{"click":function($event){$event.stopPropagation();(_vm.user.isMasterAdmin && _vm.versionApproved)
          || (_vm.versionApproved && _vm.user.isFranchisor && _vm.paymentPermissions.chat.access)
          ? _vm.handleClickDocuments()
          : _vm.showMessage()}}},[_vm._v("\n        Documents\n      ")])],1)]),_vm._v(" "),_c('p',{staticClass:"account-steps-card__description"},[_vm._v("\n    "+_vm._s(_vm.item.description)+"\n  ")]),_vm._v(" "),_c('ModalDocumentsList',{ref:"document-list-modal",attrs:{"items":_vm.item,"show":_vm.showDocumentsModal},on:{"close":function($event){_vm.showDocumentsModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }