//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {WORK_BOOK, WORK_BOOK_TOP_LIST_KEYS} from "@/store/constants";
import {mapActions, mapGetters} from "vuex";
import cloneDeep from "lodash/cloneDeep";
import ArrowCodeIcon from '~/assets/icons/arrows/ArrowCodeIcon.svg?inline'
import ArrowCodeBolderIcon from '~/assets/icons/arrows/ArrowCodeBolderIcon.svg?inline'

export default {
  name: "WorkBookCompetitiveAnalysis",
  components: {
    ArrowCodeIcon,
    ArrowCodeBolderIcon,
  },
  props: {
    blockIndex: {
      type: Number,
      default: 0
    },
    countTop: {
      type: Number,
      default: 0,
    }
  },
  data: () => ({
    currentFranchiseIndex: 0,
    currentFieldIndex: 0,
  }),
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      topList: 'account/workbook/topList',
      franchise: 'account/workbook/franchise',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
    }),
    questionsBlock() {
      return WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex].questionsBlocks[this.blockIndex]
    },
    currentFieldList() {
      return this.questionsBlock.questions.filter(item => item.fieldIndex === this.currentFieldIndex)
    },
    currentAltFieldList() {
      const returnArr = []

      this.currentFieldList.forEach(question => {
        returnArr[question.groupIndex] = {
          name: question.groupTitle
        }
      })

      returnArr.forEach((qroup, groupIndex) => {
        returnArr[groupIndex].items = this.currentFieldList.filter(item => item.groupIndex === groupIndex)
      })

      return returnArr
    },
    isAltTypeQuestions() {
      return this.currentFieldList.findIndex(item => typeof item.groupIndex === "undefined") === -1
    },

  },
  methods: {
    ...mapActions({
      changeAnswer: 'account/workbook/changeAnswer',
    }),
    getFranchiseName() {
      const listIndex = WORK_BOOK_TOP_LIST_KEYS.THREE

      if (typeof this.topList[listIndex] === "undefined")
        return ''

      const franchise = this.franchise.find(item => Number(item.id) === Number(this.topList[listIndex][this.currentFranchiseIndex]))

      if (typeof franchise === "undefined")
        return `Franchise #${this.currentFranchiseIndex + 1}`

      return franchise.franchise
    },
    getValue(questionId, currentFranchiseIndex) {
      const savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)

      return savedValue.value[currentFranchiseIndex]
    },
    handlerUpdateValue(questionId, currentFranchiseIndex, value) {
      const savedValue = cloneDeep(this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId))
      const questionIndex = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.findIndex(item => item.id === questionId)


      savedValue.value[currentFranchiseIndex] = value
      this.changeAnswer({blockIndex: this.blockIndex, questionIndex, value: savedValue.value})
    },
  }
}
