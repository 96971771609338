//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Eye from "@/components/animated-icons/Eye"

export default {
  components: {Eye},
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    nativeType: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showPassword: false,
    }
  },
  computed: {
    /**
     * Detect input if password type
     * @returns {boolean}
     */
    isPasswordType() {
      return this.nativeType === 'password'
    },
    /**
     * Dynamical input type
     * @returns {String}
     */
    inputType() {
      if (this.isPasswordType && this.showPassword) return 'text'
      return this.nativeType
    },
  },
}
