//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters,
} from "vuex"
import ClearIcon from "@/components/icons/ClearIcon"
import SignUpFranchiseStepOne from "@/components/form/SignUpFranchise/SignUpFranchiseStepOne"
import SignUpFranchiseStepTwo from "@/components/form/SignUpFranchise/SignUpFranchiseStepTwo"
import SignUpFranchiseStepThree from "@/components/form/SignUpFranchise/SignUpFranchiseStepThree"
import SignUpFranchiseStepCongrats from "@/components/form/SignUpFranchise/SignUpFranchiseStepCongrats"
import SignUpFranchiseStepOnePending from "@/components/form/SignUpFranchise/SignUpFranchiseStepOnePending"
import SignUpFranchiseStepTwoPending from "@/components/form/SignUpFranchise/SignUpFranchiseStepTwoPending"

import hubspotMixin from "@/components/mixins/hubspotMixin"

import {
  MODAL_SIGN_UP_FRANCHISE_ID,
  SIGN_UP_FRANCHISE_FAST_STEP_ONE,
  HUBSPOT_EVENT_TYPES,
} from "@/store/constants"

export default {
  name: "ModalSignUpFranchisee",
  components: {
    SignUpFranchiseStepTwoPending,
    SignUpFranchiseStepOnePending,
    SignUpFranchiseStepCongrats,
    ClearIcon,
    SignUpFranchiseStepThree,
    SignUpFranchiseStepTwo,
    SignUpFranchiseStepOne,
  },
  mixins: [
    hubspotMixin,
  ],
  data: () => ({
    listSteps: [],
    defaultSteps: [
      {
        component: 'sign-up-franchise-step-one',
        modalSize: 'sm'
      },
      {
        component: 'sign-up-franchise-step-two',
        modalSize: 'sm'
      },
      {
        component: 'sign-up-franchise-step-three',
        modalSize: 'lg'
      },
    ],
    beginTodaySteps: [
      {
        component: 'sign-up-franchise-step-three',
        modalSize: 'lg'
      },
      {
        component: 'sign-up-franchise-step-congrats',
        modalSize: 'sm'
      },
      {
        component: 'sign-up-franchise-step-one',
        modalSize: 'sm'
      },
      {
        component: 'sign-up-franchise-step-two',
        modalSize: 'sm'
      },
    ],
    pendingListSteps: [
      {
        component: 'sign-up-franchise-step-one-pending',
        modalSize: 'sm'
      },
      {
        component: 'sign-up-franchise-step-two-pending',
        modalSize: 'sm'
      },
      {
        component: 'sign-up-franchise-step-congrats',
        modalSize: 'sm'
      },
    ],
    currentStep: {},
    pageLink: '',
    modalId: MODAL_SIGN_UP_FRANCHISE_ID
  }),
  computed: {
    ...mapGetters({
      authenticated: 'auth/check',
      franchiseStage: 'registration/franchise_stage',
      selectedFranchise: 'account/selectedFranchise',
      showFdd: 'account/showFdd',
      company_url: 'registration/company_url',
      enableFlowBeginToday: 'registration/enableFlowBeginToday',
      enableFlowPendingList: 'registration/enableFlowPendingList',
    }),
  },
  watch: {
    franchiseStage() {
      this.currentStep = this.listSteps[this.franchiseStage]
    },
  },
  mounted() {
    this.updateStepList()
    this.currentStep = this.listSteps[this.franchiseStage]
  },
  methods: {
    ...mapActions({
      changeStatusFlowBeginToday: 'registration/changeStatusFlowBeginToday',
      changeStatusFlowPendingList: 'registration/changeStatusFlowPendingList',
      changeStatusFlowPendingListWithSave: 'registration/changeStatusFlowPendingListWithSave'
    }),
    updateStepList() {
      if (this.enableFlowBeginToday) {
        this.listSteps = this.beginTodaySteps
        return
      }

      if (this.enableFlowPendingList) {
        this.listSteps = this.pendingListSteps
        return
      }

      this.listSteps = this.defaultSteps
    },
    async redirectToCompanyPage() {
      if (this.company_url) {
        await this.$router.push(this.company_url)
      }
    },
    async updateStep(step) {
      await this.$store.commit('registration/SET_REGISTRATION_FRANCHISEE_STAGE', step)
    },
    async checkUser() {
      await this.redirectToCompanyPage()
      if (this.franchiseStage === SIGN_UP_FRANCHISE_FAST_STEP_ONE)
        return

      this.sendHubSpotEvents()

      if (!this.showFdd)
        return

      const fddUrl = this.selectedFranchise.files?.fdd?.url ?? null
      if (fddUrl) {
        await this.$store.dispatch('iframe/openModal', fddUrl)
        await this.hsCustomEvent(this.selectedFranchise.franchise_id, HUBSPOT_EVENT_TYPES.openFullFDD)
      }
    },
    sendHubSpotEvents(e) {
      if (this.authenticated) {
        this.hsSignUpForm(this.selectedFranchise?.id)
      }
    },
    onModalShow() {
      this.updateStepList()
      this.currentStep = this.listSteps[this.franchiseStage]
    },
    onModalShown() {
      window.addEventListener('beforeunload', this.sendHubSpotEvents)
    },
    onModalHidden() {
      this.changeStatusFlowBeginToday(false)
      this.changeStatusFlowPendingList('')
      this.changeStatusFlowPendingListWithSave('')
      this.$store.commit('registration/SET_REGISTRATION_FRANCHISEE_STAGE', SIGN_UP_FRANCHISE_FAST_STEP_ONE)
      window.removeEventListener('beforeunload', this.sendHubSpotEvents)
    }
  }
}
