//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VCheckBox from "@/components/form/VCheckBox"
import VInput from '@/components/form/VInput'
import closeWithWrapperIcon from '@/assets/images/svg/close-with-wrap.svg?inline'
import VButton from "@/components/VButton"
import ClearIcon from "@/components/icons/ClearIcon"

export default {
  components: {
    ClearIcon,
    VButton,
    VCheckBox,
    VInput,
    closeWithWrapperIcon,
  },
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    reasons: {
      type: Array,
      default: () => [],
    },
    busy: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      customReasonChecked: false,
      customReasonText: '',
    }
  },
  computed: {
    checkedReasons() {
      return this.reasons.filter((value) => value.checked)
    },
    confirmButtonDisabled() {
      if (this.checkedReasons.length && this.customReasonChecked && this.customReasonText.length) {
        return false
      }
      if (this.checkedReasons.length && this.customReasonChecked && !this.customReasonText.length) {
        return true
      }
      if (this.checkedReasons.length && !this.customReasonChecked) {
        return false
      }
      return !this.customReasonText.length
    },
  },
  watch: {
    customReasonChecked(newValue) {
      if (newValue) {
        this.customReasonText = ''
      }
    },
  },
  methods: {
    /**
     * Confirm click handler
     */
    handleClickConfirm() {
      const checkedReasonsIds = []
      this.checkedReasons.forEach((value) => {
        checkedReasonsIds.push(value.reason_id)
      })

      this.$emit('confirm', {checkedReasonsIds, customReasonText: this.customReasonText})
    },
    /**
     * Clear all checkboxes & inputs
     */
    clearModalForm() {
      this.customReasonChecked = false
      this.customReasonText = ''
    },
  },
}
