//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {USER_PREFERENCES_TOTAL_AMOUNT_INVEST} from "@/store/constants";
import {mapActions, mapGetters} from 'vuex'
import zenscroll from 'zenscroll'

export default {
  name: "MessageNarrowYouSearchFranchisee",
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
      preferencesIndustries: 'account/preferencesIndustries',
      preferencesStates: 'account/preferencesStates',
      preferencesInvestment: 'account/preferencesInvestment',
    }),
    showMessage() {
      if (!this.authenticated)
        return false

      if (!this.user.isFranchisee)
        return false

      if (this.preferencesIndustries.length === 0)
        return true

      if (this.preferencesStates.length === 0)
        return true

      if (USER_PREFERENCES_TOTAL_AMOUNT_INVEST.filter(item => item.value === this.preferencesInvestment).length === 0)
        return true

      return false
    },
  },
  watch: {
    preferencesInvestment() {
      this.invest = this.preferencesInvestment ?? []
    },
  },
  async created() {
    if (this.authenticated && this.user.isFranchisee)
      await this.fetchPreferences()
  },
  methods: {
    ...mapActions({
      fetchPreferences:'account/fetchPreferences',
      updateFocusFilterToggleStatus:'account/updateFocusFilterToggleStatus',
    }),
    handlerButton() {
      const path = '/account/my-matches';
      if (path === this.$route.path) {
        zenscroll.toY(0, 800)
      } else {
        this.$router.push(path)
      }
      this.updateFocusFilterToggleStatus(true)
      setTimeout(() => {
        this.updateFocusFilterToggleStatus(false)
      }, 10000)
    }
  }
}
