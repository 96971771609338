//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileDocumentIcon from "@/assets/icons/files/FileDocumentIcon.svg?inline"
import PlusIcon from "@/assets/icons/landing/PlusIcon.svg?inline"
import ArrowReloadIcon from "@/assets/icons/arrows/ArrowReloadIcon.svg?inline"
import moment from "moment";

export default {
  name: "FileInputCustom",
  components: {FileDocumentIcon, PlusIcon, ArrowReloadIcon},
  props: {
    accept: {
      type: String,
      default: 'application/pdf'
    },
    file: {
      type: Object,
      default: () => {
        return {id: '', updated_at: '', filename: '', url: ''}
      }
    },
    replaceMode: {
      type: Boolean,
      default: () => {
      }
    },
  },
  data() {
    return {
      focus: false,
      fileName: '',
      fileLoad: false,
      showNotifyReplace: false,
    }
  },
  created() {
    this.updateAttributes()
  },
  methods: {
    updateAttributes(){
      this.fileLoad = !!Object.keys(this.file).length
      if (this.fileLoad)
        this.fileName = this.trimLength(this.file.filename)
    },
    changeTimeFormat(dateTime) {
      return new moment(dateTime).format('LT, MMM D, YYYY')
    },
    async handleFileUpload(e) {
      this.$emit('change')
      const files = e.target.files || e.dataTransfer.files;

      if(this.showNotifyReplace){
        this.$emit('delete')
        this.showNotifyReplace = false
      }
      if (files.length) {
        const file = files[0]
        this.$emit('change', file)
        this.fileName = this.trimLength(file.name)
        this.fileLoad = true
      }
    },
    trimLength(string, length = 65, end = '...') {
      if (string.length > length + 3) {
        return string.substring(0, length) + end
      }
      return string
    },
    deleteFile(){
      this.$emit('change')
      this.fileLoad = false
      if (this.replaceMode){
        this.showNotifyReplace = true
        return
      }


      this.$emit('delete')
    },
  }
}
