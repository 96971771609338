//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ClearIcon from "@/components/icons/ClearIcon";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "WorkBookActionMenu",
  components: {ClearIcon},
  props: {
    workBookIndex: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    modalShow: false,
    openMenu: false,
  }),
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
    }),
    disableDelete() {
      return this.workBooks.length <= 1
    }
  },
  methods: {
    ...mapActions({
      deleteWorkbook: 'account/workbook/deleteWorkbook',
      changeIndexEditTitle: 'account/workbook/changeIndexEditTitle',
    }),
    handlerDelete() {
      this.openMenu = false
      this.modalShow = true
    },
    handlerRename() {
      this.openMenu = false
      this.changeIndexEditTitle(this.workBookIndex)
    },
    handlerConfirmDelete() {
      this.deleteWorkbook(this.workBookIndex)
      this.modalShow = false
    },

  }
}
