const qSort = arr => {
  if (arr.length < 2) {
    return arr
  }

  if (arr.length === 2) {
    return arr[0] > arr[1] ? [arr[1],arr[0]] : arr
  }

  const pivot = arr[0]
  const less = []
  const great = []

  arr.slice(1, arr.length).forEach(el => {
    if (el < pivot) {
      less.push(el)
    }
    if (el >= pivot) {
      great.push(el)
    }
  })

  return [...qSort(less), pivot, ...qSort(great)]
}

const arr = [14,12,34,1235,1,3246,1346,-100,347,134,56,-4654,234,23,5235,123,4,234,4,623,456,34,5,3245,234,52,34,5,2345,2345,]
const res = qSort(arr)

console.log(res)
