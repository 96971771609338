//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClearIcon from "@/components/icons/ClearIcon";
import {mapActions, mapGetters} from "vuex";
import {MODAL_WORKBOOK_ID} from '@/store/constants'
import WorkBookMenu from "@/components/workbook/elements/WorkBookMenu";
import WorkBookQuestionnaire from "@/components/workbook/WorkBookQuestionnaire";

export default {
  name: "ModalWorkBook",
  components: {
    ClearIcon,
    WorkBookQuestionnaire,
    WorkBookMenu,
  },
  data: () => ({
    MODAL_WORKBOOK_ID,
  }),
  computed: {
    ...mapGetters({
      isAuth: 'auth/check',
      user: 'auth/user',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
    }),
    enableModal() {
      return this.isAuth && this.user.isFranchisee
    },
  },
  mounted() {
    this.fetchWorkbooks()
  },
  methods:{
    ...mapActions({
      fetchWorkbooks: 'account/workbook/fetchWorkbooks',
    })
  }
}
