//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
name: "PathDown",
  props: {
  fill: {
    type:String,
    default : '#ffffff'
   }
  }
}
