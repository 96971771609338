//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'vform'
import VInputV2 from "@/components/form/VInputV2"
import CloseIcon from "@/components/icons/CloseIcon"
import BaseButton from "@/components/buttons/BaseButton"
import hubspotMixin from "@/components/mixins/hubspotMixin"

export default {
  name: 'DownloadContactForm',
  components: {
    VInputV2,
    CloseIcon,
    BaseButton,
  },
  mixins: [
    hubspotMixin,
  ],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formSent: false,
      form: new Form({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
      }),
    }
  },
  methods: {
    closeDownloadContactModal() {
      this.$emit('close')
    },
    download() {
      this.closeDownloadContactModal()
      window.open(this.url, '_blank')
    },
    async submit() {
      try {
        const result = await this.hsDownloadContactForm(
          this.form.first_name,
          this.form.last_name,
          this.form.email,
          this.form.phone,
        );
        if (result.ok) {
          this.formSent = true
          this.form.reset()
          this.$toast.success(result.msg)
        } else {
          this.$toast.error(result.msgShort)
        }
        this.form = result.form
      }catch (e){

      }

    },
  },
}
