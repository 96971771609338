//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowCodeIcon from "@/components/icons/ArrowCodeIcon";
import { mapGetters } from "vuex";
import { MODAL_EDIT_FRANCHISE_ID  } from "@/store/constants";
import zenscroll from "zenscroll";
import storeMixin from "~/components/mixins/storeMixin";
import stringMixin from "~/components/mixins/stringMixin";

export default {
  components: {ArrowCodeIcon},
  mixins: [
    storeMixin,
    stringMixin
  ],
  data: () => ({
    MODAL_EDIT_FRANCHISE_ID,
    mobileItems: [],
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      franchise: 'business-v2/franchise',
      versionApproved: 'account-franchisor/versionApproved',
      paymentPermissions: 'payment/permissions'
    }),
    activePageName () {
      let activePageName = ''

      this.mobileItems[0].children.forEach((item) => {
        if (item.to && (this.trim(item.to, '/') === this.trim(this.$route.fullPath, '/'))) {
          activePageName =  item.title
        }
      })

      return activePageName
    }
  },
  created() {
    this.mobileItems = this.generateMobileUrls();
  },
  methods: {
    handlerClick(to){
      zenscroll.toY(0, 800)
      this.$root.$emit('bv::toggle::collapse', 'mobile_menu')
      this.$router.push(to)
    },
    generateMobileUrls() {
      return [
        {
          title: null,
          bottomLine: false,
          children: [
            {
              title: 'overview',
              to: this.buildAccountFranchisorUri('/account-franchisor'),
              event: null,
              arrow: false,
            },
            {
              title: 'dashboard',
              to: this.buildAccountFranchisorUri('/account-franchisor/dashboard'),
              event: null,
              arrow: false,
            },
            {
              title: 'Onboarding packet',
              to: this.buildAccountFranchisorUri('/account-franchisor/onboarding-packet'),
              event: null,
              arrow: false,
            },
            {
              title: 'Contacts',
              to: this.buildAccountFranchisorUri('/account-franchisor/contacts'),
              event: null,
              arrow: false,
            },
            {
              title: 'Submissions',
              to: this.buildAccountFranchisorUri('/account-franchisor/submissions'),
              event: null,
              arrow: false,
            },
            {
              title: 'landing page',
              to: null,
              event: this.franchise.public_website_business_permalink
                ? () => this.openFranchiseSite(this.franchise.public_website_business_permalink)
                : null,
              arrow: true,
            },
            {
              title: 'Company page Edit',
              to: null,
              event: () => this.clickEditCompany(),
              arrow: true,
            },
            {
              title: 'Company page View',
              to: null,
              event: this.versionApproved
                ? () => this.$router.push(`/franchise-directory/industries/${this.franchise.slug_industry}/${this.franchise.slug}`)
                : this.user.isFranchisor
                  ? () => {
                    this.fireModalMessage({message: this.$t('accountFranchisor.accessMessages.notApprovedFranchisor')})
                    this.$root.$emit('bv::toggle::collapse', 'mobile_menu')
                  }
                  : null,
              arrow: true,
            },
            {
              title: 'budget',
              to:
                (this.user.isFranchisor && this.versionApproved && this.paymentPermissions.budgetTool)
                || this.user.isMasterAdmin
                ? this.buildAccountFranchisorUri('/account-franchisor/budget')
                : null,
              event: () => this.clickBudgetTool(),
              arrow: false,
            },
          ],
        },
      ];
    },
    openFranchiseSite(url) {
      window.open(url, '_blank');
    },
    clickEditCompany () {
      if (this.user.isFranchisor) {
        if (!this.versionApproved) {
          this.fireModalMessage(
            { message: this.$t('accountFranchisor.accessMessages.notApprovedFranchisor') }
          )
          this.$root.$emit('bv::toggle::collapse', 'mobile_menu')
          return
        } if (!this.paymentPermissions.editCompany.access) {
          this.fireModalMessage({ message: this.paymentPermissions.editCompany.message })
          this.$root.$emit('bv::toggle::collapse', 'mobile_menu')
          return
        }
      }

      this.$root.$emit('bv::toggle::collapse', 'mobile_menu')
      this.$bvModal.show(MODAL_EDIT_FRANCHISE_ID)
    },
    clickBudgetTool() {
      if (this.user.isFranchisor) {
        if (!this.versionApproved) {
          this.fireModalMessage({ message: this.$t('accountFranchisor.accessMessages.notApprovedFranchisor') })
          return
        }

        if (!this.paymentPermissions.budgetTool.access) {
          this.fireModalMessage({ message: this.paymentPermissions.budgetTool.message })
        }
      }
    }
  }

}
