//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    provider: {
      type: String,
      required: true
    },
    userType: {
      type: Number,
      default: null
    }
  },
  mounted () {
    window.addEventListener('message', this.onMessage, false)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.onMessage)
  },
  methods: {
    async login () {
      const newWindow = openWindow('', this.$t('login'))
      const params = {
        provider: this.provider
      }

      if (this.userType) {
        params.userType = this.userType
      }

      newWindow.location.href = await this.$store.dispatch('auth/fetchOauthUrl', params)
    },

    /**
     * @param {MessageEvent} e
     */
    onMessage (e) {
      if (e.data.error) {
        this.$emit('onError', e.data.error)
        window.removeEventListener('message', this.onMessage)
        return
      }

      if (e.origin !== window.origin || !(e.data.data && e.data.data.token)) {
        return
      }

      this.$store.dispatch('auth/saveToken', {
        token: e.data.data.token,
        remember: e.data.data.remember,
        expires: e.data.data.expires
      })
      this.$store.dispatch('auth/fetchUser')

      this.$emit('authenticated', e.data.data.is_new)
    }
  }
}

/**
 * @param {String} url
 * @param {String} title
 * @param  {Object} options
 * @return {Window}
 */
function openWindow (url, title, options = {}) {
  if (typeof url === 'object') {
    options = url
    url = ''
  }

  options = { url, title, width: 600, height: 720, ...options }

  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top
  const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width
  const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height

  options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft
  options.top = ((height / 2) - (options.height / 2)) + dualScreenTop

  const optionsStr = Object.keys(options).reduce((acc, key) => {
    acc.push(`${key}=${options[key]}`)
    return acc
  }, []).join(',')

  const newWindow = window.open(url, title, optionsStr)

  if (window.focus) {
    newWindow.focus()
  }

  return newWindow
}
