//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BlogPostsListItem from '~/components/blog/BlogPostsListItem';

export default {
  name:'BlogPostList',
  components: {
    BlogPostsListItem,
  },
  props: {
    posts: {
      type: Array,
      default: null,
    }
  }
}
