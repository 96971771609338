export default {
  methods: {
    /**
     * @param {string} subject
     * @param {Array} search
     * @param {string} replace
     */
    strReplaceArray(subject, search = [], replace  = '') {
      let result = subject

      search.forEach(item => {
        result = result.replaceAll(item, replace)
      })

      return result
    },
    trim(subject, char) {
        char = this.escapeRegExp(char);
        const regEx = new RegExp("^[" + char + "]+|[" + char + "]+$", "g");
        return subject.replace(regEx, "");
    },
    escapeRegExp(strToEscape) {
      return strToEscape.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    }
  }
}
