//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AccountButtons from "@/components/icons/AccountButtons";
import {MODAL_EDIT_FRANCHISEE_ID, MODAL_EDIT_QUESTIONNAIRE_ID} from "@/store/constants";
import {mapActions, mapGetters} from 'vuex'
import CardProfileStrength from "@/components/cards/CardProfileStrength";
import ModalUserEdit from "@/components/modals/ModalUserEdit";

export default {
  name: 'CardUserAccount',
  components: {AccountButtons, ModalUserEdit, CardProfileStrength},
  props: {
    isBlocked: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    firstStep: 0,
    secondStep: 0,
    thirdStep: 0,
    strengthLevel: '',
    fields: {
      first_name: {
        value: ''
      },
      last_name: {
        value: ''
      },
      email: {
        value: '',
        isActive: false
      },
      location: {
        value: '',
        default: 'add location',
        buttonTitle: 'add location',
        isActive: true
      },
      short_descr: {
        value: '',
        default: 'add about',
        buttonTitle: 'add about',
        placeholder: 'Fill in a brief description of who you are and what you are interested in.',
        isActive: true
      },
      phone: {
        value: '',
        default: 'add phone number',
        buttonTitle: 'add phone number',
        isActive: true
      },
      linked_in_link: {
        value: '',
        default: 'add linkedin profile',
        buttonTitle: 'add linkedin profile',
        isActive: true
      },
      questionnaire: {
        value: '',
        default: '3 minute questionnaire',
        buttonTitle: '3 minute questionnaire',
        isActive: true
      },
      countAnswers: {
        value: 0
      }
    }
  }),
  computed: {
    ...mapGetters({
      user: 'account/userObj',
      questionnaireFinished: 'account/question/finished',
    })
  },
  watch: {
    user() {
      this.updateFields()
    },
    questionnaireFinished() {
      this.updateFields()
    }
  },
  mounted() {
    this.updateFields()
  },
  methods: {
    ...mapActions({
      fetchUser: 'account/fetchUser',
    }),
    formattedPhone(phone) {
      const clearedPhone = phone.replace(/\D[^\\.]/g, '')

      return (
        `${clearedPhone.slice(0, 3)
        }-${
          clearedPhone.slice(3, 6)
        }-${
          clearedPhone.slice(6)}`
      )
    },
    updateFields() {
      if (this.user === null )
        return
      Object.keys(this.fields).forEach(key => {
        if (
          typeof this.fields[key] === 'undefined' ||
          typeof this.user[key] === 'undefined' ||
          typeof this.fields[key].buttonTitle === 'undefined'
        )
          return

        this.fields[key].value = this.user[key]

        if (this.user[key] === '' || this.user[key] === null) {
          this.fields[key].isActive = true
          this.fields[key].buttonTitle = this.fields[key].default
          return;
        }

        this.fields[key].isActive = false

        if (key === 'location')
          this.fields[key].buttonTitle = this.user[key]

        if (key === 'short_descr')
          this.fields[key].buttonTitle = 'edit about'

        if (key === 'phone')
          this.fields[key].buttonTitle = this.formattedPhone(this.user[key])

        if (key === 'linked_in_link')
          this.fields[key].buttonTitle = 'view'

      })
    },
    controlButtonHandler(buttonIndex) {
      const button = this.fields[buttonIndex];

      if (buttonIndex === 'linked_in_link' && button.value !== '' && button.value !== null) {
        window.open(button.value, '_blank');
        return
      }

      if (buttonIndex === 'questionnaire') {
        if (!this.questionnaireFinished)
          this.$bvModal.show(MODAL_EDIT_QUESTIONNAIRE_ID)
        return;
      }
      this.$bvModal.show(MODAL_EDIT_FRANCHISEE_ID)
    },
    checkDisable(buttonIndex) {
      return this.isBlocked && buttonIndex !== 'linked_in_link'
    },
    checkShow(button, buttonIndex) {
      if (typeof button.buttonTitle === 'undefined')
        return false

      if (this.isBlocked && buttonIndex === 'questionnaire')
        return false

      if (this.isBlocked && button.isActive)
        return false

      return true
    },
  }
}
