//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STATUS_TODO, STATUS_IN_PROGRESS, STATUS_DONE } from '~/store/constants';
import BaseButton from "@/components/buttons/BaseButton"
import StatusDot from "@/components/icons/StatusDot"

export default {
  components: {StatusDot, BaseButton},
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      STATUS_TODO,
      STATUS_IN_PROGRESS,
      STATUS_DONE,
    };
  },
  computed: {
    itemStatusClass() {
      return `franchisee-steps-card--${this.step.status}`;
    },
    statusTodo() {
      return this.step.status === this.STATUS_TODO;
    },
    statusInProgress() {
      return this.step.status === this.STATUS_IN_PROGRESS
    },
    statusDone() {
      return this.step.status === this.STATUS_DONE;
    },
    messageLabel() {
      if (this.statusTodo) {
        return ''
      }

      if (this.statusInProgress) {
        if (this.step.count_new_messages) {
          return `${this.step.count_new_messages} New Message${this.step.count_new_messages !== 1 ? 's' : ''}`
        }
      }

      return `${this.step.count_messages} Message${this.step.count_messages !== 1 ? 's' : ''}`
    },
    /**
     * Step presentation url
     * @returns {String}
     */
    presentationUrl() {
      if (this.step.presentation_doc_id !== null) {
        return `${process.env.BACKEND_BASE_URL}/api/v1/business-step-docs/download/${this.step.presentation_doc_id}`
      }
      return '';
    }
  },
  methods: {
    goToScheduleCall() {
      window.open('https://calendly.com/susan-617', '_blank');
    },
  },
};
