import { render, staticRenderFns } from "./Confetti.vue?vue&type=template&id=434e071b&scoped=true&"
import script from "./Confetti.vue?vue&type=script&lang=js&"
export * from "./Confetti.vue?vue&type=script&lang=js&"
import style0 from "./Confetti.vue?vue&type=style&index=0&id=434e071b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "434e071b",
  null
  
)

export default component.exports