//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "MobileAppStoreButton",
  props: {
    android: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    linkList: {
      ios: 'https://apps.apple.com/us/app/franchise123/id1564506102',
      android: 'https://play.google.com/store/apps/details?id=com.franchise123.app',
    },
    typeList: {
      ios: 'ios',
      android: 'android',
    },
    imgList: {
      ios: {
        icon: 'app-store',
        text: 'app-store-text',
      },
      android: {
        icon: 'google-play',
        text: 'google-play-text',
      },
    },
  }),
  computed: {
    type() {
      return this.android ? this.typeList.android : this.typeList.ios
    },
    link() {
      return this.linkList[this.type]
    },
  },
  methods: {}
}
