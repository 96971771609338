//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import CardSliderComparison from "@/components/cards/CardSliderComparison";
import helpers from "@/components/mixins/helpers";
import {mapActions, mapGetters} from "vuex";
import ArrowCodeIcon from '@/assets/icons/arrows/ArrowCodeIcon.svg?inline'
import {cloneDeep} from 'lodash/fp'

const fieldTypes = {
  'ITEM_TYPE_DATE': 'ITEM_TYPE_DATE',
  'ITEM_TYPE_MIN_MAX': 'ITEM_TYPE_MIN_MAX',
  'ITEM_TYPE_MONEY': 'ITEM_TYPE_MONEY',
  'ITEM_TYPE_STRING': 'ITEM_TYPE_STRING',
  'ITEM_TYPE_IFRAME': 'ITEM_TYPE_IFRAME',
  'ITEM_TYPE_LINK': 'ITEM_TYPE_LINK',
}

export default {
  name: "DashboardFranchiseeComparisons",
  components: {
    CardSliderComparison,
    ArrowCodeIcon,
    Swiper,
    SwiperSlide,
  },
  mixins: [
    helpers
  ],
  props: {
    isBlocked: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    notNextTick: true,
    fieldTypes,
    disabledNextButton: true,
    disabledPrevButton: true,
    attributes: [
      {key: 'established', title: 'Established', type: fieldTypes.ITEM_TYPE_DATE},
      {
        key: 'franchising_since',
        title: 'Franchising since',
        type: fieldTypes.ITEM_TYPE_DATE,
      },
      {
        key: 'investment',
        key_min: 'investment_min',
        key_max: 'investment_max',
        title: 'Investment required',
        type: fieldTypes.ITEM_TYPE_MIN_MAX,
      },
      {
        key: 'franchise_fee',
        key_min: 'franchise_fee_min',
        key_max: 'franchise_fee_max',
        title: 'Franchise fee',
        type: fieldTypes.ITEM_TYPE_MIN_MAX,
      },
      {
        key: 'average_revenue',
        title: 'Average Revenue',
        type: fieldTypes.ITEM_TYPE_MONEY,
      },
      {
        key: 'gross_profit',
        title: 'Previous Years Gross Sales',
        type: fieldTypes.ITEM_TYPE_STRING,
      },
      {key: 'item7Url', title: 'Item 7: Initial Investment', type: fieldTypes.ITEM_TYPE_IFRAME},
      {
        key: 'item19Url',
        title: 'Item 19: Financial Performance Representation',
        type: fieldTypes.ITEM_TYPE_IFRAME,
      },
      {
        key: 'minimum_credit_score',
        title: 'Minimum credit score',
        type: fieldTypes.ITEM_TYPE_STRING,
      },
      {
        key: 'minimum_liquid_assets',
        title: 'Minimum liquid Assets',
        type: fieldTypes.ITEM_TYPE_STRING,
      },
      {
        key: 'total_net_worth',
        title: 'Total Net Worth',
        type: fieldTypes.ITEM_TYPE_STRING,
      },
      {key: 'naics', title: 'NAICS', type: fieldTypes.ITEM_TYPE_STRING},
      {key: 'main_royalty_fee', title: 'Royalty', type: fieldTypes.ITEM_TYPE_STRING},
      {
        key: 'public_website_business_permalink',
        title: 'Website',
        label: 'View',
        type: fieldTypes.ITEM_TYPE_LINK,
      },
      {
        key: 'number_of_locations',
        title: 'Locations',
        type: fieldTypes.ITEM_TYPE_STRING,
      },
      {key: 'fddUrl', title: 'Full FDD', type: fieldTypes.ITEM_TYPE_IFRAME},
      {key: 'franchiseesUrl', title: 'Franchisees', type: fieldTypes.ITEM_TYPE_IFRAME},
      {key: 'presentation', title: 'Presentation', type: fieldTypes.ITEM_TYPE_IFRAME},
      {key: 'promo_video', title: 'Promo video', type: fieldTypes.ITEM_TYPE_LINK},
    ],
    swiperOptions: {
      updateOnWindowResize: true,
      slidesPerView: 5,
      centeredSlides: false,
      spaceBetween: 0,
      loop: false,
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        480: {
          slidesPerView: 2,
        },
        769: {
          slidesPerView: 3,
        },
        1440: {
          slidesPerView: 5,
        },
      },
    },
    overwriteArray: true,
    slidesArr: [],
    busyLoadNewFranchise: false,
  }),
  computed: {
    ...mapGetters({
      listFranchiseCompare: 'account/listFranchiseCompare',
      paginationCompare: 'account/paginationCompare',
    }),
  },
  watch: {
    listFranchiseCompare(newVal) {
      this.setShowPreloader(false)

      if (this.overwriteArray) {
        this.slidesArr = cloneDeep(newVal)
        return
      }

      if (typeof this.$refs.comparisonSlider === "undefined")
        this.$refs.comparisonSlider.$swiper.update()

      this.slidesArr = this.slidesArr.concat(cloneDeep(newVal))
      this.overwriteArray = true
      this.disabledNextButton = false
      this.busyLoadNewFranchise = false
    },
  },
  methods: {
    ...mapActions({
      setShowPreloader: 'account/setShowPreloader',
      updateCompareCurrentPage: 'account/updateCompareCurrentPage',
      fetchComparisonsList: 'account/fetchComparisonsList',
    }),
    onSwiperReady(swiper) {
      this.updateSliderButton(swiper)
    },
    changeSlide(prev = false) {
      if (prev)
        this.$refs.comparisonSlider.$swiper.slidePrev()
      else
        this.$refs.comparisonSlider.$swiper.slideNext()
    },
    updateSliderButton(swiper) {
      this.disabledNextButton = swiper.isEnd
      this.disabledPrevButton = swiper.isBeginning

      if (swiper.isEnd)
        this.loadNewPage()
    },
    loadNewPage() {
      if (this.paginationCompare.current_page === this.paginationCompare.last_page)
        return

      this.overwriteArray = false
      this.busyLoadNewFranchise = true
      this.updateCompareCurrentPage(this.paginationCompare.current_page + 1)
      this.fetchComparisonsList()
    },
  },
}
