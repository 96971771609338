//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Editor from "@tinymce/tinymce-vue"
import {cloneDeep} from 'lodash/fp'
import BaseButton from "~/components/buttons/BaseButton"
import CloseIcon from "~/assets/images/sign-up/close.svg?inline"

export default {
  name: 'ContactActionNotesItemEdit',
  components: {
    Editor,
    BaseButton,
    CloseIcon,
  },
  props: {
    note: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      active: false,
      init: false,
      editedNote: {},
      apiKey: process.env.TINY_MCE_API_KEY,
      text: '',
    }
  },
  computed: {
    btnTitle() {
      return this.note.id ? 'Save' : 'Create'
    },
    configEditor() {
      return {
        menubar: false,
        convert_urls: false,
        statusbar: false,
        auto_focus: 'editor',
        plugins: 'lists link image uploadfile paste',
        toolbar: 'bold italic strikethrough bullist numlist link image uploadfile',
        image_title: true,
        automatic_uploads: true,
        images_upload_url: `${process.env.BACKEND_BASE_URL}/api/v1/blog/save/image`,
        file_picker_types: 'file image',
        image_dimensions: false,
        content_css: `/gjs/css/fonts.css`,
        contextmenu: false,
        paste_filter_drop: false,
        paste_preprocess(plugin, args) {
          args.content = args.content
            .replace(/style=".*?"/g, '')
            .replace(/dir=".*?"/g, '')
            .replace(/<img.*?>/g, '')
        },
        content_style: `
          body {
            padding-left: 10px;
            padding-right: 10px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #212A4B;
            min-height: 75vh;
          }
          a {
            color: #00a769;
            text-decoration: none;
          }
          * {
            max-width: 100%;
          }
          `,
        external_plugins: {
          uploadfile: `/scripts/tinyMceFilePlugin.js`,
        },
        file_picker_callback: (callback) => {
          const fileInput = document.getElementById("file_input")
          fileInput.click()
          const listener = async () => {
            let file = fileInput.files[0]
            const fileFormat = file.name.split('.').pop()
            const imgFormats = ['jpg', 'jpeg', 'png', 'svg', 'gif', 'bmp',]
            let url = ``
            if (imgFormats.includes(fileFormat)) {
              url = `/api/v1/blog/save/image`
            } else {
              url = `/api/v2/franchisor-contacts/files/document`
            }

            let formData = new FormData()
            formData.append('file', file)
            if (file) {
              try {
                const data = await this.$axios.post(url, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })

                const location = data.data.result ? data.data.result.location : data.data.location
                callback(location, {
                  url: location,
                  location,
                  title: file.name,
                  alt: file.name,
                  text: file.name,
                  target: '_blank',
                })
              } catch (e) {
                this.$toast.error(e.response.data.message)
                console.log(e)
              }
            }
            fileInput.value = ''
            file = null
            formData = null
            fileInput.removeEventListener('input', listener)
          }
          fileInput.addEventListener('input', listener)
        },
      }
    },
  },
  mounted() {
    this.editedNote = cloneDeep(this.note)
    setTimeout(() => {
      this.active = true
    }, 300)
  },
  methods: {
    save() {
      this.active = false
      this.init = false
      this.$emit('save', this.editedNote)
    },
    close() {
      this.active = false
      this.init = false
      this.$emit('cancel')
    },
    editorInit() {
      this.init = true
    }
  }
}
