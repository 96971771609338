//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from "vform"
import ModalText from "@/components/modals/ModalText"
import BaseButton from '~/components/buttons/BaseButton'
import companyLandingFon from '~/assets/images/svg/company-landing-fon.svg?inline'
import VInputV2 from '~/components/form/VInputV2'

import hubspotMixin from "~/components/mixins/hubspotMixin"
import googleAnalyticsMixin from "~/components/mixins/googleAnalyticsMixin"

export default {
  name: 'CompanyPageLanding',
  components: {
    BaseButton,
    companyLandingFon,
    ModalText,
    VInputV2,
  },
  mixins: [
    hubspotMixin,
    googleAnalyticsMixin,
  ],
  props: {
    franchise: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sentModalText: '',
      sentModalShow: false,
      formSend: new Form({
        franchise_id: null,
        business_name: null,
        page_url: null,
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        agree: null,
        hubspotutk: null,
        busy: false,
      }),
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
    franchiseStyles() {
      let styles = ''
      if (this.franchise.style_color_dark && this.franchise.style_color_vibrant) {
        // bg
        styles += `.style-color-dark-bg { background: ${this.franchise.style_color_dark} !important; } `

        // btn
        styles += `.franchise-style-btn {
           background: ${this.franchise.style_color_vibrant} !important;
           border-color: ${this.franchise.style_color_vibrant} !important;
          } `
        styles += `.franchise-style-btn:hover {
           background: #fff !important;
           color: ${this.franchise.style_color_vibrant} !important;
          } `

        // btn-reverse
        styles += `.franchise-style-btn-reverse {
           background: #fff !important;
           border-color: ${this.franchise.style_color_vibrant} !important;
           color: ${this.franchise.style_color_vibrant} !important;
          } `
        styles += `.franchise-style-btn-reverse:hover {
           background: ${this.franchise.style_color_vibrant} !important;
           color: #fff !important;
          } `

        // svg
        styles += `.style-color-vibrant-fill * { fill: ${this.franchise.style_color_vibrant} !important } `
      }

      if (this.franchise.style_font_paragraph) {
        // paragraph
        //  styles += `@import url('https://fonts.googleapis.com/css?family=${this.franchise.style_font_paragraph}'); `
        styles += `.style-font-paragraph *:not(.style-font-title) { font-family: ${this.franchise.style_font_paragraph}, serif !important } `
      }

      if (this.franchise.style_font_title) {
        // title
        // styles += `@import url('https://fonts.googleapis.com/css?family=${this.franchise.style_font_title}'); `
        styles += `.style-font-title { font-family: ${this.franchise.style_font_title}, serif !important } `
      }

      return `<style>${styles}</style>`
    },
  },
  methods: {
    scrollToBottom() {
      if (process.client) {
        const objDiv = document.getElementsByClassName('company-pages-modal')[0]
        objDiv.scrollTop = objDiv.scrollHeight
      }
    },
    async hubspotEventSent() {
      const formSend = this.formSend
      formSend.busy = true
      formSend.franchise_id = this.franchise.id
      formSend.business_name = this.franchise.business_name
      formSend.page_uri = `${document.location.protocol}//${document.location.host}${this.$route.fullPath}`
      formSend.hubspotutk = this.$cookies.get('hubspotutk')

      const result = await this.hsLandingForm(
        this.franchise.id,
        this.franchise.business_name,
        this.formSend.first_name,
        this.formSend.last_name,
        this.formSend.email,
        this.formSend.phone,
      )
      this.sentModalText = result.msg
      this.sentModalShow = true
      formSend.busy = false

      if (result.ok) {
        await this.gaSendLandingForm()
      }
      formSend.busy = false
    },
    startNow() {
      window.open('https://calendly.com/susan-617', '_blank')
    },
  },
}
