export default {
  data : () => ({
    mimeType: ''
  }),
  methods: {
    getFileMimeType (fileInstance) {
     let hh = this.getHeader(fileInstance, (header) => {
        let type = 'unknown';

        switch (header) {
          case '89504e47':
            type = 'image/png';
            break;
          case '47494638':
            type = 'image/gif';
            break;
          case 'ffd8ffe0':
          case 'ffd8ffe1':
          case 'ffd8ffe2':
            type = 'image/jpeg';
            break;
          case '25504446':
            type = 'application/pdf';
            break;
        }

        this.mimeType = type
      })
    },
    getHeader (fileInstance, callback) {
      let fileReader = new FileReader()

      fileReader.onloadend = function (e) {
        if (e.target.readyState === FileReader.DONE) {
          const uint = new Uint8Array(e.target.result)

          let bytes = []
          uint.forEach((byte) => {
            bytes.push(byte.toString(16))
          })

          const hex = bytes.join('').toUpperCase()

          callback(hex)
        }
      }

      fileReader.readAsArrayBuffer(fileInstance.slice(0, 4));
    }
  }
}
