//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseModalV2 from '~/components/modals/BaseModalV2';
import ContactForm from '~/components/form/ContactForm';
import { SET_VISIBILITY_CONTACT_MODAL } from '~/store/mutation-types';

export default {
  components: {
    BaseModalV2,
    ContactForm,
  },
  computed: {
    visible: {
      get() {
        return this.$store.state.modals.contact.visible;
      },
      set(value) {
        this.$store.commit(`modals/contact/${SET_VISIBILITY_CONTACT_MODAL}`, value);
      },
    },
  },
};
