//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Editor from "@tinymce/tinymce-vue"
import EditPencil from "@/assets/icons/landing/editor/EditPencil.svg?inline"
import {mapGetters} from "vuex"
import {LANDING_FONT_LIST} from '~/store/constants'

export default {
  name: "LandingText",
  components: {
    Editor,
    EditPencil,
  },
  model: {
    prop: 'html',
    event: 'input',
  },
  props: {
    css: {
      default: '',
    },
    html: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    title: {
      type: Boolean,
      default: false,
    },
    collapseHeight: {
      type: Number,
      default: 0,
    },
    isHeroTitle: {
      type: Boolean,
      default: false
    },
    isHeroText: {
      type: Boolean,
      default: false
    },
    isTitle: {
      type: Boolean,
      default: false
    },
    isText: {
      type: Boolean,
      default: false
    },
    isTextBig: {
      type: Boolean,
      default: false
    },
    isTextSmall: {
      type: Boolean,
      default: false
    },
    isButton: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    apiKey: process.env.TINY_MCE_API_KEY,
    editing: false,
    toolbar: '',
    content: '',
    backup: '',
    backupState: true,
    showCollapsedBlock: false,
    blockStyles: {
      height: 'max-content',
      maxHeight: 'max-content',
    },
    showReadMoreButton: false,
  }),
  computed: {
    ...mapGetters({
      mainStyles: 'landing/main_styles'
    }),
    textReadMore() {
      return this.showCollapsedBlock ? 'Read less' : 'Read more'
    },
    fontFormats() {
      let fonts = ''
      LANDING_FONT_LIST.forEach(function (font) {
        fonts += `${font}=${font}, sans-serif; `
      })
      return fonts
    },
    fontSizes() {
      const
        from = 8
      const to = 64
      const step = 2
      let fonts = ''
      for (let size = from; size <= to; size += step) {
        fonts += `${size}px `
      }
      return fonts.trim()
    },
    configEditor(){
      return {
        branding: false,
          menubar: false,
          statusbar: false,
          plugins: 'lists searchreplace visualblocks insertdatetime table paste autoresize',
          toolbar: this.toolbar,
          fontsize_formats: this.fontSizes,
          font_formats: this.fontFormats,
          visualchars_default_state: true,
          entity_encoding: 'raw',
          paste_data_images: false,
          paste_filter_drop: false,
          paste_preprocess(plugin, args) {
          args.content = args.content
            .replace(/style=".*?"/g, '')
            .replace(/dir=".*?"/g, '')
            .replace(/<img.*?>/g, '')
        },
      }
    },
  },
  watch: {
    collapseHeight(newVal) {
      if (newVal > 0 && window.innerWidth > 1200 && !this.editable) {
        this.blockStyles.height = `${this.collapseHeight}px`
        this.blockStyles.maxHeight = `${this.$refs.textBlock.clientHeight}px`
        this.showReadMoreButton = true
      }
    },
  },
  created() {
    this.content = this.html
    if (this.text) {
      this.toolbar = 'fontselect fontsizeselect bold italic forecolor bullist numlist alignleft aligncenter alignright alignjustify'
    }
    if (this.title) {
      this.toolbar = 'formatselect fontselect fontsizeselect bold italic forecolor'
    }
  },
  methods: {
    start() {
      this.editing = true
    },
    save() {
      this.$emit('input', this.content)
      this.editing = false
    },
    cancel() {
      this.content = this.html
      this.editing = false
    },
  }
}
