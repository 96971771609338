//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters} from "vuex"
import cloneDeep from 'lodash/cloneDeep'
import WorkBookInput from "@/components/workbook/elements/WorkBookInput"

import {
  WORK_BOOK,
  WORK_BOOK_FIELD_TYPES,
  WORK_BOOK_FIELD_VALUE_TYPES,
} from "@/store/constants"

export default {
  name: "WorkBookCultureTraits",
  components: {WorkBookInput},
  props: {
    numberTop: {
      type: Number,
      default: 0
    },
    blockIndex: {
      type: Number,
      default: 0
    },
    updateTop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    fieldTypes: WORK_BOOK_FIELD_TYPES,
    fieldValueTypes: WORK_BOOK_FIELD_VALUE_TYPES,
  }),
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      franchise: 'account/workbook/franchise',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
      franchiseeNameList: 'account/workbook/franchiseeNameList',
      franchiseeTotalList: 'account/workbook/franchiseeTotalList',
    }),
    questionsBlock() {
      return WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex].questionsBlocks[this.blockIndex]
    },
    questionBlockClassList() {
      return this.questionsBlock.classList ?? ``
    },
  },
  methods: {
    ...mapActions({
      changeAnswer: 'account/workbook/changeAnswer',
      updateListFranchise: 'account/workbook/updateListFranchise',
    }),
    getField(fieldIndex) {
      return this.questionsBlock.fields[fieldIndex] ?? null
    },
    getFieldValueType(fieldIndex) {
      return this.getField(fieldIndex).valueType ?? false
    },
    getFieldValueTypeIndex(fieldIndex) {
      return this.getField(fieldIndex).valueTypeIndex ?? false
    },
    getValue(questionId, fieldIndex) {
      const fieldType = this.getFieldValueType(fieldIndex)
      const fieldTypeIndex = this.getFieldValueTypeIndex(fieldIndex)
      const questionIdShifted = questionId - 1

      if (fieldType === this.fieldValueTypes.TEN_FRANCHISEE) {
        const franchiseeNameList = this.franchiseeNameList(fieldTypeIndex)
        return franchiseeNameList[questionIdShifted] ?? null
      }

      if (fieldType === this.fieldValueTypes.TEN_FRANCHISEE_TOTAL) {
        const franchiseeNameList = this.franchiseeTotalList(fieldTypeIndex)
        return franchiseeNameList[questionIdShifted] ?? null
      }

      return this.getValueDefault(questionId, fieldIndex)
    },
    getCellClass(fieldIndex) {
      return this.questionsBlock.fields[fieldIndex].cellClassList ?? ``
    },
    getValueDefault(questionId, fieldIndex) {
      const slug = this.questionsBlock.fields[fieldIndex].slug
      const savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)
      return savedValue ? savedValue.value[slug] : null
    },
    handlerUpdateValue(questionId, fieldIndex, value) {
      const slug = this.questionsBlock.fields[fieldIndex].slug
      const savedValue = cloneDeep(this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId))
      const questionIndex = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.findIndex(item => item.id === questionId)
      savedValue.value[slug] = value
      this.changeAnswer({blockIndex: this.blockIndex, questionIndex, value: savedValue.value})


      if (this.updateTop) {
        const franchise = cloneDeep(savedValue.value)
        franchise.id = questionId
        this.updateListFranchise({franchise})
      }

    },
    getFieldStyle(field) {
      return field.styleList ?? ``
    },
  }
}
