//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    size: {
      type: [String, Number],
      default: () => '18',
    },
    color: {
      type: String,
      default: '#212A4B'
    }
  },
};
