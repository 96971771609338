//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FindChatLoupe from '@/assets/images/svg/find-chat-loupe.svg?inline'

export default {
  name: 'FindChat',
  components: {
    FindChatLoupe,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Find chat',
    }
  },
}
