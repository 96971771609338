import { render, staticRenderFns } from "./BlogPostsSidebar.vue?vue&type=template&id=7c48db81&scoped=true&"
import script from "./BlogPostsSidebar.vue?vue&type=script&lang=js&"
export * from "./BlogPostsSidebar.vue?vue&type=script&lang=js&"
import style0 from "./BlogPostsSidebar.vue?vue&type=style&index=0&id=7c48db81&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c48db81",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlogPostsSidebarSearch: require('/root/repo/components/blog/BlogPostsSidebarSearch.vue').default,BlogPostsSubscribe: require('/root/repo/components/blog/BlogPostsSubscribe.vue').default,BlogPostsSidebarLast: require('/root/repo/components/blog/BlogPostsSidebarLast.vue').default,BlogPostsSidebarCategory: require('/root/repo/components/blog/BlogPostsSidebarCategory.vue').default,BlogPostsSidebarSocial: require('/root/repo/components/blog/BlogPostsSidebarSocial.vue').default})
