//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters,
} from "vuex"
import helpers from "@/components/mixins/helpers"
import hubspotMixin from "@/components/mixins/hubspotMixin"
import CloseIcon from "assets/icons/cross/CloseIcon.svg?inline"
import {cloneDeep} from 'lodash/fp'

import {
  HUBSPOT_EVENT_TYPES,
} from '~/store/constants'

export default {
  name: "CardSliderComparison",
  components: {
    CloseIcon,
  },
  mixins: [
    helpers,
    hubspotMixin,
  ],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    attributes: {
      type: Array,
      default: () => ([])
    },
    fieldTypes: {
      type: Object,
      default: () => ({})
    },
    isBlocked:{
      type:Boolean,
      default: false,
    },
  },
  data:() => ({
    franchise:{}
  }),
  computed:{
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
      busyUpdateStatusProgressFranchise:'account/busyUpdateStatusProgressFranchise',
    }),
    checkStatusProgress() {
      if (this.isBlocked)
        return false

      return this.franchise.flow_status !== 'flow_not_started'
    },
    checkStatusProgressBusy() {
      return this.busyUpdateStatusProgressFranchise === this.franchise.franchise_id
    },
    getProgressLabel() {
      let text = 'Begin Today';

      if (this.isBlocked)
        return text

      if (!this.authenticated)
        return text

      if (!this.user.isFranchisee)
        return text

      if (this.franchise.flow_status === 'flow_in_progress')
        text = 'In Progress'

      if (this.franchise.flow_status === 'flow_closed')
        text = 'Closed'

      return text
    },
  },
  watch:{
    item(newVal){
      this.franchise = cloneDeep(newVal)
    },
  },
  created() {
    this.franchise = cloneDeep(this.item)
  },
  methods: {
    ...mapActions({
      updateComparisonStatusFranchise: 'account/updateComparisonStatusFranchise',
      updateProgressStatusFranchise: 'account/updateProgressStatusFranchise',
      fetchComparisonsList: 'account/fetchComparisonsList',
      updateCompareCurrentPage: 'account/updateCompareCurrentPage',
      setUserClick: 'account/setUserClick',
    }),
    openIframe(key) {
      this.handlerButtonProgress(false)

      let link = ''

      if (key === 'item7Url') {
        link = this.franchise?.files?.item_7?.url
        this.hsCustomEvent(this.franchise.franchise_id, HUBSPOT_EVENT_TYPES.openItem7)
      }

      if (key === 'item19Url') {
        link = this.franchise?.files?.item_19?.url
        this.hsCustomEvent(this.franchise.franchise_id, HUBSPOT_EVENT_TYPES.openItem19)
      }

      if (key === 'fddUrl') {
        link = this.franchise?.files?.fdd?.url
        this.hsCustomEvent(this.franchise.franchise_id, HUBSPOT_EVENT_TYPES.openFullFDD)
      }

      if (key === 'presentation') {
        link = this.franchise?.files?.fdd?.presentation?.url
        this.hsCustomEvent(this.franchise.franchise_id, HUBSPOT_EVENT_TYPES.openPresentation)
      }

      if (key === 'franchiseesUrl') {
        link = this.franchise?.files?.fdd?.franchisees?.url
        this.hsCustomEvent(this.franchise.franchise_id, HUBSPOT_EVENT_TYPES.openFranchiseesList)
      }

      if (link === '')
        return

      this.$store.dispatch('iframe/openModal', link)
    },
    checkValueIframe(key) {
      if (key === 'item7Url')
        return this.franchise?.files?.item_7 !== null

      if (key === 'item19Url')
        return this.franchise?.files?.item_19 !== null

      if (key === 'fddUrl')
        return this.franchise?.files?.fdd !== null

      if (key === 'presentation')
        return this.franchise?.files?.presentation !== null

      if (key === 'franchiseesUrl')
        return this.franchise?.files?.franchisees !== null

      return false
    },
    openLink(key) {
      let link = ''

      if (key === 'public_website_business_permalink')
        link = this.franchise.public_website_business_permalink

      if (key === 'promo_video')
        link = this.franchise.video

      if (link === '')
        return

      window.open(link, '_blank');
    },
    checkValueLink(key) {
      if (key === 'public_website_business_permalink')
        return this.franchise.public_website_business_permalink !== null

      if (key === 'promo_video')
        return this.franchise.video !== null

      return false
    },
    async handlerButtonProgress(isRedirect = true) {
      if (this.isBlocked)
        return

      if (isRedirect && this.franchise.flow_status !== 'flow_not_started') {
        await this.$router.push(`/account/dashboard`)
        return
      }

      if (this.franchise.flow_status !== 'flow_not_started') {
        return
      }

      this.franchise.flow_status = 'flow_in_progress'
      await this.setUserClick(true)
      await this.updateProgressStatusFranchise(this.franchise)
      await this.hsCustomEvent(this.franchise.franchise_id, 'begin-today')
    },
    async handlerButtonCompare() {
      if (this.isBlocked)
        return

      await this.setUserClick(true)
      await this.updateCompareCurrentPage(1)
      await this.updateComparisonStatusFranchise(this.franchise.franchise_id)
    },
  },
}
