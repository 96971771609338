export default {
  data() {
    return {
      gaCode: 'UA-189975007-1',
    }
  },
  methods: {
    async gaSendLandingForm() {
      this.init();
      ga('send', {
        hitType: 'event',
        eventCategory: 'landing-form',
        eventAction: 'send',
        eventLabel: 'send-landing-form',
      });
    },
    async gaSendFranchiseSignUpForm() {
      this.init();
      ga('send', {
        hitType: 'event',
        eventCategory: 'sign-up-franchisee-form',
        eventAction: 'send',
        eventLabel: 'send-sign-up-franchisee-form',
      });
    },
    async gaSendFranchisorSignUpForm() {
      this.init();
      ga('send', {
        hitType: 'event',
        eventCategory: 'sign-up-franchisor-form',
        eventAction: 'send',
        eventLabel: 'send-sign-up-franchisor-form',
      });
    },
    async gaSendFranchisorSignUpFormFull() {
      this.init();
      ga('send', {
        hitType: 'event',
        eventCategory: 'sign-up-franchisor-form-full',
        eventAction: 'send',
        eventLabel: 'send-sign-up-franchisor-form-full',
      });
    },
    init() {
      ga.create(this.gaCode, 'auto');
    }
  },
};
