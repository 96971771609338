//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CompanyInnovationCurve from "@/components/company/CompanyInnovationCurve";
import InputCustom from "@/components/form/customField/InputCustom";
import TextAreaCustom from "@/components/form/customField/TextAreaCustom";
import VFormAvatarInput from "@/components/form/VFormAvatarInput";
import ClearIcon from "@/components/icons/ClearIcon";
import VerifiedIcon from "@/components/icons/VerifiedIcon";
import tabEditFranchise from "@/components/mixins/tabEditFranchise";
import {cloneDeep} from "lodash/fp";
import Form from 'vform';
import {mapActions, mapGetters} from "vuex";
import RatingStars from "~/components/RatingStars";

export default {
  name: "FormEditFranchiseMain",
  components: {
    TextAreaCustom,
    InputCustom, ClearIcon, VerifiedIcon, VFormAvatarInput, CompanyInnovationCurve, RatingStars
  },
  mixins: [
    tabEditFranchise
  ],
  data() {
    return {
      tagField: '',
      delete_file_ids: [],
      form: new Form({
        certified_by_fa: '',
        company_twitter_username: '',
        company_stage: '',
        company_short_description: '',
        company_description: '',
        location: '',
        number_of_locations: '',
        founders: [],
        ratings: [],
        tags: [],
        delete_avatar_ids: [],
      }),
      optionTags: []
    }
  },
  created() {
    this.$on('saveFormEditCompany', this.submit);
    this.updateForm()
  },
  computed: {
    ...mapGetters({
      franchise: 'business-v2/franchise',
    }),
    companyAttributes() {
      const founders = this.franchise.founders.map(item => {
        return {
          id: item.id ? item.id : '',
          first_name: item.first_name ?? '',
          last_name: item.last_name ?? '',
          description: item.description ?? '',
          avatar: item.avatar ?? '',
          avatar_url: item.avatar && item.avatar.hasOwnProperty('url')
              ? item.avatar.url.medium
              : ''
        }
      })

      const ratings = this.franchise.self_ratings.map(item => {
        return {
          id: item.id ? item.id : '',
          category_id: item.category_id,
          category: item.category,
          rating_value: item.rating_value,
        }
      })

      return {
        business_name: this.franchise.business_name,
        certified_by_fa: this.franchise.certified_by_fa,
        company_twitter_username: this.franchise.company_twitter_username,
        company_stage: this.franchise.company_stage,
        company_short_description: this.franchise.company_short_description,
        company_description: this.franchise.company_description,
        industry: this.franchise.industry,
        location: this.franchise.location,
        number_of_locations: this.franchise.number_of_locations,
        founders,
        ratings,
        surveyRatings: this.franchise.survey_ratings,
        tags: this.franchise.tags,
        delete_avatar_ids: [],
      }
    }
  },
  methods: {
    ...mapActions({
      fetchFranchise: 'business-v2/fetchFranchise',
    }),
    addTag() {
      this.tagField = this.tagField.toLowerCase();
      const minNameLength = 2;

      if (this.tagField.length > minNameLength) {
        this.$emit('change')
        // remove if there is a duplicate
        this.deleteTag(this.tagField);

        this.optionTags.push({
          id: 0,
          name: this.tagField,
        });
        this.tagField = '';
      }
    },
    deleteTag(name) {
      this.$emit('change')
      name.toLowerCase();

      const removeIndex = this.optionTags.map(function (item) {
        return item.name;
      }).indexOf(name);

      if (removeIndex >= 0) {
        this.optionTags.splice(removeIndex, 1);
      }
    },
    addFounders() {
      this.$emit('change')
      const foundersArr = this.form.founders;
      foundersArr.push({
        id: '',
        first_name: '',
        last_name: '',
        description: '',
        avatar: ''
      })
    },
    deleteFounders(index) {
      this.$emit('change')
      const avatar = this.form.founders[index].avatar;

      if (avatar && avatar.id)
        this.form.delete_avatar_ids.push(avatar.id)

      this.form.founders.splice(index, 1)
    },
    updateForm() {
      const company = this.companyAttributes;

      Object.keys(company).forEach(key => {
        if (key === 'tags') {
          this.optionTags = cloneDeep(company[key])
        } else if (key in this.form) {
            this.form[key] = company[key]
        }
      })

      if (!company.founders.length) {
        this.addFounders()
      }
    },
    arithmeticMeanRating() {
      const rating = this.form.ratings;
      const meanRating = rating.reduce((total, item) => {
        return total + parseFloat(item.rating_value)
      }, 0) / rating.length;

      return Math.round(meanRating*10)/10
    },
    updateFounderAvatar(index, image) {
      this.$emit('change')
      const founder = this.form.founders[index];
      if (founder.avatar && founder.avatar.id)
        this.form.delete_avatar_ids.push(founder.avatar.id)

      if (typeof image === 'string') {
        founder.avatar = '';
      } else {
        founder.avatar = image;
      }
    },
    async submit() {
      try {
        const url = `/api/v2/businesses/edit/${this.franchise.id}/${this.franchise.version_id}/main`
        this.form.tags = this.optionTags;

        await this.form.submit('post', url, {
          headers: {'Content-Type': 'multipart/form-data'},
          transformRequest: [function (data) {
            const formData = new FormData
            Object.keys(data).forEach(key => {
              if (
                  key === 'founders' ||
                  key === 'ratings' ||
                  key === 'delete_avatar_ids' ||
                  key === 'tags'
              )
                return;

              formData.append(key, data[key] ?? '')
            })

            data.ratings.forEach(
                (el, i) => {
                  formData.append(`ratings[${i}][id]`, el.id ? el.id : '');
                  formData.append(`ratings[${i}][category_id]`, el.category_id);
                  formData.append(`ratings[${i}][rating_value]`, el.rating_value);
                }
            );

            data.tags.forEach(
                (el, i) => {
                  formData.append(`tags[${i}][id]`, el.id);
                  formData.append(`tags[${i}][name]`, el.name);
                }
            );

            data.founders.forEach(
                (el, i) => {
                  const founder = el;
                  if (
                      !founder.first_name.length
                      && !founder.last_name.length
                      && !founder.description.length
                      && !founder.avatar.length
                  ) {
                    founder.id = '';
                  }

                  formData.append(`founders[${i}][id]`, founder.id ?? '');
                  formData.append(`founders[${i}][first_name]`, founder.first_name ?? '');
                  formData.append(`founders[${i}][last_name]`, founder.last_name ?? '');
                  formData.append(
                      `founders[${i}][description]`,
                      founder.description ? founder.description : ''
                  );

                  formData.append(
                      `founders[${i}][avatar]`,
                      founder.avatar && founder.avatar.id ? '' : founder.avatar
                  );
                }
            );
            data.delete_avatar_ids.forEach(
                (el, i) => formData.append(`delete_avatar_ids[${i}]`, el)
            );
            return formData;
          }],
        })
        this.$emit('sendSuccess')
        await this.fetchFranchise({
          franchiseId:this.franchise.id,
          versionId:this.franchise.version_id,
        })
      } catch (e) {
        this.$emit('sendError')
      }
    },
  }
}
