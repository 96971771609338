//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlider from 'vue-slider-component'
import ArrowSelectRange from '../animated-icons/ArrowSelectRange'

export default {
  components: {
    ArrowSelectRange,
    VueSlider
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: () => 'Title'
    },
    // value: {
    //   type: [String, Array],
    //   required: true
    // },
    sliderParams: {
      type: Object,
      default () {
        return {
          min: 0,
          max: 100,
          data: []
        }
      }
    }
  },
  data () {
    return {
      isActive: false,
      selectedTitle: '',
      sliderData: [],
      clearButton: false
    }
  },
  created () {
    if (process.client) {
      document.addEventListener('click', this.documentClick)
    }

    this.sliderParams.data.forEach((value, k) => {
      this.sliderData.push(value.title)
    })
  },
  destroyed () {
    if (process.client) {
      document.removeEventListener('click', this.documentClick)
    }
  },
  methods: {
    handleClickSelect (e) {
      let selectRangeMenu = this.$refs.selectRangeMenu
      let target = e.target

      if (selectRangeMenu !== target && !selectRangeMenu.contains(target)) {
        this.isActive = !this.isActive
      }
    },
    handleChangeSlider (e) {
      this.clearButton = true
      const findByTitle = this.sliderParams.data.find(el => el.title === e)
      this.selectedTitle = e
      this.$emit('change', findByTitle.value)
    },
    handleClearValue (e) {
      this.isActive = false
      this.selectedTitle = ''
      this.clearButton = false
      this.$emit('clear')
    },
    documentClick (e) {
      let el = this.$refs.selectRange
      let target = e.target
      if (el !== target && !el.contains(target)) {
        this.isActive = false
      }
    }
  }
}
