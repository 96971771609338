
import { Bar, mixins } from "vue-chartjs";

const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object,
      default() {
        return {
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
              label (tooltipItem) {
                return tooltipItem.yLabel;
              }
            }
          },
          responsive: true,
          scales: {
            xAxes: [{
              gridLines: {
                drawOnChartArea: false,
                drawTicks: false
              },
              stacked: true,
              barThickness: 16,
              maxBarThickness: 16,
              ticks: {
                stepSize: 10, // <----- This prop sets the stepSize
                fontColor: 'rgba(158,140,167,1)',
                fontFamily: 'Open Sans',
                fontSize: 12,
                padding: 12,
              },
            }],
            yAxes: [{
              gridLines: {
                drawTicks: false
                // drawBorder: false,
              },
              ticks: {
                userCallback(item) {
                  return item;
                },
                min: 0,
                max: 40,
                padding: 17,
                // forces step size to be 5 units
                stepSize: 10, // <----- This prop sets the stepSize
                fontColor: 'rgba(158,140,167,1)',
                fontFamily: 'Open Sans',
                fontSize: 12
              },
            }]
          },
          maintainAspectRatio: false,
        };
      }
    }
  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
    this.$data._chart.generateLegend()
  }
};
