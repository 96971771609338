//
//
//
//
//
//
//

export default {
name: "VerifiedIcon"
}
