import { render, staticRenderFns } from "./CompanyPageLandingNew.vue?vue&type=template&id=230f06ca&scoped=true&"
import script from "./CompanyPageLandingNew.vue?vue&type=script&lang=js&"
export * from "./CompanyPageLandingNew.vue?vue&type=script&lang=js&"
import style0 from "./CompanyPageLandingNew.vue?vue&type=style&index=0&id=230f06ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "230f06ca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingHeader: require('/root/repo/components/landing/LandingHeader.vue').default,LandingSectionHero: require('/root/repo/components/landing/sections/LandingSectionHero.vue').default,LandingSectionVideo: require('/root/repo/components/landing/sections/LandingSectionVideo.vue').default,LandingSectionImage: require('/root/repo/components/landing/sections/LandingSectionImage.vue').default,LandingSectionCollapse: require('/root/repo/components/landing/sections/LandingSectionCollapse.vue').default,LandingSectionGallery: require('/root/repo/components/landing/sections/LandingSectionGallery.vue').default,LandingSectionComments: require('/root/repo/components/landing/sections/LandingSectionComments.vue').default,LandingSectionStats: require('/root/repo/components/landing/sections/LandingSectionStats.vue').default,LandingSectionFacts: require('/root/repo/components/landing/sections/LandingSectionFacts.vue').default,LandingSectionPlayList: require('/root/repo/components/landing/sections/LandingSectionPlayList.vue').default,LandingFooter: require('/root/repo/components/landing/LandingFooter.vue').default,LandingControl: require('/root/repo/components/landing/LandingControl.vue').default,LandingStickyButton: require('/root/repo/components/landing/elements/LandingStickyButton.vue').default,LandingMenu: require('/root/repo/components/landing/LandingMenu.vue').default})
