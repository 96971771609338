//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Loader from '@/components/Loader'
import DocGreenIcon from '@/assets/icons/files/DocGreenIcon.svg?inline'
import CrossIcon from '@/assets/icons/cross/CrossIcon.svg?inline'

export default {
  components: {
    Loader,
    DocGreenIcon,
    CrossIcon,
  },
  props: {
    counter: {
      type: [Number, String],
      default: 0,
    },
    title: {
      type: String,
      required: true,
    },
    acceptFormats:{
      type: String,
      default: '',
    }
  },
  data() {
    return {
      isLoading: false,
      chosenFile: null,
    };
  },
  methods: {
    /**
     * Set preloader state
     * @param {Boolean} loading - preloading state
     */
    setLoading(loading) {
      this.isLoading = loading;
    },
    /**
     * File change handler
     * @param {Event} ev file input event
     */
    handleChangeFile(ev) {
      if (ev.target.files.length) {
        this.chosenFile = ev.target.files[0]
        this.$emit('file-chose', ev.target.files[0]);
      }
    },
    /**
     * Click cross event
     */
    handleDeleteFile() {
      this.$refs['file-uploader-input'].value = null
      this.chosenFile = null;
      this.$emit('file-chose', '');
    },
  },
};
