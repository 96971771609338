//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalText from "@/components/modals/ModalText";
import Form from 'vform'

export default {
  components: {
    ModalText,
  },
  data() {
    return {
      formSubscribe: new Form({
        email: null,
      }),
      subscribeEmail: null,
      emailErrorClass: null,
      modalSubscribeShow: false,
      regEmail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    async subscribe() {
      if (this.subscribeEmail == null || !this.regEmail.test(this.subscribeEmail)) {
        this.emailErrorClass = 'error'
        return null;
      }

      this.emailErrorClass = null
      this.formSubscribe.email = this.subscribeEmail
      this.formSubscribe.hubspotutk = this.$cookies.get('hubspotutk')

      try {
        await this.formSubscribe.post('/api/v1/other/subscribe-form')
        this.modalSubscribeShow = true

        try {
          const form = new Form({
            email: this.subscribeEmail,
          });
          await form.post('/api/v1/blog-subscribe/subscribe')
          this.subscribeEmail = null
        } catch (e) {
          console.log(e)
        }

      } catch (e) {
        this.emailErrorClass = 'error'
        console.log(e);
      }

      return true
    },
  },
}
