//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters,
} from 'vuex'
import helpers from "@/components/mixins/helpers"
import {BTable} from 'bootstrap-vue'
import MoneyFormat from 'vue-money-format'
import FddIcon from '@/components/icons/FddIcon'
import LikeIconV4 from '@/components/icons/LikeIconV4'
import hubspotMixin from "@/components/mixins/hubspotMixin"
import ModalText from "@/components/modals/ModalText"

import {
  EVENT_COMPARE,
  HUBSPOT_EVENT_TYPES,
} from '~/store/constants'

export default {
  name: 'VBTableNew',
  components: {
    ModalText,
    LikeIconV4,
    FddIcon,
    BTable,
    MoneyFormat,
  },
  mixins: [
    hubspotMixin,
    helpers,
  ],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    progress: {
      type: Number,
      default: 0
    },
    isBlocked: {
      type: Boolean,
      default: false
    },
    isPublic:{
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    EVENT_COMPARE,
    modalSignedInMsgShow: false,
    SignedInMsg:
      'You are signed in as a Franchisor. If you are interested in buying this franchise please register a new account as a Franchisee. Please  use another email to sign up as a Franchisee.',
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
      inProgressBusinessClientStep: 'franchisee-steps/inProgressBusinessClientStep',
      paginationCompare: 'account/paginationCompare',
      paginationProgress: 'account/paginationProgress',
      busyUpdateStatusComparisonFranchise: 'account/busyUpdateStatusComparisonFranchise',
      busyUpdateStatusProgressFranchise: 'account/busyUpdateStatusProgressFranchise',
      busyUpdateStatusLikeFranchise: 'account/busyUpdateStatusLikeFranchise',
      sort: 'account/sort',
    }),
  },
  watch: {
    paginationCompare() {
      this.updateCompareLabel()
    },
    paginationProgress() {
      this.updateProgressLabel()
    },
  },
  mounted() {
    this.updateCompareLabel()
    this.updateProgressLabel()
  },
  methods: {
    ...mapActions({
      fetchFranchises: 'account/fetchFranchises',
      fetchComparisonsList: 'account/fetchComparisonsList',
      fetchLikeList: 'account/fetchLikeList',
      fetchProgressList: 'account/fetchProgressList',
      updateComparisonStatusFranchise: 'account/updateComparisonStatusFranchise',
      updateProgressStatusFranchise: 'account/updateProgressStatusFranchise',
      updateLikeStatusFranchise: 'account/updateLikeStatusFranchise',
      updateSort: 'account/updateSort',
      setUserClick: 'account/setUserClick',
    }),
    // compare actions
    checkStatusCompare(franchise) {
      return franchise.in_comparison
    },
    checkStatusCompareBusy(franchise) {
      return this.busyUpdateStatusComparisonFranchise === franchise.franchise_id
    },
    updateCompareLabel() {
      const compareIndex = this.fields.findIndex(item => item.key === 'compare')

      let label = 'Compare'

      if (this.paginationCompare.total > 0)
        label = `Compare ${this.paginationCompare.total}/10`

      if (this.paginationCompare.total >= 10)
        this.fields[compareIndex].thClass += ' max-amount'

      this.fields[compareIndex].label = label
    },
    async handlerButtonCompare(franchise) {
      if (this.isBlocked)
        return

      if (!this.authenticated) {
        await this.$store.dispatch('registration/openModal')
        return
      }

      if (!this.user.isFranchisee) {
        this.modalSignedInMsgShow = true
        return
      }
      await this.setUserClick(true)
      await this.updateComparisonStatusFranchise(franchise.franchise_id)
    },
    // progress actions
    checkStatusProgress(franchise) {
      return franchise.flow_status !== 'flow_not_started'
    },
    checkStatusProgressBusy(franchise) {
      return this.busyUpdateStatusProgressFranchise === franchise.franchise_id
    },
    updateProgressLabel() {
      if (this.isBlocked)
        return

      const progressIndex = this.fields.findIndex(item => item.key === 'contact')

      let label = 'Select 10'

      if (this.paginationProgress.total > 0)
        label = `Selected ${this.paginationProgress.total}/10`

      if (this.paginationProgress.total >= 10)
        this.fields[progressIndex].thClass += ' max-amount'

      this.fields[progressIndex].label = label
    },
    getProgressLabel(franchise) {
      let text = 'Begin Today';

      if (!this.authenticated)
        return text

      if (!this.user.isFranchisee)
        return text

      if (franchise.flow_status === 'flow_in_progress')
        text = 'In Progress'

      if (franchise.flow_status === 'flow_closed')
        text = 'Closed'

      return text
    },
    handlerOpenFile(franchise, link, franchiseId) {
      this.handlerButtonProgress(franchise, false)
      this.showFile(link, franchiseId)
    },
    async handlerButtonProgress(franchise, isRedirect = true) {
      if (this.isBlocked)
        return

      if (!this.authenticated) {
        await this.$store.dispatch('registration/openModal')
        return
      }

      if (!this.user.isFranchisee) {
        this.modalSignedInMsgShow = true
        return
      }

      if (isRedirect && franchise.flow_status !== 'flow_not_started') {
        await this.$router.push(`/account/dashboard`);
        return
      }

      if (franchise.flow_status !== 'flow_not_started') {
        return
      }

      await this.setUserClick(true)
      await this.updateProgressStatusFranchise(franchise)
      await this.hsCustomEvent(franchise.franchise_id, 'begin-today')
    },
    // like actions
    checkStatusLike(franchise) {
      return franchise.like
    },
    checkStatusLikeBusy(franchise) {
      return this.busyUpdateStatusLikeFranchise === franchise.franchise_id
    },
    async handlerButtonLike(franchise) {
      if (this.isBlocked)
        return

      if (!this.authenticated) {
        await this.$store.dispatch('registration/openModal')
        return
      }

      if (!this.user.isFranchisee) {
        this.modalSignedInMsgShow = true
        return
      }
      await this.setUserClick(true)
      await this.updateLikeStatusFranchise(franchise.franchise_id)
    },
    ucFirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    async sortingChanged(e) {
      await this.updateSort({
        field: e.sortBy,
        direction: e.sortDesc ? 'desc' : 'asc'
      })

      await this.fetchFranchises()
    },
    showFile(link, franchiseId) {
      if (this.authenticated) {
        this.hsCustomEvent(franchiseId, HUBSPOT_EVENT_TYPES.openFullFDD)
        this.$store.dispatch('iframe/openModal', link)
      } else {
        this.$store.dispatch('registration/openModal');
      }
    },
  },
};
