//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import {mapActions, mapGetters} from "vuex"
  import {cloneDeep} from "lodash/fp"
  import {evaluate} from"mathjs"
  import {
    WORK_BOOK,
    WORK_BOOK_CALC_TYPE,
    WORK_BOOK_QUESTION_TYPES,
  } from '@/store/constants'

  export default {
  name: "WorkBookMathQuestionList",
  props: {
    blockIndex: {
      type: Number,
      default: 0
    },
  },
  data: () => ({
    answerTypes: WORK_BOOK_QUESTION_TYPES,
    calcType: WORK_BOOK_CALC_TYPE,
    formula: {},
    formulaAnswer: {},
  }),
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
    }),
    questionsBlockName() {
      return this.questionsBlock.name ?? null
    },
    questionsBlock() {
      return WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex].questionsBlocks[this.blockIndex]
    },
  },
  created() {
    this.questionsBlock.questions.forEach( (question, questionIndex) => {
      if (question.formula) {
        this.formulaAnswer[`answer${questionIndex}`] = 0
        question.formula.forEach( arg => {
          if (arg.type === this.calcType.NUMBER) {
            this.formula[arg.slug] = this.getValue(question.id, arg.slug)
          }
        })

        this.calcValue(question.formula, questionIndex)
      }
    })
  },
  methods: {
  ...mapActions({
    changeAnswer: 'account/workbook/changeAnswer',
  }),
  getTotalStyle(question) {
    return question.totalStyle ?? ``
  },
  prepareFormula(formula) {
    let preparedFomula = ``
    formula.forEach( arg => {
      if (arg.type === this.calcType.OPERATOR) {
        preparedFomula += arg.text
      }
      if (arg.type === this.calcType.NUMBER) {
        preparedFomula += this.formula[arg.slug] ?? `0`
      }
    })
    return preparedFomula
  },
  calcFormula(formula) {
    const decimalSize = 100
    let calculatedFormula

    try {
      calculatedFormula = evaluate(formula)

      if (
        Number.isNaN(calculatedFormula) ||
        calculatedFormula === Infinity
      ) {
        calculatedFormula = 0
      }

    } catch (e) {
      calculatedFormula = 0
    }

    if (calculatedFormula) {
      calculatedFormula = Math.round((calculatedFormula + Number.EPSILON) * decimalSize) / decimalSize
    }

    return calculatedFormula
  },
  checkValue(questionId, value){
    const savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)
    return savedValue.value === value
  },
  calcValue(formula, questionIndex){
    const bufferFormula = cloneDeep(this.formulaAnswer)

    bufferFormula[`answer${questionIndex}`] = this.calcFormula(
      this.prepareFormula(formula)
    )

    this.formulaAnswer = {}
    this.formulaAnswer = bufferFormula
  },
  changeValue(formula, questionIndex, inputSlug, inputVal) {

    if (inputSlug) {
      this.formula[inputSlug] = inputVal ?? 0
    }

    this.calcValue(formula, questionIndex)

    this.changeAnswer({blockIndex:this.blockIndex, questionIndex, value: this.formula})
  },
  getValue(questionId, slug){
    const savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)
    return savedValue ? savedValue.value[slug] : null
  },
  handlerAddNewValue(questionId){
    const savedValue = cloneDeep(this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)).value
    const questionIndex = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.findIndex(item => item.id === questionId)

    savedValue.push('')

    this.changeValue(questionIndex,savedValue)
  },
  }
}
