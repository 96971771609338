import { render, staticRenderFns } from "./BlogPostsListItem.vue?vue&type=template&id=19e0270c&scoped=true&"
import script from "./BlogPostsListItem.vue?vue&type=script&lang=js&"
export * from "./BlogPostsListItem.vue?vue&type=script&lang=js&"
import style0 from "./BlogPostsListItem.vue?vue&type=style&index=0&id=19e0270c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19e0270c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlogPostImage: require('/root/repo/components/blog/BlogPostImage.vue').default})
