//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import landingStyles from "@/components/mixins/landingStyles";
import LandingContactForm from "@/components/form/LandingContactForm";
import LandingText from "@/components/landing/elements/LandingText";
import LandingButton from "@/components/landing/elements/LandingButton";
import SectionTools from "@/components/landing/elements/SectionTools";
import {mapGetters} from "vuex";

export default {
  name: "LandingSectionHero",
  components: {
    SectionTools,
    LandingContactForm,
    LandingText,
    LandingButton,
  },
  mixins: [
    landingStyles
  ],
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    section: {
      type: Object,
      default: () => {
      }
    },
    currentIndex: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      showTools: false,
      containerSize: {
        width: 0,
        height: 0
      },
    }
  },
  computed: {
    ...mapGetters({
      mainStyles: 'landing/main_styles',
    }),
    backgroundImage() {
      const imageId = this.section.styles.backgroundImage ?? ''

      return (imageId === '') ? '' : `${process.env.BACKEND_BASE_URL}/api/v2/landing/${imageId}/image`
    },
  },
  mounted() {
    if (this.editable)
      this.updateSectionSizes()
  },
  methods: {
    updateSectionSizes() {
      this.containerSize.width = this.$refs[`container_${this.section.id}`].clientWidth
      this.containerSize.height = this.$refs[`section_${this.section.id}`].clientHeight
    },
    updateSection() {
      this.updateSectionSizes()
      this.$store.dispatch('landing/updateSection', {section: this.section, index: this.currentIndex})
    }
  }
}
