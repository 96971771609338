//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {MODAL_EDIT_QUESTIONNAIRE_ID} from "@/store/constants";
import Form from 'vform'
import {mapActions, mapGetters, mapState} from 'vuex'
import ClearIcon from "@/components/icons/ClearIcon";
import CardQuestion from '../../cards/CardQuestion'

export default {
  name:'ModalQuestions',
  components: {
    ClearIcon,
    CardQuestion
  },
  data: () => ({
    modalId:MODAL_EDIT_QUESTIONNAIRE_ID,
    selectedAnswers: {},
    form: new Form({
      answers: {}
    }),
    size: 'xl',
    edit: false,
    contentClass: '',
    buttonText: 'Go to your matches',
    saveButton: {
      text: 'Please answer all questions',
      disabled: true
    },
  }),
  computed: {
    ...mapState({
      show: state => state.account.question.show,
      editable: state => state.account.question.editable,
      finished: state => state.account.question.finished
    }),
    ...mapGetters({
      answersAll: 'account/answers/answers',
      questionsAll: 'account/answers/questions'
    }),
  },
  watch: {
    answersAll(value) {
      value.forEach(item => {
        const questionId = item.id
        this.selectedAnswers[questionId] = {
          question_id: questionId,
          answer_id: item.answers[0].answer_id ?? null,
          user_answer: ''
        }
      })
    },
  },
  async created() {
    await this.fetchQuestions()
    await this.fetchAnswers()
    await this.updateSaveButton()
  },
  methods: {
    ...mapActions({
      fetchAnswers: 'account/answers/fetchAnswers',
      fetchQuestions: 'account/answers/fetchQuestions',
    }),
    handlerChangeAnswer() {
      this.$forceUpdate()
      this.updateSaveButton()
    },
    allQuestionsAnswered() {
      return Object.keys(this.selectedAnswers).length === this.questionsAll.length
    },
    async saveQuestions() {
      if (!this.allQuestionsAnswered()) {
        return
      }

      Object.entries(this.selectedAnswers).forEach(([key, el]) => {
        this.form.answers[el.question_id] = {
          question_id: el.question_id,
          answer_id: el.answer_id,
          user_answer: el.user_answer ? el.user_answer : ''
        }
      })

      try {
        await this.form.post('/api/v1/user/questions');
        this.size = '';
        this.contentClass = 'questions-content';
        this.buttonText = this.edit ? 'Ok' : 'Go to your matches';
        await this.$store.dispatch('account/question/setFinished', true)
        await this.$store.dispatch('account/question/setEdit', false)
        await this.sendQuestionsToHubSpot();
        this.$emit('update', !!this.answersAll.length);

      } catch (e) {
        console.log(e)
      }
    },
    async sendQuestionsToHubSpot() {
      await this.$axios.get('/api/v1/other/questions-form')
    },
    updateSaveButton() {
      const answersLength = Object.keys(this.selectedAnswers).length
      const questionsLength = this.questionsAll.length

      if (answersLength !== questionsLength) {
        this.saveButton = {
          text: `Please answer all questions ${answersLength}/${questionsLength}`,
          disabled: true,
        }
        return
      }

      if (answersLength === questionsLength)
        this.saveButton.text = `Save Changes`

      if (answersLength !== this.answersAll)
        this.saveButton.disabled = true

      const answersChanged = Object.keys(this.selectedAnswers).filter((answerId) => {
        const answer = this.selectedAnswers[answerId]
        const savedQuestion = this.answersAll.find(item => item.id === answer.question_id)

        if (!savedQuestion)
          return answer

        if (savedQuestion.answers[0].answer_id !== answer.answer_id)
          return answer
      })

      if (answersChanged.length)
        this.saveButton.disabled = false

    },
    selectAnswer(answer) {
      this.edit = !!this.answersAll.length
      this.selectedAnswers[answer.question_id] = answer
      if (this.allQuestionsAnswered()) {
        this.saveQuestionsBtnText = this.edit ? `Save Changes` : `Show results ${Object.keys(this.selectedAnswers).length}/${this.questionsAll.length}`;
      } else {
        this.saveQuestionsBtnText = `Please answer all questions ${Object.keys(this.selectedAnswers).length}/${this.questionsAll.length}`
      }
    },
    closeModal() {
      this.$bvModal.hide(MODAL_EDIT_QUESTIONNAIRE_ID);
      if (!this.edit)
        this.$router.push('/account/my-matches')

      setTimeout(() => {
        this.size = 'xl';
        this.contentClass = '';
      }, 500);
    },
  }
}
