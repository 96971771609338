//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MultiSelect from "vue-multiselect";
import styles from 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: "MultiSelectCustom",
  components: {
    MultiSelect,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    groupSelect: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => []
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    groupLabel: {
      type: String,
      default: ''
    },
    groupValues: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    trackBy: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  methods: {
    handlerInput(e) {
      this.$emit('change')
      this.$emit('input', e)
    },
  }
}
