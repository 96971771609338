//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mimeTypeMixin from '../mixins/mimeTypeMixin'
import FileIcon from '../icons/FileIcon'
import CloseIcon from '../icons/CloseIcon';
import moment from 'moment';

export default {
  components: {
    CloseIcon,
    FileIcon
  },
  props: {
    accept: {
      type: String,
      default: 'application/pdf'
    },
    deleteButtonText: {
      type: String,
      default: 'Clear'
    },
    preselected: {
      type: Object,
      default: () => {
        return { uploadedAt: '', filename: '' }
      }
    },
  },
  data () {
    return {
      file: '',
      filename: '',
      uploadedAt: ''
    }
  },
  mixins: [
    mimeTypeMixin
  ],
  watch: {
    preselected () {
      this.handlePreselected()
    }
  },
  mounted () {
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (event) {
        this.$refs.dragAndDropInput.addEventListener(event, function (e) {
          e.preventDefault();
          e.stopPropagation();
        }.bind(this), false);
      }.bind(this));

      this.$refs.dragAndDropInput.addEventListener('drop', function (e) {
        if (this.file === '') {
          this.handleFileUpload(e)
        }
      }.bind(this));

      this.handlePreselected()
  },
  methods: {
    async handleFileUpload (e) {
      let files = e.target.files || e.dataTransfer.files;

      if (files.length) {
        this.file = files[0]
        this.filename = this.trimLength(files[0].name)
      } else {
        this.deleteFile()
      }

      this.$emit('change', this.file)
    },
    deleteFile () {
      this.filename = ''
      this.file = ''
      this.uploadedAt = ''

      this.$emit('change', this.file)
    },
    trimLength (string, length = 65, end = '...') {
      if (string.length > length + 3) {
        return string.substring(0, length) + end
      }

      return string
    },
    handlePreselected () {
      if (this.preselected.uploadedAt && this.preselected.filename) {
        this.filename = this.trimLength(this.preselected.filename)
        this.uploadedAt = new moment(this.preselected.uploadedAt).format('LT, MMM D, YYYY')
      }
    },
    reset() {
      this.filename = ''
      this.file = ''
      this.uploadedAt = ''
    }
  }
}
