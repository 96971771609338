//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BButton } from 'bootstrap-vue'

export default {
  name: 'BaseButton',
  components: {
    BButton,
  },
  props: {
    text: {
      type: String,
      default: () => 'button'
    },
    noBordered: {
      type: Boolean,
      default: () => false
    },
    busy: {
      type: Boolean,
      default: () => false
    },
    clickable: {
      type: Boolean,
      default: () => false
    },
    variant: {
      type: String,
      default: () => 'button'
    }
  },
  methods: {
    click (e) {
      this.$emit('click', e)
    }
  }
}
