//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep';
import {WORK_BOOK, WORK_BOOK_TOP_LIST_KEYS} from "@/store/constants";
import {mapActions, mapGetters} from "vuex";
import ArrowCodeIcon from '~/assets/icons/arrows/ArrowCodeIcon.svg?inline'

export default {
  name: "WorkBookProsAndCons",
  components: {
    ArrowCodeIcon,
  },
  props: {
    numberTop: {
      type: Number,
      default: 0
    },
    blockIndex: {
      type: Number,
      default: 0
    },
  },
  data: () => ({
    currentTabIndex: 0,
  }),
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
      topList: 'account/workbook/topList',
      franchise: 'account/workbook/franchise',
    }),
    answersBlock() {
      return this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers
    },
    questionsBlock() {
      return WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex].questionsBlocks[this.blockIndex]
    },
    getTabs() {
      const tabs = []

      for (let i = 0; i < this.numberTop; i += 1) {
        tabs.push(
          {
            name: this.getFranchiseName(i),
            description: '',
            subDescription: '',
            isTotal: false,
          }
        )
      }

      tabs.push(
        {
          name: 'Summary',
          description: 'Pick your top pros for each franchise and your top three cons.',
          subDescription: 'Identify any cons that are concerning to you and eliminate one franchisor from your list.',
          isTotal: true,
        }
      )

      return tabs
    },
  },
  methods: {
    ...mapActions({
      changeAnswer: 'account/workbook/changeAnswer',
    }),
    getQuestionTitle(questionIndex) {
      return this.questionsBlock.questions[questionIndex].text
    },
    getTotalValue(questionIndex, franchiseIndex) {
      return this.answersBlock[questionIndex].value[franchiseIndex].filter(answer => answer !== '').length
    },
    getFranchiseName(franchiseIndex) {
      let listIndex = WORK_BOOK_TOP_LIST_KEYS.TEN

      if (this.numberTop === 5)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.FIVE

      if (this.numberTop === 3)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.THREE

      if (this.numberTop === 2)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.TWO

      if (typeof this.topList[listIndex] === "undefined")
        return ''

      const currentFranchise = this.franchise.find(item => Number(item.id) === Number(this.topList[listIndex][franchiseIndex]))

      if (typeof currentFranchise === "undefined")
        return `Franchise #${franchiseIndex + 1}`

      return currentFranchise.franchise
    },
    getValue(questionId, tabIndex, answerIndex, isSummary = false) {
      const savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)

      if (isSummary)
        return savedValue.value[tabIndex].summary

      return savedValue.value[tabIndex].answers[answerIndex]
    },
    handlerUpdateValue(questionId, tabIndex, answerIndex, value, isSummary = false) {
      const savedValue = cloneDeep(this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId))
      const questionIndex = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.findIndex(item => item.id === questionId)

      if (isSummary)
        savedValue.value[tabIndex].summary = value

      if (!isSummary)
        savedValue.value[tabIndex].answers[answerIndex] = value

      this.changeAnswer({blockIndex: this.blockIndex, questionIndex, value: savedValue.value})
    },
  },

}
