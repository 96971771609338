//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FranchisorWelcomeBanner from "@/components/banners/FranchisorWelcomeBanner"
// eslint-disable-next-line import/no-extraneous-dependencies
import {mapGetters} from 'vuex'
// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'

import ModalText from "@/components/modals/ModalText"
import PdfBook from "@/components/information/PdfBook"
import Confetti from "@/components/Confetti"
import SearchFranchisesMobBtn from "~/components/SearchFranchisesMobBtn"
import forSellers from "~/assets/images/homepage/for_sellers.svg?inline"
import forBuyers from "~/assets/images/homepage/for_buyers.svg?inline"
import franchiseeBookIcon from "~/assets/images/homepage/franchisee-book.svg?inline"
import MobileAppStoreButton from "~/components/buttons/mobileAppStoreButton"
import storeMixin from "~/components/mixins/storeMixin"
import PromoVideo from "~/components/information/PromoVideo"

import {
  REGISTRATION_STAGE_FORM,
  USER_TYPE_FRANCHISOR,
  USER_TYPE_FRANCHISEE,
  SEARCH_TYPE_FRANCHISOR,
  SEARCH_TYPE_FRANCHISEE,
// eslint-disable-next-line import/extensions,import/no-unresolved
} from "~/store/constants"

Vue.use(Vue2TouchEvents)

export default {
  name: "PromoHome",
  components: {
    Confetti,
    PdfBook,
    FranchisorWelcomeBanner,
    PromoVideo,
    ModalText,
    MobileAppStoreButton,
    forSellers,
    forBuyers,
    SearchFranchisesMobBtn,
    franchiseeBookIcon,
  },
  mixins: [
    storeMixin
  ],
  props: {
    activeBlockId: {
      default: null,
    },
    tagLineFranchisee: {
      type: String,
      default: '',
    },
    tagLineFranchisor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      topFranchises: [
        {
          name: 'Fitness',
          slug: 'fitness',
        },
        {
          name: 'Pet',
          slug: 'pet',
        },
        {
          name: 'Home Services',
          slug: 'home-services',
        },
        {
          name: 'Restaurant',
          slug: 'restaurant',
        },
        {
          name: 'Health & Beauty',
          slug: 'health-beauty',
        },
        {
          name: 'Cleaning',
          slug: 'cleaning',
        },
      ],
      blocks: [
        {
          id: 1,
          type: 1,
          userType: USER_TYPE_FRANCHISEE,
          className: 'right',
          isActive: true,
          isAnimated: false,
          title: '',
          subTitle: 'Franchise<span>123</span>',
          switchComponent: 'forSellers',
          text: 'The Franchise MLS — Connecting Franchise Buyers and Sellers Globally',
          descrTitle: 'Buy a Franchise',
          buttonTitle: 'Begin Today', // not auth - open modal sign up, is role franchisor — open modal (You are signed in as Franchisor. in browse page), is role franchise — lint to My Acc (tab dashboard "My matches")
          steps: [
            'Identify',
            'Research',
            'Decide',
          ],
        },
        {
          id: 0,
          type: 0,
          userType: USER_TYPE_FRANCHISOR,
          className: 'left',
          isActive: false,
          isAnimated: false,
          title: 'Sell',
          subTitle: 'franchises',
          switchComponent: 'forBuyers',
          descrTitle: '',
          text: '',
          buttonTitle: 'Grow my franchise', // not auth - open modal sign up, is role franchisor — link to My Acc (tab dashboard main "Overview"), is role franchise — open modal (text not ready) — marGetters
          steps: [
            'Claim <br>your brand',
            'Customize <br>your brand',
            'Grow <br>your brand',
          ],
        },
      ],
      modal: {
        isFranchisor: {
          message: 'You are signed in as a Franchisor. If you are interested in buying this franchise please register a new account as a Franchisee. Please use another email to sign up as a Franchisee.',
          isShow: false,
        },
        isFranchisee: {
          message: 'You are signed in as a Franchisee. If you own a brand and wish to grow it, please Sign up as a Franchisor. Sign in if you already have a Franchisor account.',
          isShow: false,
        }
      },
      containerIsActive: true,
      currentActiveBlock: 0,
      disableSwipe: false,
      freezeBlock: false,
      verticalScroll: 0,
      swipe: {
        start: {
          active: false,
          coordinateX: 0,
          coordinateY: 0,
        },
        end: {
          active: false,
          coordinateX: 0,
        },
        current: {
          coordinateX: 0,
          coordinateY: 0,
          direction: '',
        },
        container: {
          shift: 0,
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      check: 'auth/check',
    }),
  },
  created() {
    if (this.activeBlockId !== null) {
      this.blocks.forEach((block) => {
        if (block.id === this.activeBlockId) {
          this.changeActiveBlock(block)
        }
      })
    }
  },
  methods: {
    getDescrText(userType) {
      if (userType === USER_TYPE_FRANCHISEE) {
        return this.tagLineFranchisee
      }
      if (userType === USER_TYPE_FRANCHISOR) {
        return this.tagLineFranchisor
      }
      return ''
    },
    openTop(slug) {
      this.$router.push(`/top-franchises/${slug}`)
    },
    showRegisterModal(blockId) {
      if (blockId === 1) {
        this.showFranchiseeBeginTodayRegistration()
      } else {
        this.$store.dispatch('registration/openModal', {
          stage: REGISTRATION_STAGE_FORM,
          userType: USER_TYPE_FRANCHISOR
        })
      }
    },
    containerClasses() {
      const arrClasses = [
        'ph__container'
      ]
      if (this.blocks[0].isActive)
        arrClasses.push('ph__container--left')

      if (this.blocks[1].isActive)
        arrClasses.push('ph__container--right')

      if (this.freezeBlock)
        arrClasses.push('freeze')

      if (this.containerIsActive)
        arrClasses.push('active')
      return arrClasses

    },
    blockClasses(block) {
      const arrClasses = [
        'ph__block'
      ]
      arrClasses.push(`ph__block--${block.className}`)
      if (block.isActive)
        arrClasses.push('active')
      if (block.isAnimated)
        arrClasses.push('animated')
      return arrClasses
    },
    changeActiveBlock(currentBlock) {
      // eslint-disable-next-line no-param-reassign,no-return-assign
      this.blocks.forEach(block => block.isActive = false)

      // eslint-disable-next-line no-param-reassign
      currentBlock.isActive = true
      this.containerIsActive = true
      this.setTimeoutAnimation(currentBlock)
    },
    clickBlockHandler(number, type) {
      const currentBlock = this.blocks[number]
      this.swipe.current.direction = (number === 0) ? 'right' : 'left'

      if (number === 1) {
        this.$store.dispatch('search/setType', SEARCH_TYPE_FRANCHISOR)
      } else {
        this.$store.dispatch('search/setType', SEARCH_TYPE_FRANCHISEE)
      }

      if (!this.containerIsActive
        && type === 'toggle'
        && window.innerWidth <= 1100) {
        return
      }
      if (this.containerIsActive
        && !currentBlock.isActive
        && type === 'block'
        && window.innerWidth <= 1100) {
        return
      }

      this.changeActiveBlock(currentBlock)
    },
    clickButtonHandler(block) {
      const user = this.user
      if (user === null) {
        this.showRegisterModal(block.id)
        return
      }
      if (block.className === 'left' && user.isFranchisor) {
        this.$router.push('/account-franchisor')
        return
      }
      if (block.className === 'right' && user.isFranchisor) {
        this.modal.isFranchisor.isShow = true
        return
      }
      if (block.className === 'left' && user.isFranchisee) {
        this.modal.isFranchisee.isShow = true
        return
      }
      if (block.className === 'right' && user.isFranchisee) {
        this.$router.push('/account/my-matches')
      }
    },
    setTimeoutAnimation(block) {
      // eslint-disable-next-line no-param-reassign
      block.isAnimated = true
      setTimeout(() => {
        // eslint-disable-next-line no-return-assign,no-param-reassign,no-shadow
        this.blocks.forEach(block => block.isAnimated = false)
      }, 800)
    },
    showLoginModal() {
      this.$store.dispatch('login/openModal')
    },
    movingHandler(e) {
      if (window.innerWidth > 550)
        return
      const {start, current, container} = this.swipe

      if (!start.active)
        return
      current.coordinateX = e.changedTouches[0].clientX
      current.coordinateY = e.changedTouches[0].clientY

      if (Math.abs(current.coordinateX - start.coordinateX) > this.swipe.maxShift)
        return

      current.direction = (start.coordinateX > current.coordinateX) ? 'left' : 'right'

      if ((this.blocks[0].isActive && current.direction === 'right')
        || (this.blocks[1].isActive && current.direction === 'left')) {
        this.disableSwipe = true
        return
      }
      container.shift = current.coordinateX - start.coordinateX
    },
    containerStyles() {
      const {container} = this.swipe

      return {
        transform: `translateX(${parseFloat(container.shift).toFixed(0)}px)`
      }
    }
  }
}
