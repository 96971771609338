//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";
import { SEARCH_TYPE_FRANCHISOR, SEARCH_TYPE_FRANCHISEE } from "~/store/constants";
import ModalSearchFranchises from "~/components/modals/ModalSearchFranchises";
import BaseButton from "@/components/buttons/BaseButton"

export default {
  name: 'SearchFranchisesMobBtn',
  components: {
    BaseButton,
    ModalSearchFranchises,
    SEARCH_TYPE_FRANCHISOR,
    SEARCH_TYPE_FRANCHISEE,
  },
  props: {
    Navbar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeFranchisee: SEARCH_TYPE_FRANCHISEE,
      typeFranchisor: SEARCH_TYPE_FRANCHISOR,
      type: SEARCH_TYPE_FRANCHISEE,
      showModal: false,
    }
  },
  computed: {
    ...mapGetters({
      search: "search/type",
    }),
    ...mapState({
      searchType: state => state.search.type,
    }),
  },
  watch: {
    searchType (newVal) {
      this.type = newVal;
    },
  },
  methods: {
    showFilter() {
      if (process.client) {
        window.scrollTo(0,0);
      }
      this.showModal = true;
      this.$store.dispatch('nav/setNavOverlap', true);
    },
    hideFilter() {
      this.showModal = false;
      this.$store.dispatch('nav/setNavOverlap', false);
    },
  },
}
