//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    /**
     * Format data for usability
     * @param data
     * @param key
     * @returns {*}
     */
    formatData(data, key) {
      let formatted;
      if (key === 'month') {
      formatted =  Number(data[key]) === 0 ? 'Total' : `${data[key]} month`;
      } else if (key === 'percent') {
        formatted = Number(data[key]) === 0 || isNaN(data[key]) ? '-': `${this.formatThousands(data[key])}%`;
      } else {
        formatted = Number(data[key]) === 0 ? '-': this.formatThousands(data[key]);
      }
      return formatted;
    },
    /**
     *
     * @param number
     * @returns {string}
     */
    formatThousands(number) {
      if(number){
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  }
}
