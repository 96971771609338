import { render, staticRenderFns } from "./DashboardFranchiseeMatches.vue?vue&type=template&id=b514db4e&scoped=true&"
import script from "./DashboardFranchiseeMatches.vue?vue&type=script&lang=js&"
export * from "./DashboardFranchiseeMatches.vue?vue&type=script&lang=js&"
import style0 from "./DashboardFranchiseeMatches.vue?vue&type=style&index=0&id=b514db4e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b514db4e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ViewSwitcherNew: require('/root/repo/components/ViewSwitcherNew.vue').default})
