//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Magnifier from '~/assets/images/svg/loupe.svg?inline'
import Close from '~/assets/images/svg/close-with-wrap.svg?inline'

export default {
  name: 'BlogPostsSiderbarSearch',
  components: {
    Magnifier,
    Close,
  },
  data() {
    return {
      blogUrl: '/blog/',
      keywords: '',
    }
  },
  created() {
    this.keywords = this.$route.params.search
  },
  methods: {
    async search() {
      if (this.keywords) {
        window.scroll(0,0)
        await this.$store.commit("blog/SET_STATUS_FETCH_DATA", true)
        await this.$router.push(`${this.blogUrl}?search=${this.keywords}`)
        await this.changeData()
        await this.$store.commit("blog/SET_STATUS_FETCH_DATA", false)
      } else {
        window.location.href = '/blog/'
      }
    },
    async reset() {
      this.keywords = ''
      await this.$router.push(`${this.blogUrl}`)
      await this.changeData()
    },
    async changeData() {
      await this.$store.commit("blog/BLOG_SET_PAGINATION", { page: 1})
      await this.$store.commit("blog/BLOG_SET_SEARCH", this.keywords)
      await this.$store.dispatch('blog/fetchArticlesList')
    }
  }
}
