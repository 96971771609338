//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {WORK_BOOK, WORK_BOOK_QUESTION_TYPES} from "@/store/constants";
import {mapGetters} from "vuex";

export default {
  name: "WorkBookSectionScore",
  data: () => ({
    workBookObj: WORK_BOOK,
    questionTypes:WORK_BOOK_QUESTION_TYPES,
    sectionScores:[],
    sectionTotalScore:0,
  }),
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
    }),
    currentSection() {
      return WORK_BOOK[this.currentSectionIndex]
    },
  },
  created() {
    this.calculateSectionScore()
  },
  methods:{
    showChapter(chapterIndex){
      const chapter = this.currentSection.chapters[chapterIndex]
      return chapter.questionsBlocks.find(item => item.calculateTotal === true)
    },
    chapterNumber(chapterIndex) {
      return `${this.currentSectionIndex + 1}.${chapterIndex + 1}`
    },
    calculateSectionScore(){
      this.workBooks[this.currentWorkBookIndex].answers[this.currentSectionIndex].chapters.forEach((chapter,chapterIndex) => {
        let scores = 0

        if(this.showChapter(chapterIndex)){
          chapter.questionsBlocks.forEach((block) => {
            block.answers.forEach((answer) => {
              scores += Number(answer.value)
            })
          })
        }

        this.sectionScores.push(scores)
      })

      this.sectionScores.forEach(count => {
        this.sectionTotalScore += count
      })
    },
  }
}
