//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters,
} from "vuex"
import Form from "vform"
import VSelect from "@/components/form/VSelect"
import VInputV2 from "@/components/form/VInputV2"
import VTextAreaV2 from "@/components/form/VTextAreaV2"
import {MASK_PHONE} from "@/store/constants"

export default {
  name: "NewLeadForm",
  components: {
    VSelect,
    VInputV2,
    VTextAreaV2,
  },
  data: () => ({
    MASK_PHONE,
    form: new Form({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      step: '',
      silent: '',
      note: '',
    }),
    errorMessage: '',
    silentOptions: [
      {
        title: 'Yes',
        value: 'true',
      },
      {
        title: 'No',
        value: 'false',
      }
    ]

  }),
  computed: {
    ...mapGetters({
      businessSteps: 'business-steps/businessStepsListWithoutCounter',
      franchiseId: 'account-franchisor/franchiseId',
    }),
  },
  mounted() {
    this.setDefaultFormValues()
  },
  methods: {
    ...mapActions({
      addNewLead: 'manage-lead/addNewLead',
    }),
    maskForPastedValue() {
      this.form.phone = this.form.phone.replace(/\D/g, '')
    },
    setDefaultFormValues() {
      this.form.step = this.businessSteps[0]?.value ?? ''
      this.form.silent = this.silentOptions[0]?.value ?? ''
    },
    async submit() {
      await this.addNewLead({
        leadForm: this.form,
        franchiseId: this.franchiseId,
      })

      if (Object.keys(this.form?.errors?.errors).length === 0) {
        this.$emit('update')
        this.$emit('close')
      }
    },
  }
}
