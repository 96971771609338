//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    textColor: {
      type: String,
      default: '#FFFFFF',
    },
    to: {
      type: String,
      default: '/',
    },
    bottomLine: {
      type: Boolean,
      default: false
    }
  },
};
