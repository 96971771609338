//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {WORK_BOOK, WORK_BOOK_QUESTION_TYPES} from "@/store/constants";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "WorkBookQuestionsClosedWithSelect",
  props: {
    blockIndex: {
      type: Number,
      default: 0
    },
  },
  data: () => ({
    answerTypes: WORK_BOOK_QUESTION_TYPES
  }),
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
    }),
    questionsBlockName() {
      return this.questionsBlock.name ?? `Questions`
    },
    questionsBlock() {
      return WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex].questionsBlocks[this.blockIndex]
    },
    scoreQuestionBlock() {
      if (!this.questionsBlock.calculateTotal)
        return false

      let count = 0
      this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.forEach(answer => {
        count += Number(answer.value)
      })
      return count
    }
  },
  methods: {
    ...mapActions({
      changeAnswer: 'account/workbook/changeAnswer',
    }),
    changeValue(questionIndex, value) {
      this.changeAnswer({blockIndex: this.blockIndex, questionIndex, value})
    },
    getValue(questionId) {
      const savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)
      return savedValue ? savedValue.value : null
    },
  }
}
