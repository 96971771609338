//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex"
import BaseModal from "~/components/modals/BaseModal"
import BaseButton from "~/components/buttons/BaseButton"

export default {
  components: {
    BaseButton,
    BaseModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    plan: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
    }),
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
  }
};
