//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from "vform"
import VButton from "@/components/VButton"
import {mapActions, mapGetters} from "vuex"
import {
  MASK_PHONE,
  MODAL_SIGN_UP_FRANCHISE_ID,
  SIGN_UP_FRANCHISE_FAST_STEP_THREE,
  USER_TYPE_FRANCHISEE,
} from "@/store/constants"
import VInputDefault from "@/components/form/defaultField/VInputDefault"
import LogoWithTradeMark from "~/components/LogoWithTradeMark"

export default {
  name: "SignUpFranchiseStepTwo",
  components: {
    VInputDefault,
    LogoWithTradeMark,
    VButton,
  },
  data: () => ({
    MASK_PHONE,
    form: new Form({
      first_name: '',
      last_name: '',
      rawPhone: '',
      phone: '',
      password: 'franchise123',
      password_confirmation: 'franchise123',
      busy: false,
      user_type: USER_TYPE_FRANCHISEE,
      onKeydown: () => {
        this.updateFranchisee()
      },
    })
  }),
  computed: {
    ...mapGetters({
      selected_franchise: 'account/selectedFranchise',
      show_fdd: 'account/showFdd',
      enableFlowBeginToday: 'registration/enableFlowBeginToday',
    }),
    titleButtonIndustry() {
      const title = 'Industry'

      if (this.isMobScreen) {
        return title
      }

      if (!this.industry.list.length)
        return title

      const industryArr = this.industry.list.map(item => `<span>${item.title}</span>`)

      return industryArr.join(', ')
    },
    titleButtonStates() {
      const title = 'States'

      if (this.isMobScreen) {
        return title
      }

      if (!this.states.list.length)
        return title

      const statesArr = this.states.list.map(item => `<span>${item.name}</span>`)

      return statesArr.join(', ')
    },
    titleButtonInvest() {
      const title = 'Investment'

      if (this.isMobScreen) {
        return title
      }

      if (!Object.keys(this.invest.checked).length)
        return title

      return `<span>${this.invest.checked.title}</span>`
    },
    titleButtonSend() {
      return this.checkEmptyFilter() ? 'Narrow Your Search' : 'Next'
    },
  },
  mounted() {
    this.checkCompiledAllSteps()
  },
  methods: {
    ...mapActions({
      savePreferences: 'account/savePreferences',
    }),
    async checkCompiledAllSteps() {
      if (this.selected_franchise) {
        if (!this.show_fdd) {
          await this.$store.commit('registration/SET_COMPANY_PAGE_URL', this.selected_franchise.itemUrl)
        }
      } else {
        await this.$store.commit('registration/SET_COMPANY_PAGE_URL', '/account/my-matches')
      }
    },
    async updateFranchisee() {
      this.form.phone = this.form.rawPhone.replace(/[^0-9\.]+/g, '')
      try {
        const {data} = await this.form.put('/api/v1/user')
        if (!data)
          return

        await this.$store.dispatch('auth/fetchUser')

        if (this.enableFlowBeginToday) {
          await this.savePreferences()
          this.$bvModal.hide(MODAL_SIGN_UP_FRANCHISE_ID)
          await this.$router.push('/account/my-matches')
          return
        }

        await this.$store.commit('registration/SET_REGISTRATION_FRANCHISEE_STAGE', SIGN_UP_FRANCHISE_FAST_STEP_THREE)
      } catch (e) {
      }
    }
  }
}
