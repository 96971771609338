//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FilterPreferences from "@/components/account/FilterPreferences";
import DirectoryCard from "@/components/cards/DirectoryCard";
import VBTableNew from "@/components/tables/VBTableNew";
import ViewSwitcherNew from "@/components/ViewSwitcherNew";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DashboardFranchiseeMatches",
  components: {VBTableNew, DirectoryCard, FilterPreferences, ViewSwitcherNew},
  props: {
    isBlocked: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    enableCancel: false,
    filters: {},
    disableTableView: false,
    counter: 0,
    tableFields: [
      {
        key: 'business_image',
        label: '',
        sortable: false,
        thClass: 'account_table__th disable_sort',
        tdClass: 'account_table__td',
      },
      {
        key: 'business_name',
        label: 'Franchise Name',
        sortable: true,
        thClass: 'account_table__th',
        tdClass: 'account_table__td',
      },
      {
        key: 'like',
        label: 'Like',
        sortable: false,
        thClass: 'account_table__th disable_sort',
        tdClass: 'account_table__td',
      },
      {
        key: 'investment',
        label: 'Investment',
        sortable: true,
        thClass: 'account_table__th',
        tdClass: 'account_table__td',
      },
      {
        key: 'average_revenue',
        label: 'Average revenue',
        sortable: true,
        thClass: 'account_table__th',
        tdClass: 'account_table__td',
      },
      {
        key: 'franchise_fee',
        label: 'Franchise fee',
        sortable: true,
        thClass: 'account_table__th',
        tdClass: 'account_table__td',
      },
      {
        key: 'number_of_locations',
        label: 'Locations',
        sortable: true,
        thClass: 'account_table__th',
        tdClass: 'account_table__td',
      },
      {
        key: 'compare',
        label: '',
        sortable: false,
        thClass: 'account_table__th disable_sort',
        tdClass: 'account_table__td',
      },
      {
        key: 'fdd',
        label: 'FDD',
        sortable: false,
        thClass: 'account_table__th disable_sort',
        tdClass: 'account_table__td',
      },
      {
        key: 'contact',
        label: '',
        sortable: false,
        isRowHeader: true,
        thClass: 'account_table__th disable_sort',
        tdClass: 'account_table__td',
      }
    ],
  }),
  head() {
    return {
      title: 'My Matches',
    }
  },
  computed: {
    ...mapGetters({
      pagination: 'account/pagination',
      listFranchise: 'account/listFranchise',
      busyFetchListFranchise: 'account/busyFetchListFranchise',
      itemsState: 'account/business/itemsState',
    }),
  },
  watch: {
    busyFetchListFranchise(newVal) {
      if (!newVal)
        this.setShowPreloader(false)
    },
  },
  async mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    ...mapActions({
      setShowPreloader: 'account/setShowPreloader',
      updateCurrentPage: 'account/updateCurrentPage',
      fetchFranchises: 'account/fetchFranchises',
    }),
    async handleChangePage(pageNumber) {
      const path = this.$route.path
      await this.updateCurrentPage(pageNumber)

      if (!this.isBlocked)
        await this.$router.push(`${path}?page=${pageNumber}`)

      await this.fetchFranchises()
    },
    onResize() {
      const windowWidth = window.innerWidth;
      if (windowWidth < 600) {
        this.disableTableView = true
        this.$store.commit('account/business/SET_ITEMS_STATE', true)
      } else {
        this.disableTableView = false
      }
    }
    ,
  }
}
