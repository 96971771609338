//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "BlogPostImage",
  props: {
    imageUrl: {
      type: String,
      default: '',
    },
    videoUrl: {
      type: String,
      default: '',
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    useLazy: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    showImage() {
      return !(this.isDetail && this.videoUrl !== null && this.videoUrl !== '' && this.videoUrl.length > 10)
    },
    getImageUrl() {
      const defaultUrl = '/img/blog/no-image.svg'
      if (this.imageUrl !== '' && this.imageUrl !== null)
        return this.imageUrl

      if (this.videoUrl !== '' && this.videoUrl !== null && this.videoUrl.length > 10) {
        const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
        const match = this.videoUrl.match(regExp)
        return match && match[2].length === 11 ? `https://img.youtube.com/vi/${match[2]}/mqdefault.jpg` : defaultUrl
      }

      return defaultUrl
    },
  },
}
