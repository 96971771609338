//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'vform'
import VInput from '~/components/form/VInput'
import VButton from '~/components/VButton'
import VCheckBox from '~/components/form/VCheckBox'
import VTextArea from '~/components/form/VTextArea'
import VMaskedInput from '~/components/form/VMaskedInput'
import VSelect from '~/components/form/VSelect'
import { mapGetters } from 'vuex'

export default {
  components: {
    VSelect,
    VTextArea,
    VCheckBox,
    VButton,
    VInput,
    VMaskedInput
  },
  props: {
    formClass: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    educationTrackOptions: [{ key: 'Franchisee', title: 'Franchisee' }, { key: 'Franchisor', title: 'Franchisor' }],
    status: '',
    message: '',
    form: new Form({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      education_track: '',
      message: '',
      agree: false,
      busy: false,
      hubspotutk: ''
    })
  }),
  computed: mapGetters({
    user: 'auth/user',
    authenticated: 'auth/check'
  }),
  watch: {
    show (val) {
      if (val === true && this.user != null) {
        this.form.first_name = this.user.first_name
        this.form.last_name = this.user.last_name
        this.form.email = this.user.email
        this.form.phone = this.user.phone
        this.form.education_track = this.educationTrackOptions[0].key
        this.form.message = 'auth user'
        this.form.agree = true
        this.submit()
      }
    }
  },
  mounted() {
    this.form.hubspotutk = this.$cookies.get('hubspotutk')
  },
  methods: {
    showForm () {
      return this.user == null
    },
    async submit () {
      try {
        const { data } = await this.form.post('/api/v1/other/contact-form-pre-register')

        this.setAlertFields(data.message, data.status)
        this.form.reset()
        this.$refs.contactForm.reset()
      } catch (e) {
        if (e.response.status === 400 && e.response.data.status === 'fail') {
          this.setAlertFields(e.response.data.message, e.response.data.status)
        } else if (e.response.status !== 422) {
          this.$emit('submitted')
        }
      }
    },
    setAlertFields (message = '', status = '') {
      this.message = message
      this.status = status
    }
  }
}
