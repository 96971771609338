//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  WORK_BOOK,
  WORK_BOOK_COUNTABLE_CHAPTER,
  WORK_BOOK_QUESTION_TYPES,
  WORK_BOOK_TOP_LIST_KEYS
} from "@/store/constants";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "WorkBookSelectTop",
  props: {
    countTop: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    ...mapGetters({
      topList: 'account/workbook/topList',
      workBooks: 'account/workbook/workBooks',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      franchise: 'account/workbook/franchise',
    }),
    listIndex() {
      if (this.countTop === 5)
        return WORK_BOOK_TOP_LIST_KEYS.FIVE

      if (this.countTop === 3)
        return WORK_BOOK_TOP_LIST_KEYS.THREE

      if (this.countTop === 2)
        return WORK_BOOK_TOP_LIST_KEYS.TWO

      if (this.countTop === 1)
        return WORK_BOOK_TOP_LIST_KEYS.ONE

      return false
    },
    listFranchiseId() {
      const returnArr = []

      if (this.countTop === 5)
        return this.topList[WORK_BOOK_TOP_LIST_KEYS.TEN]

      if (this.countTop === 3)
        return this.topList[WORK_BOOK_TOP_LIST_KEYS.FIVE]

      if (this.countTop === 2)
        return this.topList[WORK_BOOK_TOP_LIST_KEYS.THREE]

      if (this.countTop === 1)
        return this.topList[WORK_BOOK_TOP_LIST_KEYS.TWO]

      return returnArr
    },
  },
  methods: {
    ...mapActions({
      updateTopList: 'account/workbook/updateTopList',
    }),
    getFranchiseName(franchiseId){
      const franchise = this.franchise.find(item => Number(item.id) === Number(franchiseId))

      if (typeof franchise === "undefined")
        return false

      return franchise.franchise
    },
    checkSelected(franchiseId, index){
      if (this.countTop === 5)
        return this.topList[WORK_BOOK_TOP_LIST_KEYS.FIVE].findIndex(item => Number(item) === Number(franchiseId)) === index

      if (this.countTop === 3)
        return this.topList[WORK_BOOK_TOP_LIST_KEYS.THREE].findIndex(item => item === franchiseId) === index

      if (this.countTop === 2)
        return this.topList[WORK_BOOK_TOP_LIST_KEYS.TWO].findIndex(item => item === franchiseId) === index

      if (this.countTop === 1)
        return this.topList[WORK_BOOK_TOP_LIST_KEYS.ONE].findIndex(item => item === franchiseId) === index

      return false
    },
    checkDisabled(franchiseId) {
      if (this.countTop === 5)
        return this.topList[WORK_BOOK_TOP_LIST_KEYS.FIVE].findIndex(item => Number(item) === Number(franchiseId)) !== -1

      if (this.countTop === 3)
        return this.topList[WORK_BOOK_TOP_LIST_KEYS.THREE].findIndex(item => item === franchiseId) !== -1

      if (this.countTop === 2)
        return this.topList[WORK_BOOK_TOP_LIST_KEYS.TWO].findIndex(item => item === franchiseId) !== -1

      if (this.countTop === 1)
        return this.topList[WORK_BOOK_TOP_LIST_KEYS.ONE].findIndex(item => item === franchiseId) !== -1

      return false
    },
    handlerUpdate(indexFranchise,franchiseId) {
      this.updateTopList({
        listIndex: this.listIndex,
        indexFranchise,
        franchiseId
      })
      this.$forceUpdate()
    },
    findBlockAnswers(sectionId, chapterId, blockId) {
      const findSection = this.workBooks[this.currentWorkBookIndex].answers.find(section => section.id === sectionId)
      const findChapter = findSection.chapters.find(chapter => chapter.id === chapterId)
      return findChapter.questionsBlocks.find(block => block.id === blockId)
    },
    findBlockQuestions(sectionId, chapterId, blockId) {
      const findSection = WORK_BOOK.find(section => section.id === sectionId)
      const findChapter = findSection.chapters.find(chapter => chapter.id === chapterId)
      return findChapter.questionsBlocks.find(block => block.id === blockId)
    },
    getFranchiseIndexInTopList(franchiseId){
      const index = this.topList[WORK_BOOK_TOP_LIST_KEYS.TEN].findIndex(item => item === franchiseId)

      if (this.countTop === 3 || this.countTop === 2)
        return this.topList[WORK_BOOK_TOP_LIST_KEYS.FIVE].findIndex(item => item === franchiseId)

      if (this.countTop === 1)
        return this.topList[WORK_BOOK_TOP_LIST_KEYS.TWO].findIndex(item => item === franchiseId)

      return index
    },
    calculateFranchiseTotal(franchiseId) {
      let counter = 0
      let countableChapters = []
      const franchiseIndex = this.getFranchiseIndexInTopList(franchiseId)

      if (this.countTop === 5)
        countableChapters = WORK_BOOK_COUNTABLE_CHAPTER[WORK_BOOK_TOP_LIST_KEYS.TEN]

      if (this.countTop === 3 || this.countTop === 2)
        countableChapters = WORK_BOOK_COUNTABLE_CHAPTER[WORK_BOOK_TOP_LIST_KEYS.FIVE]

      if (this.countTop === 1)
        countableChapters = WORK_BOOK_COUNTABLE_CHAPTER[WORK_BOOK_TOP_LIST_KEYS.ONE]


      if (countableChapters.length === 0)
        return counter

      countableChapters.forEach(item => {
        const blockAnswers = this.findBlockAnswers(item.sectionId, item.chapterId, item.blockId)

        if (blockAnswers.type === WORK_BOOK_QUESTION_TYPES.CLOSED_QUESTION_WITH_SCORE_TOP_TEN) {
          blockAnswers.answers.forEach(answer => {
            counter += Number(answer.value[franchiseIndex] ?? 0)
          })
        }

        if (blockAnswers.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TEN_ENTRY) {
          const questionsBlock = this.findBlockQuestions(item.sectionId, item.chapterId, item.blockId)

          const fieldWithScore = questionsBlock.fields.find((field) => {
            if (typeof field.isScore !== "undefined")
              return field.slug
            return false
          })

          counter += Number(blockAnswers.answers[franchiseIndex].value[fieldWithScore.slug] ?? 0)
        }

        if (blockAnswers.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ADDITIONAL) {
          const questionsBlock = this.findBlockQuestions(item.sectionId, item.chapterId, item.blockId)

          const fieldWithScore = questionsBlock.fields.find((field) => {
            if (typeof field.isScore !== "undefined")
              return field.slug
            return false
          })

          blockAnswers.answers.forEach(answer => {
            counter += Number(answer.value[franchiseIndex][fieldWithScore.slug] ?? 0)
          })
        }

        if (blockAnswers.type === WORK_BOOK_QUESTION_TYPES.TOP_TWO_ENTRY_CUSTOM) {
          blockAnswers.answers.forEach(answer => {
            const field = franchiseIndex === 0 ? 'franchise1': "franchise2"
            counter += Number(answer.value[0][field] ?? 0)
          })
        }

      })

      return counter
    },
  }
}
