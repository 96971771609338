//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseButton from "@/components/buttons/BaseButton"

export default {
  components: {
    BaseButton,
  },
  props: {
    text: {
      type: String,
      default: ``,
    },
    show: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: `Yes`,
    },
    cancelText: {
      type: String,
      default: `No`,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
