//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BlogPostImage from "@/components/blog/BlogPostImage"

export default {
  name: 'BlogPostsSiderbarLast',
  components: {
    BlogPostImage,
  },
  props: {
    posts: {
      type: Array,
      default: null,
    }
  },
  methods: {
    async routeHandler(externalLink) {
      if (externalLink) {
        window.open(externalLink, '_blank')
        return
      }
      await this.$store.commit("blog/SET_STATUS_FETCH_DATA", true)
    },
    getPostEvent(externalLink) {
      return externalLink ? '' : 'click'
    },
  },
}
