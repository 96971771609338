//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CardUserAccount from "@/components/cards/CardUserAccount";
import {MODAL_EDIT_QUESTIONNAIRE_ID} from "@/store/constants";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DashboardFranchiseeProfile",
  components: {CardUserAccount},
  props:{
    isBlocked:{
      type:Boolean,
      default: false,
    }
  },
  data() {
    return {
      modalId: MODAL_EDIT_QUESTIONNAIRE_ID,
      checkData: true,
      answered: 0,
      fields: [
        {
          key: 'questions',
          label: 'Questions',
          thClass: 'table__head',
          tdClass: 'table__body'
        },
        {
          key: 'answers',
          label: 'Answers',
          thClass: 'table__head',
          tdClass: 'table__body'
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      answersAll: 'account/answers/answers',
    }),
  },
  watch:{
    answersAll(){
      this.setShowPreloader(false)
    },
  },
  mounted() {
    this.checkData = !!this.answered
  },
  methods: {
    ...mapActions({
      setShowPreloader: 'account/setShowPreloader',
    }),
    openModalQuestionnaire() {
      this.$bvModal.show(MODAL_EDIT_QUESTIONNAIRE_ID);
      this.$store.dispatch('account/question/setShow', true)
      this.$store.dispatch('account/question/setEdit', true)
    }
  }
}
