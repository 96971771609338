//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "FileInputImageCustom",
  props: {
    accept: {
      type: String,
      default: '.png'
    },
    file: {
      type: Object,
      default: () => {
        return {id: '', updated_at: '', filename: '', url: ''}
      }
    },
    replaceMode: {
      type: Boolean,
      default: () => {
      }
    },
  },
  data() {
    return {
      fileUrl: '',
      fileLoad: false,
    }
  },
  created() {
    this.updateAttributes()
  },
  methods: {
    updateAttributes() {
      this.fileLoad = !!Object.keys(this.file).length
      if (this.fileLoad)
        this.fileUrl = this.file.medium
    },
    async handleFileUpload(e) {
      this.$emit('change')
      const files = e.target.files || e.dataTransfer.files;

      if (files.length) {
        const file = files[0]

        const reader = new FileReader();

        reader.onload = (event) => {
          this.fileUrl = event.target.result;
        }
        reader.readAsDataURL(file)
        this.$emit('change', file)
        this.fileLoad = true
      }
    },
    deleteFile() {
      this.$emit('change')
      this.fileLoad = false

      this.$emit('delete')
    },
  }
}
