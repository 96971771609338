//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseModal from './BaseModal';
import BaseButton from '../buttons/BaseButton';

export default {
  name:'ModalText',
  components: {
    BaseButton,
    BaseModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: ''
    },
    msg: {
      type: String,
      default: ''
    },
    msgArray: {
      type: Array,
      default: () => []
    },
    asNumberList: {
      type: Boolean,
      default: false
    },
    buttonClass : {
      type : Boolean,
      default: false
    }
  },
  data: () => ({
    bodyClass: 'modal-franchise-edit',
    modalSize: 'xs',
  }),
  methods: {
    ok() {
      this.$emit('close');
    }
  },
};
