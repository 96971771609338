//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    sortAsc: {
      type: Boolean,
      default: () => true
    },
    sortDesc: {
      type: Boolean,
      default: () => false
    },
    name: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {}
  },
  methods: {
    handleChangeSort (e) {
      let targetClassList = e.target.classList
    }
  }
}
