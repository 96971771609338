//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    shown: {
      type: Function,
      default() {},
    },
    size: {
      type: String,
      default: '',
    },
    customModalTitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    hideHeaderTitle: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: true,
    },
    modalClass: {
      type: String,
      default: '',
    },
    headerClass: {
      type: String,
      default: 'border-bottom-0',
    },
    contentClass: {
      type: String,
      default: '',
    },
    footerClass: {
      type: String,
      default: 'border-top-0',
    },
    dialogClass: {
      type: String,
      default: '',
    },
    bodyClass: {
      type: String,
      default: '',
    },
    staticModal: {
      type: Boolean,
      default: false,
    },
    lazyRender: {
      type: Boolean,
      default: true,
    },
    backDrop: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      showModal: this.show,
    };
  },
  watch: {
    show(value) {
      this.showModal = value;
    },
  },
};
