//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormEditFranchiseMain from "@/components/form/editFranchise/FormEditFranchiseMain";
import FormEditFranchiseQuickFacts from "@/components/form/editFranchise/FormEditFranchiseQuickFacts";
import FormEditFranchiseFiles from "@/components/form/editFranchise/FormEditFranchiseFiles";
import FormEditFranchiseSeo from "@/components/form/editFranchise/FormEditFranchiseSeo";
import {MODAL_EDIT_FRANCHISE_ID} from '@/store/constants';
import ClearIcon from "@/components/icons/ClearIcon";
import VButton from "@/components/VButton";

export default {
  name: "ModalEditFranchise",
  components: {
    VButton,
    ClearIcon,
    FormEditFranchiseSeo, FormEditFranchiseFiles, FormEditFranchiseQuickFacts, FormEditFranchiseMain
  },
  data: () => {
    return {
      MODAL_EDIT_FRANCHISE_ID,
      showErrorInSecond: 0,
      showNoSaveInSecond: 0,
      showSuccessInSecond: 0,
      message: {
        error: 'There were some problems with your input.',
        not_save: 'You have unsaved data.',
        success: 'Successfully saved.',
      },
      busy: false,
      tabs: [
        {
          id: 'main',
          title: 'Main',
          component: 'form-edit-franchise-main',
          edited: false,
          show: true
        },

        {
          id: 'quick_facts',
          title: 'Quick Facts',
          component: 'form-edit-franchise-quick-facts',
          edited: false,
          show: false
        },

        {
          id: 'files',
          title: 'Files',
          component: 'form-edit-franchise-files',
          edited: false,
          show: false
        },

        {
          id: 'seo',
          title: 'Seo',
          component: 'form-edit-franchise-seo',
          edited: false,
          show: false
        },
      ]
    }

  },
  mounted() {
    this.$root.$on('bv::collapse::state', this.handlerChangeStateCollapse);
  },
  beforeDestroy() {
    this.showErrorInSecond = 0
    this.showNoSaveInSecond = 0
    this.showSuccessInSecond = 0
  },
  methods: {
    handlerCloseModal(){
      this.showErrorInSecond = 0
      this.showNoSaveInSecond = 0
      this.showSuccessInSecond = 0
    },
    getNameTab(tab) {
      return tab.edited ? `${tab.title} *` : tab.title
    },
    showMessage(error = false) {
      this.busy = false
      if (error)
        this.showErrorInSecond = 3;
      else
        this.showSuccessInSecond = 3;
    },
    submit() {
      const currentTab = this.tabs.filter(item => item.show)[0];
      const editableTabs = this.tabs.filter(item => item.edited);

      if (!!editableTabs.length && !currentTab.edited) {
        this.showNoSaveInSecond = 3
        return
      }

      if (!currentTab.edited)
        return

      currentTab.edited = false
      this.$refs.tabs.forEach(component => {
        if (component.blockId === currentTab.id) {
          component.submit()
          this.busy = component.form.busy
        }
      })
    },
    handlerChangeStateCollapse(collapseId, isJustShown) {
      this.tabs.forEach(item => {
        if (item.id === collapseId)
          item.show = isJustShown
      })
    },
    changeTab(tabId) {
      const tab = this.tabs.filter(item => item.id === tabId)[0];

      if (tab.show)
        return

      this.$root.$emit('bv::toggle::collapse', tabId)

    },
    tabEdited(id) {
      this.tabs.forEach(item => {
        if (item.id === id)
          item.edited = true
      })
      this.busy = true;
      this.setBusyChanged();
    },
    setBusyChanged(timeout = 1500) {
      setTimeout(() => {
        this.busy = false;
      }, timeout);
    },
  },
}
