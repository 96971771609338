var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"steps-card-v2-wrapper"},[_c('div',{class:[
      'steps-card-v2',
      { 'steps-card-v2--done': _vm.statusDone },
      { 'steps-card-v2--in-progress': _vm.statusInProgress },
      { 'steps-card-v2--todo': _vm.statusTodo } ]},[_c('div',{staticClass:"steps-card-v2__top-row"},[_c('span',{staticClass:"steps-card-v2__statuses steps-card-v2-statuses"},[_c('check-outlined-icon',{staticClass:"steps-card-v2__check-icon"}),_vm._v(" "),_c('span',{staticClass:"steps-card-v2__step-number"},[_vm._v("0"+_vm._s(_vm.$vnode.key + 1))])],1),_vm._v(" "),_c('div',{staticClass:"steps-card-v2__messages"},[(_vm.statusDone || _vm.statusInProgress)?_c('span',{staticClass:"steps-card-v2__messages-count"},[_vm._v("\n          "+_vm._s(_vm.messageLabel)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.statusInProgress && _vm.step.count_new_messages)?_c('status-dot',{staticClass:"steps-card-v2__messages-indicator",attrs:{"background-color":"#00A769","wrapper-color":"rgba(0, 167, 105, 0.2)"}}):(!_vm.statusTodo)?_c('status-dot',{staticClass:"steps-card-v2__messages-indicator",attrs:{"background-color":"#D1D6E7","wrapper-color":"rgba(209, 214, 231, 0.2)"}}):_vm._e()],1)]),_vm._v(" "),_c('h4',{staticClass:"steps-card-v2__title"},[_vm._v("\n      "+_vm._s(_vm.step.name)+"\n    ")]),_vm._v(" "),_c('ul',{staticClass:"steps-card-v2__doc-list steps-card-v2-doc-list"},_vm._l((_vm.step.docs),function(doc,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(doc.doc_subtype !== 'none'),expression:"doc.doc_subtype !== 'none'"}],key:index,class:[
          'steps-card-v2-doc-list__item',
          { 'doc-list-item--presentation': doc.doc_subtype === 'presentation' } ],on:{"click":function($event){return _vm.$emit('click-document', index)}}},[_vm._v("\n        "+_vm._s(doc.doc_subtype)+"\n      ")])}),0),_vm._v(" "),_c('div',{staticClass:"steps-card-v2__buttons steps-card-v2-buttons"},[_c('button',{class:[
          'steps-card-v2-buttons__call',
          'steps-card-v2-button',
          'steps-card-v2-button--primary',
          { 'steps-card-v2-button--disabled': !_vm.statusInProgress } ],attrs:{"disabled":_vm.statusTodo},on:{"click":_vm.goToScheduleCall}},[_vm._v("\n        Schedule a Call\n      ")]),_vm._v(" "),_c('button',{staticClass:"steps-card-v2-buttons__chat steps-card-v2-button steps-card-v2-button--success",attrs:{"disabled":_vm.statusTodo},on:{"click":function($event){return _vm.$router.push(("/franchisee-steps/chat?slug=" + (_vm.step.slug)))}}},[_vm._v("\n        Chat\n      ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }