//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters} from "vuex"
import ClearIcon from "@/components/icons/ClearIcon"
import ActionsDateRangePicker from "@/components/account-franchisor/range/ActionsDateRangePicker"
import ContactActionTaskEdit from "@/components/account-franchisor/contacts/actions/tasks/ContactActionTaskEdit"
import ContactActionTaskCard from "@/components/account-franchisor/contacts/actions/tasks/ContactActionTaskCard"
import PaginationDefault from "@/components/paginations/PaginationDefault"
import Loader from "@/components/Loader"

export default {
  name: "ContactActionsTasks",
  components: {
    Loader,
    PaginationDefault,
    ContactActionTaskCard,
    ContactActionTaskEdit,
    ActionsDateRangePicker,
    ClearIcon,
  },
  props: {
    franchiseeId: {
      type: Number,
      default: null
    },
  },
  data: () => ({
    showEdit: false,
    isCreateMode: false,
    currentTask: {},
  }),
  computed: {
    ...mapGetters({
      taskListArr: 'account-franchisor/actions/tasks/taskListArr',
      paginationObj: 'account-franchisor/actions/tasks/paginationObj',
      filterObj: 'account-franchisor/actions/tasks/filterObj',
      busy: 'account-franchisor/actions/tasks/busy',
    }),
    modalTitle() {
      return this.isCreateMode ? 'Create task' : 'Edit task'
    },
    getDate() {
      if (this.taskListArr.length === 0)
        return ''

      const item = this.taskListArr[0]
      const day = new Date(item.created_at)
      const month = day.toLocaleString('en', {month: 'long'}).substring(0, 3)
      return `${month} ${day.getDate()}`
    },
  },
  mounted() {
    this.fetchTasks(this.franchiseeId)
    this.fetchContactsList()
  },
  methods: {
    ...mapActions({
      fetchTasks: 'account-franchisor/actions/tasks/fetchTasks',
      changePage: 'account-franchisor/actions/tasks/changePage',
      updateFilter: 'account-franchisor/actions/tasks/updateFilter',
      fetchContactsList: 'account-franchisor/contacts/fetchContactsList',
    }),
    handlerSavedEvent(){
      if (this.isCreateMode)
        this.showEdit = false
    },
    async handlerChangeDatePicker({startDate, endDate}) {
      const filterStartDate = startDate === null? startDate: new Date(startDate).toISOString()
      const filterEndDate = endDate === null? endDate: new Date(endDate).toISOString()

      await this.updateFilter({startDate:filterStartDate, endDate:filterEndDate})
      await this.fetchTasks(this.franchiseeId)
    },
    handlerCreateNewTask() {
      this.currentTask = {}
      this.isCreateMode = true
      this.showEdit = true
    },
    async handlerChangeEdit(task) {
      this.isCreateMode = false
      this.currentTask = task
      this.showEdit = true
    },
    async handlerChangeDelete(task) {
      const id = task.id

      try {
        await this.$axios.delete(`api/v2/franchisor-contacts/tasks/delete/${id}`)
        await this.fetchTasks(this.franchiseeId)
      } catch (e) {
        console.log(e)
      }
    },
    handlerChangePage(page) {
      this.changePage(page)
      this.fetchTasks(this.franchiseeId)
    },
  }
}

