//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters
} from "vuex"
import Form from "vform"
import cloneDeep from "lodash/cloneDeep"
import Editor from "@tinymce/tinymce-vue"
import VInputDefault from "@/components/form/defaultField/VInputDefault"
import VSelectDefault from "@/components/form/defaultField/VSelectDefault"
import VSelectDateDefault from "@/components/form/defaultField/VSelectDateDefault"
import VButton from "@/components/VButton"
import VFileInputMultipleDefault from "@/components/form/defaultField/VFileInputMultipleDefault"
import VSelectDateReminderDefault from "@/components/form/defaultField/VSelectDateReminderDefault"
import {
  ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS,
  ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS,
  ACCOUNT_FRANCHISOR_TASK_STATUS_LIST,
} from "@/store/constants"
import VSelect from "~/components/form/VSelect"

export default {
  name: "ContactActionTaskEdit",
  components: {
    VSelectDateReminderDefault,
    VFileInputMultipleDefault,
    VButton,
    VSelectDateDefault,
    VSelectDefault,
    VInputDefault,
    VSelect,
    Editor,
  },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    franchiseeId: {
      type: Number,
      default: null
    },
    selectForTasks: {
      type: Boolean,
      default: false,
    },
    contactsTab: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    activeEditor: false,
    contactsFilter: {
      search: null,
      activeStep: null,
      filter: null,
      filterPrev: null,
      preferences: {
        industry: null,
        state_id: null,
        total_amount_invest: null,
      },
    },
    uploadedFiles: [],
    form: new Form({
      franchisee_id: null,
      franchise_id: null,
      full_name: '',
      title: '',
      text: ' ',
      due_date_day: '',
      due_date_time: '00:00',
      reminder: '',
      files: [],
      deleted_files_id: [],
      status: 0,
    }),
    apiKey: process.env.TINY_MCE_API_KEY,
    editorInFocus: false,
    optionsDueDateDay: [
      ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.TODAY,
      ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.TOMORROW,
      ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.IN_ONE_MONTH,
      ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.IN_THREE_MONTHS,
      ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.IN_SIX_MONTHS,
      ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.CUSTOM_DATE,
    ],
    optionsReminderDay: [
      ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS.AT_TASK_DUE_TIME,
      ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS.ONE_HOUR_BEFORE,
      ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS.ONE_DAY_BEFORE,
      ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS.ONE_WEEK_BEFORE,
      ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS.CUSTOM_DATE,
    ],
    statusList: ACCOUNT_FRANCHISOR_TASK_STATUS_LIST,
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      franchisor: 'account-franchisor/ownerData',
      franchisee: 'account-franchisor/franchiseeViewed',
      contacts: 'account-franchisor/contacts/contactList',
      franchiseId: 'account-franchisor/franchiseId',
    }),
    errorLabel(){
      return this.form.errors.has('franchisee_id')
    },
    contactsArr(){
      return this.contacts.map(item => ({title: item.full_name, value: item.id}))
    },
    contactsPlaceholder(){
      if (!this.form.full_name){
        return 'Search or select contact...'
      }
      return this.form.full_name
    },
    contactsFilterOptionsSelected() {
      if (!this.contactsFilter.filter) return []

      const result = []
      this.contactsFilter.filter.forEach(el => result.push(el.value))
      return result
    },
    configEditor() {
      return {
        menubar: false,
        convert_urls: false,
        statusbar: false,
        plugins: 'lists link paste code image',
        toolbar: 'bold italic strikethrough bullist numlist link ',
        image_title: true,
        automatic_uploads: true,
        file_picker_types: 'file image',
        image_dimensions: false,
        content_css: `/gjs/css/fonts.css`,
        contextmenu: false,
        paste_data_images: true,
        paste_filter_drop: false,
        paste_preprocess(plugin, args) {
          args.content = args.content
            .replace(/style=".*?"/g, '')
            .replace(/dir=".*?"/g, '')
            .replace(/<img.*?>/g, '')
        },
        content_style: `
          body {
            padding-left: 10px;
            padding-right: 10px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #212A4B;
          }
          h1 {
            font-size: 24px;
          }
          h2 {
            font-size: 20px;
          }
          h3 {
            font-size: 16px;
          }
          a {
            color: #00a769;
            text-decoration: none;
          }
          * {
            max-width: 100%;
          }
          `,
      }
    },
    optionsDueTime() {
      let globalMinutes = 0
      let globalHours = 0
      const stepWidth = 15
      let step = 0
      return Array(24 * 60 / stepWidth)
        .fill()
        .map(i => {
          step += 1
          const minutes = globalMinutes < 10 ? `0${globalMinutes}` : globalMinutes
          const hours = globalHours < 10 ? `0${globalHours}` : globalHours

          globalMinutes = step * stepWidth
          if (globalMinutes === 60) {
            globalHours += 1
            globalMinutes = 0
            step = 0
          }

          return {
            text: `${hours}:${minutes}`,
            value: `${hours}:${minutes}`,
          }
        })
    },
    getCurrentDueDate() {
      if (this.form.due_date_day === '')
        return ''

      const time = this.form.due_date_time === '' ? '00:00' : this.form.due_date_time

      return `${this.form.due_date_day} ${time}`
    }
  },
  mounted() {
    this.fetchContactsListAll()
    this.calculatedData()
    setTimeout(() => {
      this.activeEditor = true
    }, 1000)
  },
  beforeDestroy() {
    this.activeEditor = false
  },
  methods: {
    ...mapActions({
      fetchContactsListAll: 'account-franchisor/contacts/fetchContactsListAll',
      fetchContactsList: 'account-franchisor/contacts/fetchContactsList',
      fetchContactsSelected: 'account-franchisor/contacts/fetchContactsSelected',
      fetchTasks: 'account-franchisor/actions/tasks/fetchTasks',
      fetchCurrentContact: 'account-franchisor/contacts/fetchCurrentContact',
    }),
    async updateContactSearch(e){
      const search = e
      const page = 1

      await this.fetchContactsList({
        search, page
      })
    },
    getFormatedStringDate(date) {
      const mm = date.getMonth() + 1
      const dd = date.getDate()
      return `${date.getFullYear()}-${(mm > 9 ? '' : '0')}${mm}-${(dd > 9 ? '' : '0')}${dd}`
    },
    handlerChangeDueDate() {
      this.form.reminder = ''
    },
    calculatedData() {
      if (Object.keys(this.task).length === 0)
        return

      this.form.title = this.task.title
      this.form.full_name = this.task.franchisee.full_name
      this.form.text = this.task?.text ?? ''
      this.form.status = this.task?.status ?? 0

      if (this.task.due_date !== null) {
        const day = new Date(this.task.due_date)
        this.form.due_date_day = this.getFormatedStringDate(day)

        const hours = (`0${  day.getHours()}`).slice(-2)
        const minutes = (`0${  day.getMinutes()}`).slice(-2)
        this.form.due_date_time = `${hours}:${minutes}`
      }
      if (this.task.reminder !== null) {
        const day = new Date(this.task.reminder)
        this.form.reminder = `${this.getFormatedStringDate(day)} ${day.getHours()}:${day.getMinutes()}`
      }

      if (this.task.files.length > 0)
        this.uploadedFiles = cloneDeep(this.task.files)

    },
    handlerChangeFiles(files) {
      this.form.files = files
    },
    handlerDeleteFiles(id) {
      const fileIndex = this.uploadedFiles.findIndex(item => Number(item.id) === Number(id))
      this.uploadedFiles.splice(fileIndex, 1)
      this.form.deleted_files_id.push(id)
    },
    async handlerClickSave() {
      if (!isNaN(this.franchiseeId)) {
        this.form.franchisee_id = this.franchiseeId
      }
      this.form.franchise_id = this.franchiseId

      const id = this.task?.id ?? 0
      try {
        await this.form.submit(
          'post',
          `/api/v2/franchisor-contacts/tasks/update/${id}`,
          {
            headers: {'Content-Type': 'multipart/form-data'},
            transformRequest: [(data) => {
              const formData = new FormData

              if (data.due_date_day !== '') {
                const timeString = data.due_date_time === '' ? '00:00' : data.due_date_time
                const dateTimeString = `${data.due_date_day} ${timeString}`
                const day = new Date(dateTimeString)
                formData.append('due_date', `${day.toISOString().substring(0, 19)}+00:00`)
              }

              if (data.reminder !== '') {
                const day = new Date(data.reminder)
                formData.append('reminder', `${day.toISOString().substring(0, 19)}+00:00`)
              }

              Object.keys(data).forEach(key => {
                if (
                  key === 'due_date_day' ||
                  key === 'due_date_time' ||
                  key === 'reminder' ||
                  key === 'text' ||
                  key === 'deleted_files_id' ||
                  key === 'files'
                )
                  return

                const item = data[key]

                if (
                  item === '' ||
                  item === ' '
                )
                  return

                formData.append(key, item)
              })

              if (data.text !== '' || data.text !== ' ')
                formData.append('text', data.text.split('').map((char) => char.charCodeAt(0).toString(2)).join(' '))

              if (data.files.length > 0) {
                data.files.forEach((file, fileIndex) => {
                  formData.append(`files[${fileIndex}]`, file)
                })
              }
              if (data.deleted_files_id.length > 0) {
                data.deleted_files_id.forEach((file, fileIndex) => {
                  formData.append(`deleted_files_id[${fileIndex}]`, file)
                })
              }
              return formData
            }],
          })

        await this.fetchTasks(this.form.franchisee_id)
        this.$emit('saved')
      } catch (e) {
        console.log(e)
        setTimeout(() => {
          this.form.errors.set({})
        }, 5000)
      }
    }
  }
}
