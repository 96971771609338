//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DefaultLogo from '@/assets/icons/landing/DefaultLogo.svg?inline'
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import {mapGetters} from "vuex";
import landingActions from "@/components/mixins/landingActions";

export default {
  name: "LandingLogo",
  components: {
    DefaultLogo,
    Cropper,
  },
  mixins: [
    landingActions
  ],
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    simpleMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      preview: {
        type: '',
        canvas: '',
        width: 0,
        height: 0,
      },
      fileUrl: '',
    }
  },
  computed: {
    ...mapGetters({
      logo: 'landing/logo',
    }),
    imgUrl() {
      return `${process.env.BACKEND_BASE_URL}/api/v2/landing/${this.logo.url}/image`;
    }
  },
  methods: {
    hideCropper() {
      this.preview.canvas = ''
      this.preview.type = ''
    },
    async changeFileInput(value) {
      this.formAttributes.image = value;
      await this.submit();
    },
    async submit() {
      const resultSave = await this.saveFile(this.preview.canvas, this.preview.type)

      if (!resultSave)
        return

      this.hideCropper()
      await this.$store.dispatch('landing/updateLogo', resultSave)
    },
    changeCrop({image, canvas}) {
      this.preview.canvas = canvas.toDataURL(this.preview.type)
      this.preview.height = image.height
      this.preview.width = image.width
    },
    loadFile(e) {
      const files = e.target.files || e.dataTransfer.files;

      if (files.length) {
        const file = files[0]
        this.preview.type = file.type
        const reader = new FileReader();

        reader.onload = (event) => {
          this.fileUrl = event.target.result;
        }
        reader.readAsDataURL(file)
      }
    },
  },
}
