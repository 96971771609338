//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: {
    topLabel: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    value: {
      type: [Number, String],
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    inputNativeType: {
      type: String,
      default: () => 'text'
    },
    inputName: {
      type: String,
      default: () => ''
    },
    compacted: {
      type: Boolean,
      default: false,
    },
  }
}
