//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SilentIcon from '@/assets/images/svg/silent-mode.svg?inline'

export default {
  name: 'SilentMode',
  components: {
    SilentIcon,
  },
  computed: {
    tipId() {
      return `silent-tip${Math.random().toString()}`;
    },
  },
}
