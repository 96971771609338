//
//
//
//
//
//
//
//
//
//
//
//
//

import { BButton } from 'bootstrap-vue';

export default {
  components: {
    BButton,
  },
  props: {
    busy: {
      type: Boolean,
      default: () => false,
    },
    bs4Variant: {
      type: String,
      default: () => 'primary',
    },
    bs4Size: {
      type: String,
      default: () => 'md',
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isDisabled() {
      if (this.disabled) return this.disabled;
      return this.busy;
    }
  },
  methods: {
    handleClickButton(e) {
      this.$emit('click', e);
    },
  },
};
