var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('div',[_c('b-table',{staticClass:"account_table",attrs:{"id":_vm.progress > 9 ? 'progress': '',"fields":_vm.fields,"items":_vm.items,"sort-by":_vm.sort.field,"sort-desc":_vm.sort.direction === 'desc',"no-local-sorting":"","responsive":"","sort-icon-right":""},on:{"sort-changed":_vm.sortingChanged},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v("\n      "+_vm._s(data.item.franchiseNumber)+"\n    ")]}},{key:"cell(business_image)",fn:function(data){return [_c('router-link',{attrs:{"to":_vm.getFranchiseDetailUrl(data.item)}},[_c('img',{attrs:{"src":data.item.logo.mini,"alt":"logo"}})])]}},{key:"cell(business_name)",fn:function(data){return [_c('router-link',{staticClass:"account_table__link",attrs:{"to":_vm.getFranchiseDetailUrl(data.item)}},[_vm._v("\n        "+_vm._s(data.item.business_name)+"\n      ")])]}},{key:"cell(like)",fn:function(data){return [_c('button',{staticClass:"account_table__button--icon",class:{
          active: _vm.checkStatusLike(data.item),
          loading: _vm.checkStatusLikeBusy(data.item),
        },attrs:{"disabled":_vm.isBlocked},on:{"click":function($event){return _vm.handlerButtonLike(data.item)}}},[_c('like-icon-v4')],1)]}},{key:"cell(average_revenue)",fn:function(data){return [(data.item.average_revenue > 0)?_c('MoneyFormat',{attrs:{"hide-subunits":true,"value":Number(data.item.average_revenue)}}):[_vm._v("\n        Coming soon\n      ")]]}},{key:"cell(investment)",fn:function(data){return [(Number(data.item.investment.low) > 0)?_c('MoneyFormat',{attrs:{"hide-subunits":true,"value":Number(data.item.investment.low)}}):[_vm._v("\n        Coming soon\n      ")]]}},{key:"cell(membership)",fn:function(data){return [_vm._v("\n      "+_vm._s(_vm.ucFirst(data.item.membership))+"\n    ")]}},{key:"cell(compare)",fn:function(data){return [_c('button',{staticClass:"card-body__compare",class:{
          active: _vm.checkStatusCompare(data.item),
          loading: _vm.checkStatusCompareBusy(data.item),
        },attrs:{"id":((data.item.franchise_id) + "-tooltip-button"),"disabled":_vm.isBlocked},on:{"click":function($event){return _vm.handlerButtonCompare(data.item)}}},[_vm._v("\n        "+_vm._s(_vm.checkStatusCompare(data.item) ? 'In Comparisons' : 'Compare')+"\n      ")])]}},{key:"cell(franchise_fee)",fn:function(data){return [_c('MoneyFormat',{attrs:{"hide-subunits":true,"value":Number(data.item.franchise_fee.low)}})]}},{key:"cell(number_of_locations)",fn:function(data){return [(data.item.number_of_locations !== '')?[_vm._v("\n        "+_vm._s(data.item.number_of_locations)+"\n      ")]:[_vm._v("\n        N/A\n      ")]]}},{key:"cell(fdd)",fn:function(data){return [(data.item.files.fdd)?[_c('button',{staticClass:"account_table__button--icon",attrs:{"disabled":_vm.isBlocked},on:{"click":function($event){return _vm.handlerOpenFile(data.item, data.item.files.fdd.url, data.item.franchise_id)}}},[_c('FddIcon')],1)]:[_vm._v("\n        N/A\n      ")]]}},(!_vm.isBlocked)?{key:"cell(contact)",fn:function(data){return [_c('button',{staticClass:"account_table__button",class:{
          flow_started: _vm.checkStatusProgress(data.item),
          loading: _vm.checkStatusProgressBusy(data.item),
        },attrs:{"id":("button_item_" + (data.item.franchise_id)),"type":"button"},on:{"click":function($event){return _vm.handlerButtonProgress(data.item)}}},[_vm._v("\n        "+_vm._s(_vm.getProgressLabel(data.item))+"\n      ")])]}}:null],null,true)}),_vm._v(" "),_c('modal-text',{attrs:{"msg":_vm.SignedInMsg,"show":_vm.modalSignedInMsgShow},on:{"close":function($event){_vm.modalSignedInMsgShow = false}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }