//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "PaginationDefault",
  props: {
    total: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 0
    },
    align: {
      type: String,
      default: 'left'
    },
    currentPage: {
      type: String,
      default: '1'
    }
  },

  methods: {
    handleChangePage(numPage) {
      this.$emit('change', numPage)
    }
  }

}
