//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CompanyLanding',
  data() {
    return {

    }
  },
}
