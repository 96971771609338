//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReviewStars from "~/assets/images/pcmania/review-stars.svg?inline"

export default {
  name: "ReviewCard",
  components: {
    ReviewStars
  }
}
