//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EditPencil from "@/assets/icons/landing/editor/EditPencil.svg?inline";
import BlankBlock from "@/components/landing/blank/BlankBlock";
import VInputV3 from "@/components/form/VInputV3";

export default {
  name: "LandingVideo",
  components: {
    VInputV3,
    EditPencil,
    BlankBlock,
  },
  model: {
    prop: 'html',
    event: 'input',
  },
  props: {
    css: {
      default: '',
    },
    html: {
      type: Object,
      default: () => ({}),
    },
    editable: {
      type: Boolean,
      default: false,
    },
    franchiseId: {
      type: Number,
      default: 0,
    },
    versionId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      result: null,
      editing: false,
      toolbar: '',
      content: '',
      backup: '',
      backupState: true,
    }
  },
  created() {
    this.content = this.html;
    this.result = this.content.url;
  },
  methods: {
    start() {
      this.editing = true;
    },
    save() {
      this.content.url = this.result.trim();
      this.$emit('input', this.content);
      this.editing = false;
    },
    cancel() {
      this.content = this.html;
      this.editing = false;
    },
  }
}
