//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from "vform"
import {
  mapActions,
  mapGetters,
} from "vuex"
import LinkedInSignUpButton from "@/components/buttons/LinkedInSignUpButton"
import GoogleSignUpButton from "@/components/buttons/GoogleSignUpButton"
import VButton from "@/components/VButton"
import VInputDefault from "@/components/form/defaultField/VInputDefault"
import LogoWithTradeMark from "~/components/LogoWithTradeMark"

import googleAnalyticsMixin from "~/components/mixins/googleAnalyticsMixin"
import hubspotMixin from "~/components/mixins/hubspotMixin"

import {
  SIGN_UP_FRANCHISE_FAST_STEP_TWO,
  USER_TYPE_FRANCHISEE,
  REGISTRATION_DEFAULT_PASSWORD,
  SIGN_UP_FRANCHISE_FAST_STEP_THREE,
  CLOSED_FORM_TYPES,
} from '@/store/constants'

export default {
  name: "SignUpFranchiseStepOnePending",
  components: {
    VInputDefault,
    LogoWithTradeMark,
    VButton,
    GoogleSignUpButton,
    LinkedInSignUpButton,
  },
  mixins: [
    googleAnalyticsMixin,
    hubspotMixin,
  ],
  data: () => {
    return {
      submitted: false,
      form: new Form({
        first_name: '',
        last_name: '',
        email: '',
        password: REGISTRATION_DEFAULT_PASSWORD,
        password_confirmation: REGISTRATION_DEFAULT_PASSWORD,
        busy: false,
        user_type: USER_TYPE_FRANCHISEE
      }),
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      enableFlowBeginToday: 'registration/enableFlowBeginToday',
      enableFlowPendingList: 'registration/enableFlowPendingList',
      pendingListEmail: 'registration/pendingListEmail',
      pendingListSendArr: 'registration/pendingListSendArr',
    }),
    userType() {
      return USER_TYPE_FRANCHISEE
    }
  },
  mounted() {
    if (this.pendingListEmail !== '')
      this.form.email = this.pendingListEmail
  },
  async destroyed () {
    if (!this.submitted && this.form.email) {
      await this.hsClosedForm(this.form.email, CLOSED_FORM_TYPES.signUpFranchisee)
    }
  },
  methods: {
    ...mapActions({
      associateUserWithRating: 'rating/associateUserWithRating',
      savePendingList: 'registration/savePendingList',
      updatePendingList: 'account/updatePendingList',
    }),
    async registerFranchisee() {
      try {
        const {data} = await this.form.post('/api/register')

        await this.$store.dispatch('auth/saveToken', {
          token: data.result.token,
          remember: data.result.remember,
          expires: data.result.expires,
        })

        this.submitted = true

        await this.gaSendFranchiseSignUpForm()
        if (this.pendingListSendArr){
          await this.savePendingList()
          await this.updatePendingList([])
        }
        await this.$store.dispatch('auth/fetchUser')
        await this.$emit('change-step', SIGN_UP_FRANCHISE_FAST_STEP_TWO)
        await this.associateUserWithRating()
      } catch (e) {
      }
    },
    async onAuthenticated(isNewUser = true) {
      if (isNewUser) {
        try {
          if (this.pendingListSendArr){
            await this.savePendingList()
            await this.updatePendingList([])
          }
          await this.$axios.get(`api/v2/business-pending/add-in-dashboard`)
        }catch (e){
          console.log(e)
        }
      }

      await this.$router.push('/account/dashboard')
      await this.$emit('change-step', SIGN_UP_FRANCHISE_FAST_STEP_THREE)
    },
    onError(message) {
      this.errorMessage = message

      if (!this.form.errors.any()) {
        this.form.errors.set({error: message})
      }
    }
  }
}
