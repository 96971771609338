//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WorkBookInput from "@/components/workbook/elements/WorkBookInput";
import {WORK_BOOK, WORK_BOOK_FIELD_TYPES, WORK_BOOK_TOP_LIST_KEYS} from "@/store/constants";
import {mapActions, mapGetters} from "vuex";
import {cloneDeep} from 'lodash/fp'
import ArrowCodeIcon from '~/assets/icons/arrows/ArrowCodeIcon.svg?inline'
import WorkBookTabLine from '~/components/workbook/elements/WorkBookTabLine'

export default {
  name: "WorkBookQuestionsOpenAdditional",
  components: {
    WorkBookInput,
    WorkBookTabLine,
    ArrowCodeIcon,
  },
  props: {
    blockIndex: {
      type: Number,
      default: 0
    },
  },
  data: () => ({
    fieldTypes: WORK_BOOK_FIELD_TYPES,
    currentFranchiseIndex: 0,
    currentTabIndex: 0,
  }),
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
      franchiseNameList: 'account/workbook/franchiseNameList',
      topList: 'account/workbook/topList',
      franchise: 'account/workbook/franchise',
    }),
    questionsBlock() {
      return WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex].questionsBlocks[this.blockIndex]
    },
    questionBlockClassList() {
      return this.questionsBlock.classList ?? ``
    },
    answersBlock() {
      return this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers
    },
    getFranchiseName() {
      const currentId = this.topList[WORK_BOOK_TOP_LIST_KEYS.FIVE][this.currentFranchiseIndex]
      const currentFranchise = this.franchise.find(item => Number(item.id) === Number(currentId))
      if (typeof currentFranchise?.franchise === "undefined")
        return `Franchise #${this.currentFranchiseIndex + 1}`

      return currentFranchise.franchise
    },
    currentTab() {
      return this.questionsBlock.tabs[this.currentTabIndex]
    },
    currentQuestions() {
      return this.questionsBlock.questions.filter(item => item.tabIndex === this.currentTabIndex)
    },
    getTotal() {
      let count = 0
      const fieldWithScore = this.questionsBlock.fields.find((field) => {
        if (typeof field.isScore !== "undefined")
          return field.slug
        return false
      })

      this.currentQuestions.forEach(question => {

        const answer = this.answersBlock.find(item => item.id === question.id)

        count += Number(answer.value[this.currentFranchiseIndex][fieldWithScore.slug])
      })

      return count
    },
  },
  methods: {
    ...mapActions({
      changeAnswer: 'account/workbook/changeAnswer',
    }),
    changeValue(questionId, fieldIndex, value) {
      const slug = this.questionsBlock.fields[fieldIndex].slug
      const questionIndex = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.findIndex(item => item.id === questionId)
      const savedValue = cloneDeep(this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId))
      savedValue.value[this.currentFranchiseIndex][slug] = value

      this.changeAnswer({blockIndex: this.blockIndex, questionIndex, value: savedValue.value})
    },
    getValue(questionId, fieldIndex) {
      const slug = this.questionsBlock.fields[fieldIndex].slug
      const savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)
      return savedValue ? savedValue.value[this.currentFranchiseIndex][slug] : null
    },
  }
}
