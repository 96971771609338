//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BContainer, BRow, BCol } from 'bootstrap-vue'
import { USER_PREFERENCES_TOTAL_AMOUNT_INVEST } from '~/store/constants'
import {mapState} from "vuex";

export default {
  components: {
    BContainer,
    BRow,
    BCol
  },
  props: {
    states: {
      type: Array,
      required: true
    },
    industries: {
      type: Array,
      required: true
    },
    totalAmount: {
      type: Number,
      required: true
    },
    countFranchises: {
      type: Number,
      required: true
    },
    countFranchisesByLocation: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    totalArray: USER_PREFERENCES_TOTAL_AMOUNT_INVEST
  }),
  computed: {
    totalAmountText () {
      const totalAmount = this.totalArray.find(
        el => el.value === this.totalAmount
      )
      let number = totalAmount.min
      let text = 'Over'

      if (totalAmount.max !== 0) {
        number = totalAmount.max
        text = 'Up to'
      }
      number = new Intl.NumberFormat('us-US').format(Number(number))

      return `${text} <strong>$ ${number}</strong>`
    },
    title () {
      return `We found <span>${this.countFranchises}</span> franchises for you:`
    },
  },
  methods: {
    getFirstWord (string) {
      return string.replace(/ .*/, '')
    },
    getLocationAmount (stateId) {
      const item = this.countFranchisesByLocation.find(item => item.id === stateId)

      return item ? item.count : 0
    },
    openQuestionnaire () {
      this.$router.push('/account/my-matches')
      setTimeout(() => {
        this.$store.dispatch('account/question/setShow', true)
      }, 500)
    },
    redirectTo (path, params = {}) {
      this.$store.dispatch('registration/closeModal')

      setTimeout(() => {
        this.$router.push({ path, params })
      }, 500)
    }
  }
}
