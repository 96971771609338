//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import BaseModal from '../BaseModal';
import BodyRequestReset from './BodyRequestReset';
import BodySignIn from './BodySignIn';

export default {
  components: {
    BodySignIn,
    BodyRequestReset,
    BaseModal,
  },
  metaInfo() {
    return { title: this.title };
  },
  data: () => ({
    showRequestReset: false,
  }),
  computed: {
    ...mapState({
      show: (state) => state.login.show,
    }),
    title() {
      let title = this.$t('login')

      if (this.showRequestReset) {
        title = this.$t('i_forgot_password')
      }

      return title
    },
  },
  methods: {
    closeModal() {
      setTimeout(() => {
        this.showRequestReset = false
      }, 300)

      this.$store.dispatch('login/closeModal')
      this.$store.commit('login/SET_SESSION_EXPIRED',false)
    },
    showRegisterModal() {
      this.closeModal()
      this.$store.dispatch('registration/openModal')
    },
    cancel() {
      this.showRequestReset = false
    },
  },
};
