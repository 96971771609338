//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {

  },
  beforeDestroy() {
    // change browser btn back behavior to lead him to the account from membership page
    history.pushState({id: 'account-franchisor'}, 'Account franchisor', '/account-franchisor');
  },
  destroyed() {
    this.$router.push('/membership');
  }
}
