//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapGetters
} from "vuex"
import VButton from "@/components/VButton"

import {
  USER_TYPE_FRANCHISOR,
  FRANCHISOR_FLOW_OPTIONS,
} from "~/store/constants"

export default {
  name: "SignUpFranchisorStepTwo",
  components: {
    VButton,
  },
  data: () => {
    return {
      finished: false,
      info: {
        business: {
          current: 0,
          max: 0,
        },
        industry: {
          current: 0,
          max: 0,
        },
        lost: {
          current: 0,
          max: 0,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      leads: 'registration/leads',
      franchiseId: 'registration/franchiseId',
    }),
  },
  mounted() {
    this.animateIncrement()
  },
  async beforeDestroy () {
    await this.startTrial()
  },
  methods: {
    async startTrial() {
      if (this.finished) return

      await this.$axios.post('/api/v1/business/flow/options', {
        franchise_id: this.franchiseId,
        option: FRANCHISOR_FLOW_OPTIONS.claim,
      })
      await this.$store.dispatch('registration/setOption', FRANCHISOR_FLOW_OPTIONS.claim)

      await this.$store.dispatch('registration/openModal')
      await this.$store.dispatch('registration/setUserType', USER_TYPE_FRANCHISOR)
      await this.$router.push({path: this.$route.path, query: {step_register_franchisor: '2'}})
      await this.$store.dispatch('registration/setStage', 'stepper')

      this.finished = true
      this.$emit('close')
    },
    animateIncrement() {
      const stepLimit = 70
      let i = 0
      const incrementInterval = setInterval(() => {
        for (const [key, value] of Object.entries(this.info)) {
          const maxVal = this.leads[key] ?? 0
          const stepVal = maxVal / stepLimit
          const newVal = Math.ceil(value.current + stepVal)
          this.info[key].current = newVal < maxVal ? newVal : maxVal
        }
        i++
        if (i >= stepLimit) clearInterval(incrementInterval)
      }, 10)
    }
  },
}
