//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VInputNumber from './VInputNumber'

export default {
  name: 'VRangeInputV2',
  components: {
    VInputNumber
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        min: null,
        max: null
      })
    },
    title: {
      type: String,
      default: () => ''
    },
    bordered: {
      type: Boolean,
      default: () => false
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showArrows: {
      type: Boolean,
      default: true,
    },
    compacted: {
      type: Boolean,
      default: false,
    },
    labelMin: {
      type: String,
      default: 'min:'
    },
    labelMax: {
      type: String,
      default: 'max:'
    },
    labelClass: {
      type: String,
      default:'',
    },
    isMoney: {
      type: Boolean,
      default: true,
    }
  }
}
