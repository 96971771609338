import { render, staticRenderFns } from "./PaginationDefault.vue?vue&type=template&id=6569490a&scoped=true&"
import script from "./PaginationDefault.vue?vue&type=script&lang=js&"
export * from "./PaginationDefault.vue?vue&type=script&lang=js&"
import style0 from "./PaginationDefault.vue?vue&type=style&index=0&id=6569490a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6569490a",
  null
  
)

export default component.exports