//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import BaseButton from "@/components/buttons/BaseButton"

export default {
  components: {
    BaseButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    franchiseId: {
      type: Number,
      default: 0,
    },
    franchiseTitle: {
      type: String,
      default: null,
    },
    franchiseLogo: {
      type: String,
      default: null,
    },
    scheduleCallLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sent: 0,
    };
  },
  computed: mapGetters({
    user: 'auth/user',
    authenticated: 'auth/check',
  }),
  async updated() {
    await this.HubSpotEvent();
  },
  methods: {
    getCookie(name) {
      const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
      if (match) return match[2];
    },
    async HubSpotEvent() {
      if (this.sent > 0) return null;
      this.sent++;

      // check if user is non auth
      if (this.user == null) return null;

      // Create the new request
      const xhr = new XMLHttpRequest();
      const url =
        'https://api.hsforms.com/submissions/v3/integration/submit/6241267/2db3c6ad-d216-4543-a225-cd6dc65b1c0f';

      // Example request JSON:
      const data = {
        submittedAt: this.getNow,
        fields: [
          {
            name: 'user_id',
            value: this.user.id,
          },
          {
            name: 'franchise_id',
            value: this.franchiseId,
          },
          {
            name: 'event_type',
            value: 'begin-today',
          },
          {
            name: 'event_page',
            value: 'franchise-page',
          },
          {
            name: 'email',
            value: this.user.email,
          },
          {
            name: 'firstname',
            value: this.user.first_name,
          },
          {
            name: 'lastname',
            value: this.user.last_name,
          },
          {
            name: 'franchise_title',
            value: this.franchiseTitle,
          },
          {
            name: 'franchise_url',
            value: location.href,
          },
          {
            name: 'franchise_logo',
            value: this.franchiseLogo,
          },
          {
            name: 'timestamp',
            value: +new Date(),
          },
        ],
        context: {
          hutk: this.getCookie('hubspotutk'), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission\
          pageUri: location.href,
          pageName: this.franchiseTitle,
        },
      };

      const FinalData = JSON.stringify(data);

      xhr.open('POST', url);
      // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // alert(eventType)
          // alert(xhr.responseText)
          // Returns a 200 response if the submission is successful.
        } else if (xhr.readyState === 4 && xhr.status === 400) {
          // Returns a 400 error the submission is rejected.
        } else if (xhr.readyState === 4 && xhr.status === 403) {
          // Returns a 403 error if the portal isn't allowed to post submissions.
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          // Returns a 404 error if the formGuid isn't found
        }
      };

      // Sends the request

      xhr.send(FinalData);
    },
    redirectToScheduleCall() {
      window.open(this.scheduleCallLink, '_blank');
    },
  },
};
