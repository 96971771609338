var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"question_open",class:_vm.questionBlockClassList},[(_vm.itemsListAmount > 1)?_c('div',{staticClass:"tab-line"},[_c('work-book-tab-line',{attrs:{"tab-list":_vm.getTabList(_vm.itemsListAmount, _vm.questionsBlock.id)},on:{"change":function($event){return _vm.setFranchiseIndex($event)}}})],1):_vm._e(),_vm._v(" "),_c('table',{staticClass:"question_open__table"},[_c('thead',{staticClass:"question_open__thead"},[(_vm.questionsBlock.extraTableHeader)?_c('tr',[_c('th',{staticClass:"number"}),_vm._v(" "),_vm._l((_vm.questionsBlock.extraTableHeader),function(item,index){return [_c('th',{key:index,staticClass:"extra_th",attrs:{"colspan":item.colspan}},[_vm._v("\n            "+_vm._s(item.name)+"\n          ")])]})],2):_vm._e(),_vm._v(" "),_c('tr',[_c('th',{staticClass:"number"}),_vm._v(" "),(!_vm.questionsBlock.isLeftDisabled)?_c('th',{staticClass:"question_open__th",style:(_vm.questionsBlock.leftTitleStyle),attrs:{"rowspan":"2"}},[_vm._v("\n          "+_vm._s(_vm.questionsBlock.isLeftTitle ? _vm.questionsBlock.leftTitle : " ")+"\n        ")]):_vm._e(),_vm._v(" "),_c('th')]),_vm._v(" "),(!_vm.questionsBlock.hideTableHeader)?_c('tr',{staticClass:"header-row"},[_c('th',{staticClass:"number"}),_vm._v(" "),_vm._l((_vm.itemsListAmount),function(item,itemIndex){return [_vm._l((_vm.questionsBlock.fields),function(field,fieldIndex){return [(itemIndex === _vm.currentFranchiseIndex)?_c('th',{key:("question_open_item_" + itemIndex + "_field_" + fieldIndex),class:[
                'question_open__th',
                { 'transparent' : field.transparent } ],style:(_vm.getFieldStyle(field)),domProps:{"innerHTML":_vm._s(_vm.getFieldFranchiseName(field, fieldIndex) + " " + field.name)}}):_vm._e()]})]})],2):_vm._e(),_vm._v(" "),(_vm.questionsBlock.subTableHeader)?_c('tr',{staticClass:"sub-header"},[_c('td',{staticClass:"number"}),_vm._v(" "),_c('td',{style:(_vm.questionsBlock.subTableHeader.style)},[_vm._v("\n          "+_vm._s(_vm.questionsBlock.subTableHeader.name)+"\n        ")])]):_vm._e()]),_vm._v(" "),_c('tbody',{staticClass:"question_open__tbody"},_vm._l((_vm.questionsBlock.questions),function(question,questionIndex){return _c('tr',{key:("question_open_questions_title_" + questionIndex),staticClass:"question_open__row"},[(_vm.getQuestionType(question.type) === _vm.WORK_BOOK_FIELD_TYPES.DEFAULT)?[_c('th',{staticClass:"number number__counter"},[_vm._v("\n            "+_vm._s(_vm.getQuestionIndex(question.index, questionIndex))+"\n          ")]),_vm._v(" "),(!_vm.questionsBlock.isLeftDisabled)?_c('th',{staticClass:"question_open__th question_open__th--content",domProps:{"innerHTML":_vm._s(question.text)}}):_vm._e(),_vm._v(" "),_vm._l((_vm.itemsListAmount),function(item,itemIndex){return [_vm._l((_vm.questionsBlock.fields),function(field,fieldIndex){return [(itemIndex === _vm.currentFranchiseIndex)?_c('td',{key:("question_open_answers_" + itemIndex + "_" + fieldIndex),staticClass:"question_open__td",class:_vm.getCellClass(fieldIndex),style:(_vm.getCellStyle(fieldIndex))},[(_vm.getFieldType(fieldIndex) === _vm.WORK_BOOK_FIELD_TYPES.SELECT)?[_c('work-book-input',{attrs:{"field":_vm.getField(fieldIndex),"index":questionIndex,"value":_vm.getValue(question.id, itemIndex, fieldIndex)},on:{"change":function($event){return _vm.handlerUpdateValue(question.id,itemIndex, fieldIndex, $event)}}})]:(_vm.getFieldType(fieldIndex) === _vm.WORK_BOOK_FIELD_TYPES.DIFFERENCE)?[_c('textarea',{class:[
                      'question_open__textarea',
                      'readonly',
                      { 'negative' : _vm.getDifference(question.id, itemIndex, fieldIndex) < 0 } ],style:(_vm.getInputStyle(fieldIndex)),attrs:{"placeholder":_vm.getPlaceholder(fieldIndex, question.id)},domProps:{"value":_vm.getDifference(question.id, itemIndex, fieldIndex)}})]:_c('textarea',{staticClass:"question_open__textarea",style:(_vm.getInputStyle(fieldIndex)),attrs:{"placeholder":_vm.getPlaceholder(fieldIndex, question.id)},domProps:{"value":_vm.getValue(question.id, itemIndex, fieldIndex)},on:{"change":function($event){return _vm.handlerUpdateValue(question.id,itemIndex, fieldIndex, $event.target.value)}}}),_vm._v(" "),(_vm.getDollarSign(fieldIndex))?[_c('span',{staticClass:"sign-dollar",style:(_vm.getDollarSign(fieldIndex))},[_vm._v("$")])]:_vm._e()],2):_vm._e()]})]})]:_vm._e(),_vm._v(" "),(
            _vm.getQuestionType(question.type) === _vm.WORK_BOOK_FIELD_TYPES.TOTAL ||
              _vm.getQuestionType(question.type) === _vm.WORK_BOOK_FIELD_TYPES.TOTAL_CALCULATED
          )?[_c('th',{staticClass:"number number__counter"},[_vm._v("\n            "+_vm._s(_vm.getQuestionIndex(question.index, questionIndex))+"\n          ")]),_vm._v(" "),_c('th',{staticClass:"question_open__th question_open__th--content question_open__th--content-total"},[_vm._v("\n            "+_vm._s(question.text)+"\n            "),_c('span',{staticClass:"extra-text"},[_vm._v(_vm._s(question.extraText))])]),_vm._v(" "),_vm._l((_vm.itemsListAmount),function(item,itemIndex){return [_vm._l((_vm.questionsBlock.fields),function(field,fieldIndex){return [_c('td',{key:("question_open_total_" + fieldIndex),staticClass:"question_open__td",style:(_vm.getCellStyle(fieldIndex))},[_c('span',{class:[
                    'question_open__textarea',
                    'question_open__textarea-total',
                    { 'negative' : _vm.getCustomTotal(fieldIndex, question) < 0 } ],style:(_vm.getInputStyle(fieldIndex))},[_vm._v("\n                  "+_vm._s(_vm.getCustomTotal(fieldIndex, question))+"\n                ")])])]})]})]:_vm._e(),_vm._v(" "),(_vm.getQuestionType(question.type) === _vm.WORK_BOOK_FIELD_TYPES.TOTAL_LINE)?[_c('th',{staticClass:"number number__counter"},[_vm._v("\n            "+_vm._s(_vm.getQuestionIndex(question.index, questionIndex))+"\n          ")]),_vm._v(" "),_c('td',{key:"question_open_total_line",staticClass:"question_open__td",attrs:{"colspan":"10"}},[_c('span',{staticClass:"question_open__textarea question_open__textarea-total question_open__textarea-total-line"},[_vm._v("\n              "+_vm._s(question.text)+"\n              "),_c('span',{class:[
                  'custom-total-line',
                  { 'negative' : _vm.getCustomTotalLine(question) < 0 } ]},[_vm._v("\n                "+_vm._s(_vm.getCustomTotalLine(question))+"\n              ")])])])]:_vm._e(),_vm._v(" "),(_vm.getQuestionType(question.type) === _vm.WORK_BOOK_FIELD_TYPES.TITLE)?[_c('th',{staticClass:"number number__counter"}),_vm._v(" "),_c('th',{staticClass:"question_open__th question_open__th--content question_open__th--content-total question_open__th--content-title"},[_vm._v("\n            "+_vm._s(question.text)+"\n          ")]),_vm._v(" "),_vm._l((_vm.questionsBlock.fields),function(field,fieldIndex){return [_c('td',{key:("question_open_total_" + fieldIndex),staticClass:"question_open__td",style:(_vm.getCellStyle(fieldIndex))},[_c('span',{staticClass:"question_open__textarea question_open__textarea-total",style:(_vm.getInputStyle(fieldIndex))})])]})]:_vm._e()],2)}),0),_vm._v(" "),(!_vm.questionsBlock.hideTableFooter)?_c('tfoot',{style:(_vm.getTableFooterStyle())},[_c('tr',{staticClass:"question_open__row question_open__row--foot"},[_c('th',{staticClass:"number"}),_vm._v(" "),_c('th',{staticClass:"question_open__th question_open__th--total"},[_vm._v("\n          "+_vm._s(_vm.totalName)+"\n        ")]),_vm._v(" "),_vm._l((_vm.totalFieldsAllowed),function(item,itemIndex){return _c('td',{key:("question_open_counter_" + itemIndex),staticClass:"question_open__total-cell",class:_vm.getTotalItemClass(item)},[(_vm.totalFields.includes(item.slug))?_c('span',{class:[
              { 'negative' : _vm.totalCounter[item.slug] < 0 } ]},[_vm._v("\n            "+_vm._s(_vm.totalCounter[item.slug].toFixed())+"\n          ")]):_vm._e(),_vm._v(" "),(item.dollarTotal)?_c('span',{staticClass:"sign-dollar__total"},[_vm._v("$")]):_vm._e()])})],2)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }