//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import {WORK_BOOK, WORK_BOOK_TOP_LIST_KEYS} from "@/store/constants";

export default {
  name: "WorkBookTableTotalTopFive",
  components: {},
  props: {
    blockIndex: {
      type: Number,
      default: 0
    },
  },
  data: () => ({
    selectedSectionId: 2,
    selectedChapterId: 8,
    selectedBlockId: 1,
    currentFranchiseIndex: 0,
    totalCounter: {},
  }),
  watch: {
    workBooksStackCounter() {
      this.setTotalCounter()
    },
  },
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      workBooksStack: 'account/workbook/workBooksStack',
      workBooksStackCounter: 'account/workbook/workBooksStackCounter',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      topList: 'account/workbook/topList',
      franchise: 'account/workbook/franchise',
    }),
    questionsBlock() {
      const currentSection = WORK_BOOK.find(section => section.id === this.selectedSectionId)
      const currentChapter = currentSection.chapters.find(chapter => chapter.id === this.selectedChapterId)
      return currentChapter.questionsBlocks.find(block => block.id === this.selectedBlockId)
    },
    answersBlock() {
      const currentSectionIndex = WORK_BOOK.findIndex(section => section.id === this.selectedSectionId)
      const currentSection = WORK_BOOK.find(section => section.id === this.selectedSectionId)
      const currentChapterIndex = currentSection.chapters.findIndex(section => section.id === this.selectedChapterId)
      const currentChapter = currentSection.chapters.find(section => section.id === this.selectedChapterId)
      const currentBlockIndex = currentChapter.questionsBlocks.findIndex(block => block.id === this.selectedBlockId)

      return this.workBooks[this.currentWorkBookIndex].answers[currentSectionIndex].chapters[currentChapterIndex].questionsBlocks[currentBlockIndex].answers
    },
  },
  created() {
  },
  methods: {
    getFranchiseName(franchiseIndex) {
      const currentId = this.topList[WORK_BOOK_TOP_LIST_KEYS.FIVE][franchiseIndex]
      const currentFranchise = this.franchise.find(item => Number(item.id) === Number(currentId))
      if (typeof currentFranchise === "undefined")
        return `Franchise #${franchiseIndex + 1}`

      return currentFranchise.franchise
    },
    getTabTotal(franchiseIndex, tabIndex) {
      let count = 0
      const fieldWithScore = this.questionsBlock.fields.find((field) => {
        if (typeof field.isScore !== "undefined")
          return field.slug
        return false
      })
      const currentQuestions = this.questionsBlock.questions.filter(item => item.tabIndex === tabIndex)

      currentQuestions.forEach(question => {
        const answer = this.answersBlock.find(item => item.id === question.id)
        count += Number(answer.value[franchiseIndex][fieldWithScore.slug])
      })

      return count
    },
    getFranchiseTotal(franchiseIndex){
      let count = 0
      const fieldWithScore = this.questionsBlock.fields.find((field) => {
        if (typeof field.isScore !== "undefined")
          return field.slug
        return false
      })

      this.questionsBlock.questions.forEach(question => {
        const answer = this.answersBlock.find(item => item.id === question.id)
        count += Number(answer.value[franchiseIndex][fieldWithScore.slug])
      })

      return count
    },
    setTotalCounter() {
      const totalFields = {}
      let block = {}
      this.blockList.forEach(blockId => {
        block = this.workBooksStack[blockId]
        if (!block) {
          return
        }
        Object.entries(block).forEach(([fieldId, fieldVal]) => {
          if (!totalFields[fieldId]) {
            totalFields[fieldId] = 0
          }
          totalFields[fieldId] += Number(fieldVal) ?? 0
        })
      })
      this.totalCounter = totalFields
    },
    getFieldStyle(field) {
      return {
        minWidth: field.minWidth ?? `auto`
      }
    },
  }
}
