//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "WorkBookDisabledMessage",
  model:{
    prop:'accept',
    event: 'change'
  },
  props: {
    showMessage: {
      type: Boolean,
      default: false
    },
    accept:{
      type: Boolean,
      default: false
    },
  },
  methods:{
    handlerClickButton(){
      this.$emit('change', true)
    },
  }
}
