//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BlogPostsSidebarSocial from "~/components/blog/BlogPostsSidebarSocial"
import BlogPostsSidebarLast from "~/components/blog/BlogPostsSidebarLast"
import BlogPostsSidebarCategory from "~/components/blog/BlogPostsSidebarCategory"
import BlogPostsSidebarSearch from "~/components/blog/BlogPostsSidebarSearch"
import BlogPostsSubscribe from "~/components/blog/BlogPostsSubscribe";

export default {
  name: 'BlogPostsSidebar',
  components: {
    BlogPostsSubscribe,
    BlogPostsSidebarCategory,
    BlogPostsSidebarLast,
    BlogPostsSidebarSocial,
    BlogPostsSidebarSearch,
  },
  props: {
    postsLast: {
      type: Array,
      default: null,
    },
    categories: {
      type: Array,
      default: null,
    },
  }
};
