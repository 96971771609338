//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import landingStyles from "@/components/mixins/landingStyles";
import PhoneIcon from '@/assets/icons/PhoneIcon.svg?inline'
import HamburgerIcon from '@/assets/icons/HamburgerIcons.svg?inline'
import LandingMenu from "@/components/landing/LandingMenu";
import LandingLogo from "@/components/landing/elements/LandingLogo";
import {mapGetters} from "vuex";

export default {
  name: "LandingHeader",
  components: {
    LandingLogo,
    LandingMenu,
    PhoneIcon,
    HamburgerIcon,
  },
  mixins: [
    landingStyles,
  ],
  props: {
    phone: {
      type: Object,
      default: () => {
      }
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      openMobileMenu: false,
      windowWidth: 0,
      simpleMenu: false
    }
  },
  computed: {
    ...mapGetters({
      mainStyles: 'landing/main_styles',
      modalIds: 'landing/modal_ids',
    }),
  },
  created() {
    if (process.client) {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  destroyed() {
    if (process.client)
      window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    handleScroll() {
      this.simpleMenu = window.scrollY > 0
    },
    openModal(modalId) {
      if (modalId === '')
        return

      this.$bvModal.show(modalId)
    },
  }
}
