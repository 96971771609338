//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ShareButtons",
  props: {
    shareUrl: {
      type: String,
      default: 'https://franchise123.com/blog/',
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    toggleShowLinks() {
      this.isActive = !this.isActive
    }
  }
}
