//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters,
} from "vuex"
import BaseButton from "~/components/buttons/BaseButton"
import ActionsDateRangePicker from "~/components/account-franchisor/range/ActionsDateRangePicker"
import ContactActionNotesItem from "~/components/account-franchisor/contacts/actions/notes/ContactActionNotesItem"
import ContactActionNotesItemEdit from "~/components/account-franchisor/contacts/actions/notes/ContactActionNotesItemEdit"
import PaginationDefault from "~/components/paginations/PaginationDefault"

export default {
  name: 'ContactActionNotes',
  components: {
    BaseButton,
    ActionsDateRangePicker,
    ContactActionNotesItem,
    ContactActionNotesItemEdit,
    PaginationDefault,
  },
  props: {
    franchiseeId: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      note: {},
      showEdit: false,
      dateRange: {
        startDate: null,
        endDate: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      noteList: 'account-franchisor/actions/notes/noteList',
      noteListPaginated: 'account-franchisor/actions/notes/noteListPaginated',
      busy: 'account-franchisor/actions/notes/busy',
    }),
    newNote() {
      return {
        id: 0,
        franchisee_id: this.franchiseeId,
        note: ``,
      }
    },
  },
  watch: {
    dateRange() {
      this.fetchNotes()
    },
  },
  async mounted() {
    await this.fetchNotes()
    this.fixModal()
    this.updateAllChildElement('.note .content', '*', 'style', '')
  },
  methods: {
    ...mapActions({
      $fetchNoteList: 'account-franchisor/actions/notes/fetchNoteList',
      $addNote: 'account-franchisor/actions/notes/addNote',
      $updateNote: 'account-franchisor/actions/notes/updateNote',
      $deleteNote: 'account-franchisor/actions/notes/deleteNote',
      $pinNote: 'account-franchisor/actions/notes/pinNote',
      $unpinNote: 'account-franchisor/actions/notes/unpinNote',
    }),
    updateAllChildElement(parentClass, element, propertyName, propertyVal) {
      const links = document.querySelectorAll(`${parentClass} ${element}`)
      const len = links.length;
      for (let i = 0; i < len; i++) {
        links[i][propertyName] = propertyVal
      }
    },
    handleChangePage(page) {
      this.fetchNotes(page)
    },
    cancelEdit() {
      this.showEdit = false
    },
    editNote(note) {
      this.note = note
      this.showEdit = true
    },
    async saveNote(note) {
      this.showEdit = false

      if (!note.id) {
        await this.$addNote(note)
      } else {
        await this.$updateNote(note)
      }

      await this.fetchNotes()
    },
    async deleteNote(note) {
      await this.$deleteNote(note.id)
      await this.fetchNotes()
    },
    async pinNote(note) {
      await this.$pinNote(note.id)
      await this.fetchNotes()
    },
    async unpinNote(note) {
      await this.$unpinNote(note.id)
      await this.fetchNotes()
    },
    async fetchNotes(page = 1) {
      await this.$fetchNoteList({
        franchisee_id: this.franchiseeId,
        start_date: this.dateRange.startDate,
        end_date: this.dateRange.endDate,
        page,
      })
    },
    fixModal() {
      document.addEventListener('focusin', (event) => {
        if(event.target) {
          ['.tox-tinymce', '.tox-tinymce-aux', '.moxman-window', '.tam-assetmanager-root'].forEach(selector => {
            const el = document.querySelector(selector)
            if (el) {
              event.stopImmediatePropagation()
            }
          })
        }
      })
    },
  }
}
