//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DropDownLink',
  props: {
    isActive: {
      type: Boolean,
      default: () => false
    },
    className: {
      type: String,
      default: () => 'link'
    },
    link: {
      type: String,
      default: () => ''
    }
  }
}
