//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'vform'
import {mapGetters} from 'vuex'
import {COMPANY_PHONE_NUMBER, MASK_PHONE} from "@/store/constants"
import StringMask from "string-mask"
import VInputV2 from '~/components/form/VInputV2'
import VButton from '~/components/VButton'
import VCheckBoxV2 from '~/components/form/VCheckBoxV2'
import VTextAreaV2 from '~/components/form/VTextAreaV2'

export default {
  components: {
    VTextAreaV2,
    VCheckBoxV2,
    VButton,
    VInputV2,
  },
  props: {
    formClass: {
      type: String,
      default: '',
    },
    userType: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    status: '',
    message: '',
    form: new Form({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
      agree: false,
      busy: false,
    }),
    nonFranchisorFields: ['first_name', 'last_name', 'email', 'phone'],

  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
      isFooterContactForm: 'modals/contact/isFooterContactForm',
    }),
    getUrlPhone() {
      return `tel:${COMPANY_PHONE_NUMBER}`
    },
    getFormatedPhone() {
      return `CALL: ${(new StringMask(MASK_PHONE)).apply(COMPANY_PHONE_NUMBER)}`
    },
  },
  methods: {
    async submit() {
      if (this.authenticated && (this.user.isFranchisor || this.user.isFranchisee)) {
        // prepare form fields for franchisor
        this.nonFranchisorFields.forEach((value) => delete this.form[value])
      }
      try {
        const {data} = await this.form.post(`/api/v1/other/contact-form?fromFooter=${Number(this.isFooterContactForm)}`)
        this.setAlertFields(data.message, data.status)
        this.form.reset()
        this.$refs.contactForm.reset()
      } catch (e) {
        if (e.response.status === 400 && e.response.data.status === 'fail') {
          this.setAlertFields(e.response.data.message, e.response.data.status)
        } else if (e.response.status !== 422) {
          this.$emit('submitted')
        }
      }
    },
    setAlertFields(message = '', status = '') {
      this.message = message
      this.status = status
    },
  },
}
