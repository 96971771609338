//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from "vform";
import {mapActions, mapGetters} from "vuex";
import InputCustom from "@/components/form/customField/InputCustom";
import SelectCustom from "@/components/form/customField/SelectCustom";
import InputNumberCustom from "@/components/form/customField/InputNumberCustom";
import MultiSelectCustom from "@/components/form/customField/MultiSelectCustom";
import tabEditFranchise from "@/components/mixins/tabEditFranchise";
import InfoIcon from '@/assets/icons/InfoIcon.svg?inline';

export default {
  name: "FormEditFranchiseQuickFacts",
  components: {
    MultiSelectCustom,
    InputNumberCustom,
    SelectCustom,
    InputCustom,
    InfoIcon,
  },
  mixins: [
    tabEditFranchise
  ],
  data: () => ({
    selectedStates: [],
    form: new Form({
      franchise_name: '',
      established: '',
      investment_min: '',
      investment_max: '',
      franchise_fee_min: '',
      franchise_fee_max: '',
      minimum_credit_score: '',
      franchising_since: '',
      minimum_liquid_assets: '',
      naics: '',
      total_net_worth: '',
      average_revenue: '',
      gross_profit: '',
      number_of_locations: '',
      royalty_explanation: '',
      royalty_percent: '',
      website_url: '',
      industry: '',
      states: [],
    }),
    options: {
      established: '',
      franchisingSince: '',
    },
    selectId: 'franchising-since',
  }),
  computed: {
    ...mapGetters({
      franchise: 'business-v2/franchise',
      industryOptions: 'directories/industries',
      states: 'directories/states',
      franchiseId: 'registration/franchiseId',
    }),
    formAttributes() {
      return {
        franchise_name: this.franchise.business_name,
        established: this.franchise.established,
        investment_min: this.franchise.investment.low,
        investment_max: this.franchise.investment.high,
        franchise_fee_min: this.franchise.franchise_fee.low,
        franchise_fee_max: this.franchise.franchise_fee.high,
        minimum_credit_score: this.franchise.minimum_credit_score,
        franchising_since: this.franchise.franchising_since,
        minimum_liquid_assets: this.franchise.minimum_liquid_assets,
        naics: this.franchise.naics,
        total_net_worth: this.franchise.total_net_worth,
        average_revenue: this.franchise.average_revenue,
        gross_profit: this.franchise.gross_profit,
        number_of_locations: this.franchise.number_of_locations,
        royalty_explanation: this.franchise.main_royalty_fee,
        royalty_percent: this.franchise.main_royalty_fee_percent,
        website_url: this.franchise.public_website_business_permalink,
        industry: this.franchise.industry,
        states: this.franchise.states,
      }
    },
    statesOptions() {
      const states = this.states.map(({id, name, code}) => ({
        title: `${name}, ${code}`,
        value: id,
      }))

      return [{
        allStates: 'All States',
        components: states
      }]
    }
  },
  watch: {
    async franchise() {
      this.updateAttributes()
    },
  },
  async beforeMount() {
    this.options.established = this.generateRangeDate()
    this.options.franchisingSince = this.generateRangeDate(1925)

    await this.fetchStates()
    await this.fetchIndustries()
  },
  created() {
    this.updateAttributes()
  },
  methods: {
    ...mapActions({
      fetchIndustries: 'directories/fetchIndustries',
      fetchStates: 'directories/fetchStates',
      fetchFranchise: 'business-v2/fetchFranchise',
    }),
    updateAttributes() {
      const company = this.formAttributes;

      Object.keys(company).forEach(key => {

        if (key === 'states')
          return

        if (
          key === 'established' ||
          key === 'franchising_since'
        ) {
          this.form[key] = company[key] === '' || company[key] === null ? '' : company[key].substring(0, 4)
          return
        }

        this.form[key] = company[key] ?? ''
      })

      const selectedStates = []
      this.franchise.states.forEach(({code, name, id}) => {
        const findItem = selectedStates.filter(item => item.value === id)

        if (!findItem.length) {
          selectedStates.push({
            title: `${name}, ${code}`,
            value: id
          })
        }
      })

      this.selectedStates = selectedStates
    },
    async submit() {
      try {
        const url = `/api/v2/businesses/edit/${this.franchise.id}/${this.franchise.version_id}/facts`

        this.selectedStates.forEach(({value}) => {
          this.form.states.push(value)
        })
        
        this.form.established = Number(this.form.established);
        this.form.franchising_since = Number(this.form.franchising_since);

        await this.form.put(url)
        this.$emit('sendSuccess')
        await this.fetchFranchise({
          franchiseId: this.franchise.id,
          versionId: this.franchise.version_id,
        })
      } catch (e) {
        this.$emit('sendError')
        console.log(e);
      }
    },
    generateRangeDate(minYear = 1920) {
      const currentYear = new Date().getFullYear()
      const maxYear = currentYear + 1
      const ranges = maxYear - minYear

      return Array(ranges)
          .fill()
          .map((_, i) => {
            return {
              title: String(i + minYear),
              value: String(i + minYear)
            }
          })
    },
  }
}
