//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowTriangle from "@/assets/icons/arrows/ArrowTriangle.svg?inline";

export default {
  name: "InputNumberCustom",
  components: {
    ArrowTriangle
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    topLabel: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    value: {
      type: [Number, String],
      default: () => '',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    inputName: {
      type: String,
      default: () => '',
    },
    min: {
      type: [Number, String],
      default: () => 0,
    },
    max: {
      type: [Number, String],
      default: () => 1000000000000,
    },
    step: {
      type: [Number, String],
      default: () => 10,
    },
    showArrows: {
      type: Boolean,
      default: true,
    },
    showDollar: {
      type: Boolean,
      default: true,
    },
    compacted: {
      type: Boolean,
      default: false,
    },
    sign: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      focus: false,
      inputValue: null,
    };
  },
  computed: {
    tipId() {
      return `tip${Math.random().toString()}`;
    },
  },
  watch: {
    value(newVal) {
      if (newVal === null) {
        this.inputValue = null;
      }
    },
  },
  mounted() {
    if (this.value) {
      this.inputValue = Number(this.value);
    }
  },
  methods: {
    /**
     * Change input value
     * @param {String} direction
     */
    changeStep(direction) {
      const stepLength = parseInt(this.step, 10);
      this.inputValue = Number(this.inputValue);

      if (direction === 'up') {
        if (this.inputValue + stepLength >= this.max) {
          return;
        }
        this.inputValue += Number(stepLength);
      } else if (direction === 'down') {
        if (this.inputValue - stepLength <= this.min) {
          this.inputValue = null;
          this.$emit('input', this.inputValue);
          return;
        }
        this.inputValue -= Number(stepLength);
      }
      this.$emit('input', this.inputValue);
    },
    handleInput(e) {
      this.$emit('change')

      let value = e.target.value;

      if (value === '') {
        this.inputValue = null;
      } else {
        value = Number(value);
        if (
            (value > parseInt(this.min, 10) && value <= parseInt(this.max, 10)) ||
            value <= parseInt(this.max, 10)
        ) {
          this.$emit('input', value);
          return;
        }
        if (value >= parseInt(this.max, 10)) {
          this.inputValue = parseInt(this.max, 10);
          this.$emit('input', this.inputValue);
          return;
        }
      }
      this.$emit('input', this.inputValue);
    },
  },
}
