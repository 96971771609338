//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapState,
} from 'vuex'
import VCheckBox from "@/components/form/VCheckBox"
import Form from 'vform'
import SelectCustom from "@/components/form/customField/SelectCustom"
import InputCustom from "@/components/form/customField/InputCustom"
import InputNumberCustom from "@/components/form/customField/InputNumberCustom"
import TextAreaCustom from "@/components/form/customField/TextAreaCustom"
import SelectWithListCustom from "@/components/form/customField/SelectWithListCustom"
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import MultiSelectCustom from "@/components/form/customField/MultiSelectCustom"
import InfoIcon from "@/assets/icons/InfoIcon.svg?inline"

import {
  FRANCHISOR_FLOW_OPTIONS,
} from "~/store/constants"

export default {
  components: {
    VCheckBox,
    MultiSelectCustom,
    SelectWithListCustom,
    TextAreaCustom,
    InputNumberCustom,
    SelectCustom,
    InputCustom,
    InfoIcon,
  },
  props: {
    stepIndex: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      loading: true,
      claimed: false,
      lastClaimedFranchise: '',
      showArrows: true,
      compacted: false,
      investmentTip: 'Investment numbers based on 1 unit',
      mobileWindowWidth: 769,
      windowWidth: 0,
      form: new Form({
        franchise_name: '',
        established: '',
        investment_min: '',
        investment_max: '',
        franchise_fee_min: '',
        franchise_fee_max: '',
        minimum_credit_score: '',
        franchising_since: '',
        minimum_liquid_assets: '',
        naics: '',
        total_net_worth: '',
        average_revenue: '',
        gross_profit: '',
        number_of_locations: '',
        royalty_explanation: '',
        royalty_percent: '',
        website_url: '',
        industry: '',
        states: [],
        is_authorized_representative: false,
        associations: [],
      }),
      options: {
        established: [],
        franchisingSince: [],
        industry: [],
        states: [],
      },
      optionsGroup: [{
        allStates: 'All States',
        components: []
      }],
      statesGroup: [],
    }
  },
  computed: {
    ...mapState({
      industriesDirectory: state => state.directories.industries,
      statesDirectory: state => state.directories.states,
      franchiseId: state => state.registration.franchiseId,
      franchise: state => state.registration.franchise,
      option: state => state.registration.option,
      allowReclaim: state => state.registration.allow_reclaim,
    }),
    franchiseName() {
      return this.form.franchise_name
    },
  },
  watch: {
    windowWidth(newVal, oldVal) {
      if (process.client) {
        if (newVal > this.mobileWindowWidth){
          this.showArrows = true
          this.compacted = false
        } else {
          this.showArrows = false
          this.compacted = true
        }
      }
    },
    statesGroup(value){
      const states = []
      if (value.length) {
        value.forEach(item => {
            states.push(item.value)
        })
      }
      this.form.states = states
    },
    franchise (franchise) {
      if (Object.keys(franchise).length) {
        Object.keys(franchise).forEach(key => {
            if (key === 'states') {
              const states = []

              franchise[key].forEach(stateId => {
                const state = this.optionsGroup[0].components.find(item => item.value === stateId)
                states.push(state)
              })
              this.statesGroup = states
            }

            this.form[key] = franchise[key]
        })
      }
    },
    option (newVal, oldVal) {
      if (newVal !== null && oldVal !== null && newVal !== oldVal) {
        this.form.reset()
        this.$refs['brand-form'].reset()
        this.$refs['select-associations'].reset()
        this.statesGroup = []
      }
    },
    async franchiseName(newVal) {
      if (this.allowReclaim && this.lastClaimedFranchise !== newVal ) {
        await this.claim(newVal)
      }
    },
  },
  async beforeMount () {
    const minYear = 1925
    this.options.established = this.generateRangeDate()
    this.options.franchisingSince = this.generateRangeDate(minYear)

    await this.fetchStates()
    this.statesDirectory.forEach(el => {
      this.options.states .push({
        title: `${el.name}, ${el.code}`,
        value: el.id
      })
    })
    await this.fetchIndustries()
    this.options.industry = this.industriesDirectory
    this.optionsGroup[0].components = [ ...this.options.states ]
    this.loading = false
  },
  created() {
    if (process.client) {
      this.windowWidth = window.outerWidth
      window.addEventListener("resize", this.resizeEventHandler)
    }
  },
  async mounted() {
    if (this.franchiseId && this.option === FRANCHISOR_FLOW_OPTIONS.claim) {
      await this.fetchFranchise()
    }
  },
  methods: {
    ...mapActions({
      fetchIndustries: 'directories/fetchIndustries',
      fetchStates: 'directories/fetchStates',
      fetchFranchise: 'registration/fetchFranchise',
    }),
    async claim(title) {
      let claim = false
      let franchise

      try {
        const { data } = await this.$axios.get(`/api/v1/search?business_name=${title}`)
        if (data.total === 1) {
          franchise = this.mapFranchise(data)

          if (franchise.title === title) {
            try {
              claim = await this.confirmClaim(title)
              this.lastClaimedFranchise = title
            } catch (e) {
            }
          }
        }
      } catch (e) {
        console.log(e)
      }

      if(claim) {
        this.claimed = true
        await this.loadClaimedFranchise(franchise.id)
      }
    },
    async confirmClaim(title) {
      return await this.$confirm(
        `Do you want to claim ${title}?`,
        'Claim',
        'question',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          animation: true
        }
      )
    },
    async loadClaimedFranchise(id) {
      try {
        await this.$axios.post('/api/v1/business/flow/options', {
          option: FRANCHISOR_FLOW_OPTIONS.claim,
          franchise_id: id,
        })
      } catch (e) {
        console.log(e)
      }
      await this.$store.dispatch('registration/setFranchiseId', id)
      await this.$store.dispatch('registration/setOption', FRANCHISOR_FLOW_OPTIONS.claim)
      await this.fetchFranchise()
    },
    mapFranchise(data) {
      return {
        id: data.data[0].franchise_id,
        title: data.data[0].business_name.attribute_value_string,
      }
    },
    resizeEventHandler(e) {
      this.windowWidth = e.target.outerWidth
    },
    async submit () {
      try {
        this.form.associations = this.$refs['select-associations'].getAllAssociations()
        await this.form.post(`/api/v1/business/flow/brand`)

        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    generateRangeDate (minYear = 1920) {
      const currentYear = new Date().getFullYear()
      const maxYear = currentYear + 1
      const ranges = maxYear - minYear
      return Array(ranges)
        .fill()
        .map((_, i) => {
          return {
            title: String(i + minYear),
            value: String(i + minYear)
          }
        })
    }
  }
}
