//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters} from "vuex"
import CardIndustriesCobalt from "@/components/cards/CardIndustriesCobalt"
import CardUSMapCobalt from "@/components/cards/CardUSMapCobalt"
import CardInvestmentCobalt from "@/components/cards/CardInvestmentCobalt"
import {
  MODAL_SIGN_UP_FRANCHISE_ID,
  SIGN_UP_FRANCHISE_FAST_STEP_THREE, SIGN_UP_FRANCHISE_FAST_STEP_TWO,
  USER_PREFERENCES_TOTAL_AMOUNT_INVEST
} from "@/store/constants"
import Confetti from "@/components/Confetti"
import FddIcon from "@/components/icons/FddIcon"
import wordingMixin from "@/components/mixins/wordingMixin"

export default {
  name: "SignUpFranchiseStepThree",
  components: {
    Confetti,
    CardInvestmentCobalt,
    CardUSMapCobalt,
    CardIndustriesCobalt,
    FddIcon,
  },
  mixins: [
    wordingMixin,
  ],
  data: () => ({
    investmentDirectory: USER_PREFERENCES_TOTAL_AMOUNT_INVEST,
    isMobScreen: false,
    lgWidth: 1199.98,
    tabIndex: 0,
    steps: {
      total: 3,
    },
    industry: {
      collapseId: 'accordion-industry',
      maxSelected: 5,
      selected: 0,
      show: true,
      list: []
    },
    states: {
      collapseId: 'accordion-states',
      maxSelected: 3,
      selected: 0,
      show: false,
      list: []
    },
    invest: {
      collapseId: 'accordion-invest',
      maxSelected: 1,
      selected: 0,
      show: false,
      checked: {}
    },
    pageLink: '',
    timer: null
  }),
  computed: {
    ...mapGetters({
      preferencesIndustries: 'account/preferencesIndustries',
      preferencesStates: 'account/preferencesStates',
      preferencesInvestment: 'account/preferencesInvestment',
      franchiseTotalCounter: 'account/franchiseTotalCounter',
      selected_franchise: 'account/selectedFranchise',
      pagination: 'account/business/pagination',
      enableFlowBeginToday: 'registration/enableFlowBeginToday',
      show_fdd: 'account/showFdd',
      isAuth: 'auth/check',
    }),
    title() {
      return this.enableFlowBeginToday ? 'Define your preferences' : 'Congratulations!'
    },
    stepsToFinish() {
      let finishedSteps = 0
      if (this.preferencesIndustries.length > 0)
        finishedSteps += 1
      if (this.preferencesStates.length > 0)
        finishedSteps += 1
      if (this.investmentDirectory.filter(item => item.value === this.preferencesInvestment).length > 0)
        finishedSteps += 1
      return this.steps.total - finishedSteps
    },
    titleButtonIndustry() {
      const title = 'Industry'

      if (this.isMobScreen) {
        return title
      }

      if (!this.preferencesIndustries.length)
        return title

      return `${title} (${this.preferencesIndustries.length})`
    },
    titleButtonStates() {
      const title = 'States'

      if (this.isMobScreen) {
        return title
      }

      if (!this.preferencesStates.length)
        return title

      return `${title} (${this.preferencesStates.length})`
    },
    titleButtonInvest() {
      const title = 'Investment'

      if (this.isMobScreen) {
        return title
      }

      if (!this.investmentDirectory.filter(item => item.value === this.preferencesInvestment).length)
        return title

      return `${title} (1)`
    },
    titleButtonSend() {
      if (this.checkEmptyFilter())
        return this.enableFlowBeginToday ? 'Sign Up' : 'Find Your Franchise'

      const strEnd = this.enableFlowBeginToday ? 'Go' : 'Finish'

      return `${this.stepsToFinish} ${this.makePlural(this.stepsToFinish, 'Step', 'Steps')} to ${strEnd}`
    },
  },
  watch: {
    preferencesIndustries() {
      this.updateData()
    },
    preferencesStates() {
      this.updateData()
    },
    preferencesInvestment() {
      this.updateData()
    },
  },
  async created() {
    if (this.isAuth)
      await this.fetchPreferences()

    this.updateIsMob(window.outerWidth)
    window.addEventListener("resize", this.resizeEventHandler)
  },
  mounted() {
    this.checkCompiledAllSteps()
  },
  methods: {
    ...mapActions({
      savePreferences: 'account/savePreferences',
      fetchPreferences: 'account/fetchPreferences',
      fetchFranchises: 'account/fetchFranchises',
    }),
    updateData() {
      if (this.timer !== null)
        clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        if (this.isAuth)
          this.savePreferences()
        this.fetchFranchises(true)
        this.timer = null
      }, 1000)
    },
    async previewFDD() {
      this.$bvModal.hide(MODAL_SIGN_UP_FRANCHISE_ID)
      if (this.show_fdd) {
        await this.$store.dispatch('iframe/openModal', this.selected_franchise.itemUrl)
      }
    },
    resizeEventHandler(e) {
      this.updateIsMob(e.target.outerWidth)
    },
    updateIsMob(windowWidth) {
      this.isMobScreen = windowWidth < this.lgWidth
    },
    async checkCompiledAllSteps() {
      if (this.selected_franchise) {
        if (!this.show_fdd && !Object.keys(this.invest.checked).length) {
          await this.$store.commit('registration/SET_COMPANY_PAGE_URL', this.selected_franchise.itemUrl)
        }
      } else {
        await this.$store.commit('registration/SET_COMPANY_PAGE_URL', '/account/my-matches')
      }
    },
    checkEmptyFilter() {
      if (this.preferencesIndustries.length === 0)
        return false
      if (this.preferencesStates.length === 0)
        return false

      return this.investmentDirectory.filter(item => item.value === this.preferencesInvestment).length !== 0
    },
    handlerClickNextButton() {
      if (this.preferencesIndustries.length === 0) {
        this.$root.$emit('bv::toggle::collapse', this.industry.collapseId)
        return
      }

      if (this.preferencesStates.length === 0) {
        this.$root.$emit('bv::toggle::collapse', this.states.collapseId)
        return
      }

      if (this.investmentDirectory.filter(item => item.value === this.preferencesInvestment).length === 0) {
        this.$root.$emit('bv::toggle::collapse', this.invest.collapseId)
        return
      }

      if (this.enableFlowBeginToday) {
        this.$store.commit('registration/SET_REGISTRATION_FRANCHISEE_STAGE', SIGN_UP_FRANCHISE_FAST_STEP_TWO)
        return
      }


      this.$bvModal.hide(MODAL_SIGN_UP_FRANCHISE_ID)
      this.$router.push('/account/my-matches')
    },
  }
}
