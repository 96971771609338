//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { cloneDeep } from "lodash/fp";
import { mapState } from 'vuex';
import { ASSOCIATIONS_AND_PARTNERSHIPS } from '~/store/constants'
import AssociationIcon from '~/components/icons/AssociationIcon';
import VInput from '~/components/form/VInput'
import VSelect from '~/components/form/VSelect';

export default {
  components: {
    AssociationIcon,
    VSelect,
    VInput
  },
  props: {
    wrapperClass: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selected: null,
    selectedAssociations: [],
    userAssociations: [],
    associations: [],
  }),
  computed: {
    ...mapState({
      franchiseId: state => state.registration.franchiseId,
    }),
    disabled () {
      return this.associations.length === 0
    },
    showAddNewBtn () {
      const length = this.userAssociations.length;
      if (length === 0) {
        return true
      } if (length === 3) {
        return false
      }

      let show = true

      this.userAssociations.forEach((item) => {
        if (item === null || item === '') {
          show = false
        }
      })

      return show
    }
  },
  mounted() {
    this.associations = cloneDeep(ASSOCIATIONS_AND_PARTNERSHIPS)
  },
  methods: {
    selectItem (value) {
      const selectedOptionIndex = this.associations.findIndex((el, index) => el.value === value)

      setTimeout(() => {
        this.selectedAssociations.push(this.associations[selectedOptionIndex])
        this.associations.splice(selectedOptionIndex, 1)
        this.selected = null
      }, 400)
    },
    deleteItem (idx) {
      this.associations.push(this.selectedAssociations[idx])
      this.selectedAssociations.splice(idx, 1)
    },
    addUserItem () {
      this.userAssociations.push('')
    },
    deleteUserItem (idx) {
      this.userAssociations.splice(idx, 1)
    },
    getAllAssociations() {
      const selectedAssociations = this.selectedAssociations.map(item => {
        return item.value
      })

      return [ ...selectedAssociations, ...this.userAssociations ]
    },
    reset() {
      this.userAssociations = []
      this.selectedAssociations = []
      this.associations = cloneDeep(ASSOCIATIONS_AND_PARTNERSHIPS)
    }
  }
}
