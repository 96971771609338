//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseModal from "./BaseModal";
import BaseButton from "../buttons/BaseButton";
import {mapGetters} from "vuex";

export default {
  components: {BaseButton, BaseModal},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    plan: {
      type: String,
      default: null
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
    }),
  },
  methods: {
    closeModal(action) {
      this.$emit('close', action);
    },
  }
};
