//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex"
import ModalDocumentsList from '~/components/modals/ModalDocumentsList'
import StatusDot from '~/components/icons/StatusDot'
import DocumentsIcon from '~/assets/images/svg/documents.svg?inline'
import storeMixin from "~/components/mixins/storeMixin"

export default {
  components: {
    ModalDocumentsList,
    StatusDot,
    DocumentsIcon
  },
  mixins: [
    storeMixin
  ],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      documentsLink: 'Upload docs',
      showDocumentsModal: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      versionApproved: 'account-franchisor/versionApproved',
      franchiseId: 'account-franchisor/franchiseId',
      paymentPermissions: 'payment/permissions',
    }),
    isAccessAllowed() {
      return this.user.isMasterAdmin && this.versionApproved ||
        (this.versionApproved && this.user.isFranchisor && this.paymentPermissions.chat.access)
    },
  },
  methods: {
    validateAccess(value) {
      return this.isAccessAllowed ? value : 0
    },
    /**
     * Click event documents
     */
    async handleClickDocuments() {
      await this.$refs['document-list-modal'].fetchDocuments()
      this.showDocumentsModal = true
    },
    showMessage() {
      let message = 'Access Denied'

      if (!this.versionApproved) {
        message = this.$t('accountFranchisor.accessMessages.notApprovedFranchisor')

        if (this.user.isMasterAdmin) {
          message = this.$t('accountFranchisor.accessMessages.notApprovedVersion')
        }
      } else if (this.user.isFranchisor && !this.paymentPermissions.chat.access) {
        message = this.paymentPermissions.chat.message
      }

      this.fireModalMessage({ message})
    }
  },
};
