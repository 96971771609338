//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AccountStrengthStar from "@/components/icons/AccountStrengthStar";
import {mapGetters} from 'vuex'

export default {
  name: 'CardProfileStrength',
  components: {AccountStrengthStar},
  data: () => ({
    firstStep: 0,
    secondStep: 0,
    thirdStep: 0,
    strengthLevel: '',
    fields: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      location: '',
      linked_in_link: '',
      short_descr: '',
      countAnswers: 0
    }
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      questionnaireFinished: 'account/question/finished'
    })
  },
  watch: {
    user(newVal) {
      this.updateFields()
      this.updateSteps()
    },
    questionnaireFinished(newVal) {
      this.updateSteps()
    }
  },
  created() {
    this.updateFields()
    this.updateSteps()
  },
  methods: {
    updateFields() {
      Object.keys(this.fields).forEach(key => {
        this.fields[key] = this.user[key]
      })
    },
    updateSteps() {
      let stat = 0

      if (this.fields.first_name && this.fields.last_name && this.fields.email) {
        stat += 15
      }

      if (this.fields.phone) {
        stat += 15
      }

      if (this.fields.linked_in_link) {
        stat += 20
      }

      if (this.fields.location) {
        stat += 15
      }

      if (this.fields.short_descr) {
        stat += 15
      }

      if (this.questionnaireFinished) {
        stat += 20
      }

      if (stat > 0 && stat <= 33) {
        this.firstStep = stat
        this.secondStep = 0
        this.thirdStep = 0
        this.strengthLevel = 'Weak'
      } else if (stat > 33 && stat <= 66) {
        this.firstStep = 33
        this.strengthLevel = 'Intermediate'
        stat -= 33
        this.secondStep = stat
        this.thirdStep = 0
      } else {
        this.firstStep = 33
        this.secondStep = 33
        stat -= 66
        this.thirdStep = stat
        this.strengthLevel = stat >= 16.5 ? 'All-Star' : 'Intermediate'
      }
    }
  }
}
