//
//
//
//
//
//
//
//
//
//
//

import BackIcon from "@/assets/images/icons/back-icon.svg?inline"

export default {
  components: {
    BackIcon,
  },
  props: {
    url: {
      default: '',
    },
    searchPage: {
      type: String,
      default: '',
    }
  },
  computed: {
    backUrl() {
      return this.url ? this.url : `/blog/`
    },
  },
  methods: {
    async go() {
      if (!this.searchPage) {
        await this.$router.push(this.backUrl)
        await this.$store.commit("blog/BLOG_SET_SEARCH", this.keywords)
        await this.$store.dispatch('blog/fetchArticlesList')
      } else {
        document.location.href = this.backUrl
      }
    },
  }
}
