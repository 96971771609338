//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AccountFranchisorDesktopMenu from "@/components/account-franchisor/menu/AccountFranchisorDesktopMenu"
import AccountFranchisorMobileMenu from "@/components/account-franchisor/menu/AccountFranchisorMobileMenu"
import ModalEditFranchise from "@/components/modals/franchisor/ModalEditFranchise"

export default {
  components: {
    AccountFranchisorDesktopMenu,
    AccountFranchisorMobileMenu,
    ModalEditFranchise
  },
  props: {
    contactsLayout: {
      type: Boolean,
      default: false,
    }
  },
}
