//
//
//
//
//
//
//
//
//
//
//

import StatusDot from "@/components/icons/StatusDot";
export default {
  name: "ModalFranchiseNotifications",
  components: {StatusDot},
  props: {
    addClass: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: 'Franchise has been added'
    }
  },
}

