//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {cloneDeep} from 'lodash/fp';
import {WORK_BOOK, WORK_BOOK_TOP_LIST_KEYS} from "@/store/constants";
import {mapGetters} from "vuex";

export default {
  name: "WorkBookChapterScoreFull",
  props: {
    numberTop: {
      type: Number,
      default: 0
    },
  },
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      topList: 'account/workbook/topList',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
    }),
    questionsBlock() {
      return WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex]
    },
    listCountedBlock() {
      const currentChapter = cloneDeep(WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex])
      return currentChapter.questionsBlocks.filter((block) => block.fields.findIndex(field => typeof field.isScore !== "undefined") !== -1)
    },
  },
  methods:{
    getTotalInChapter(blockIndex, franchiseIndex){
      const slug = this.listCountedBlock[blockIndex].fields.find(item => item.isScore === true).slug

      let sum = 0

      this.currentChapterAnswers.questionsBlocks[blockIndex].answers.forEach(answers => {
         sum += Number(answers.value[franchiseIndex][slug])
      })

      return sum
    },
    getTabName(block, blockIndex) {
      return block.tabName ?? `Block ${blockIndex + 1}`
    },
    getFranchiseName(franchiseIndex) {
      let listIndex = WORK_BOOK_TOP_LIST_KEYS.TEN

      if (this.numberTop === 5)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.FIVE

      if (this.numberTop === 3)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.THREE

      if (this.numberTop === 2)
        listIndex = WORK_BOOK_TOP_LIST_KEYS.TWO

      if (typeof this.topList[listIndex] === "undefined")
        return ''

      return this.topList[listIndex][franchiseIndex] ? this.topList[listIndex][franchiseIndex].franchise : `Franchise #${franchiseIndex + 1}`
    },
  }
}
