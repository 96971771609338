import { render, staticRenderFns } from "./AccountFranchisorStepper.vue?vue&type=template&id=4c7a55a0&scoped=true&"
import script from "./AccountFranchisorStepper.vue?vue&type=script&lang=js&"
export * from "./AccountFranchisorStepper.vue?vue&type=script&lang=js&"
import style0 from "./AccountFranchisorStepper.vue?vue&type=style&index=0&id=4c7a55a0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c7a55a0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccountFranchisorStepCard: require('/root/repo/components/account-franchisor/cards/AccountFranchisorStepCard.vue').default})
