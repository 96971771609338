//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapState
} from 'vuex'
import {
  FormWizard,
  TabContent,
} from 'vue-form-wizard'
import CustomWizardStep from './StepperComponents/CustomWizardStep'
import FormBrand from './StepperComponents/FormBrand'
import FormUpload from './StepperComponents/FormUpload'
import FormOptions from './StepperComponents/FormOptions'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VButton from "@/components/VButton"

import {
  FRANCHISOR_FLOW_OPTIONS,
} from "@/store/constants"

import hubspotMixin from "@/components/mixins/hubspotMixin"
import googleAnalyticsMixin from "@/components/mixins/googleAnalyticsMixin"

export default {
  components: {
    VButton,
    FormOptions,
    CustomWizardStep,
    FormBrand,
    FormUpload,
    FormWizard,
    TabContent,
  },
  mixins: [
    hubspotMixin,
    googleAnalyticsMixin,
  ],
  data: () => ({
    showAlert: false,
    isLoadingBtn: false,
    isMobile: window.innerWidth <= 991,
    activeStepIndex: 0,
    activeStepRef: 'form-options',
    nextStepDisabled: true,
    countSteps: 3,
    steps: [
      'form-options',
      'form-brand',
      'form-upload',
    ],
  }),
  computed: {
    ...mapState({
      option: state => state.registration.option,
      franchiseId: state => state.registration.franchiseId,
      franchise: state => state.registration.franchise,
    }),
    startIndex() {
      const selectFranchiseStep = 0
      const describeFranchiseStep = 1
      return this.franchiseId > 0 ? describeFranchiseStep : selectFranchiseStep
    }
  },
  async mounted() {
    await this.gaSendFranchisorSignUpForm()
    this.activeStepIndex = this.startIndex
    this.activeStepRef = this.steps[this.activeStepIndex]

    window.onpopstate = () => {
      this.showAlert = true
    }
    this.steps.forEach((item) => {
      this.$watch(
          () => {
            return this.$refs[item].form !== undefined ? this.$refs[item].form.busy : null
          },
          (value) => {
            this.isLoadingBtn = value
          }
      )
    })

    this.$watch(
        () => {
          return (
              this.activeStepRef === 'form-options' &&
              this.$refs[this.activeStepRef].form !== undefined &&
              this.$refs[this.activeStepRef].form.option === null
          )
        },
        (value) => {
          this.nextStepDisabled = value
        }
    )

    window.addEventListener('beforeunload', this.closeModal)
  },
  async beforeDestroy() {
    const lastStep = this.countSteps
    const step = this.activeStepIndex + 1
    await this.hsSignUpFormFranchisor(step)
    if (step === lastStep) {
      await this.gaSendFranchisorSignUpFormFull()
    }

    window.removeEventListener('beforeunload', this.closeModal)
  },
  methods: {
    scrollToTop() {
      if (process.client) {
        const objDiv = document.getElementsByClassName('modal-body-step-wrapper')[0]
        objDiv.scrollTop = 0
      }
    },
    onChangeStep(prevIndex, nextIndex) {
      this.activeStepIndex = nextIndex
      this.activeStepRef = this.steps.find((item, index) => index === nextIndex)
    },
    async nextStep() {
      if (this.activeStepIndex < this.countSteps) {
        const submit = await this.$refs[this.activeStepRef].submit()

        if (submit) {
          this.$refs.stepper.nextTab()
          this.scrollToTop()
        }
      }
    },
    prevStep() {
      this.activeStepIndex -= 1
      if (this.activeStepIndex < 0) {
        this.showAlert = true
      } else {
        this.$refs.stepper.prevTab()
      }
    },
    closeModal() {
      this.showAlert = false
      this.$axios.get('/api/v1/business/flow/interrupt')
      this.$emit('close')
    },
  },
};
