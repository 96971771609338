//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'WizardStep',
  props: {
    tab: {
      type: Object,
      default: () => {
      }
    },
    transition: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    iconActiveStyle () {
      return {
        backgroundColor: this.tab.color
      }
    },
    stepCheckedStyle () {
      return {
        borderColor: this.tab.color
      }
    },
    errorStyle () {
      return {
        borderColor: this.tab.errorColor,
        backgroundColor: this.tab.errorBorderColor
      }
    },
    stepTitleStyle () {
      let isError = this.tab.validationError
      return {
        color: isError ? this.tab.errorColor : this.tab.color
      }
    },
    isStepSquare () {
      return this.tab.shape === 'square'
    },
    isTabShape () {
      return this.tab.shape === 'tab'
    }
  }
}
