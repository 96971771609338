//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {MODAL_SIGN_UP_FRANCHISE_ID, SIGN_UP_FRANCHISE_FAST_STEP_THREE} from "@/store/constants"
import {mapGetters} from "vuex"
import Confetti from "@/components/Confetti"

export default {
  name: "SignUpFranchiseStepCongrats",
  components: {Confetti},
  computed: {
    ...mapGetters({
      franchiseTotalCounter: 'account/franchiseTotalCounter',
      enableFlowPendingList: 'registration/enableFlowPendingList',
    }),
    getText() {
      return this.enableFlowPendingList
        ? 'You have created your account!'
        : `You’ve matched with ${this.franchiseTotalCounter} brands`
    },
    getButtonText(){
      return this.enableFlowPendingList
        ? 'Close'
        : 'See Those Brands'
    },
  },
  methods: {
    changeStep() {
      if (this.enableFlowPendingList) {
        this.$bvModal.hide(MODAL_SIGN_UP_FRANCHISE_ID)
        return
      }
      this.$store.commit('registration/SET_REGISTRATION_FRANCHISEE_STAGE', SIGN_UP_FRANCHISE_FAST_STEP_THREE)
    },
  }
}
