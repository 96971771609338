//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from "vform"
import {mapGetters} from "vuex"
import creditCard from "@/components/payment/creditCard"

import {
  PAYMENT_MEMBERSHIP_LIST,
} from "@/store/constants"
import provideDetails from "~/components/mixins/providePaymentMixin"

export default {
  components: {
    creditCard,
  },
  mixins: [
    provideDetails,
  ],
  props: {
    paymentUser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showCreditCard: true,
      formSend: new Form({
        firstname: null,
        lastname: null,
        email: null,
        franchisor_subscription: null,
        page_uri: null,
      }),
      PAYMENT_MEMBERSHIP_LIST,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
    }),
  },
  methods: {
    toggleShowCard() {
      this.showCreditCard = !this.showCreditCard
    },
    verified() {
      return this.paymentUser.email_verify && this.paymentUser.signed_contract
    },
    currentStatus() {
      return !this.paymentUser.signed_contract ? 'todo' : 'done'
    },
  }
}
