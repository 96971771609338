//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import landingStyles from "@/components/mixins/landingStyles";
import BlankBlock from "@/components/landing/blank/BlankBlock";
import {directive, Swiper, SwiperSlide} from 'vue-awesome-swiper'
import LandingVideo from "@/components/landing/elements/LandingVideo";
import SectionTools from "@/components/landing/elements/SectionTools";
import LandingText from "@/components/landing/elements/LandingText";

export default {
  name: "LandingSectionPlayList",
  components: {
    LandingText,
    SectionTools,
    LandingVideo,
    BlankBlock,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive
  },
  mixins: [
    landingStyles
  ],
  props: {
    section: {
      type: Object,
      default: () => {
      }
    },
    editable: {
      type: Boolean,
      default: false,
    },
    currentIndex: {
      type: Number,
      default: 0
    },
  },
  data: () => {
    return {
      screenWidth: 0,
      showButtonNext: false,
      showButtonPrev: false,
      showTools: false,
      containerSize: {
        width: 0,
        height: 0
      },
    }
  },
  computed: {
    swiperOption() {
      return {
        init: false,
        updateOnWindowResize: true,
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        breakpoints: {
          '992': {
            slidesPerView: 2,
          },
          '1200': {
            slidesPerView: 3,
          },
        }
      }
    },
    backgroundImage() {
      const imageId = this.section.styles.backgroundImage ?? ''

      return (imageId === '') ? '' : `${process.env.BACKEND_BASE_URL}/api/v2/landing/${imageId}/image`
    },
  },
  mounted() {
    this.$refs.videoSlider.$swiper.init()
    if (this.editable)
      this.updateSectionSizes()
  },
  methods: {
    updateSectionSizes() {
      this.containerSize.width = this.$refs[`container_${this.section.id}`].clientWidth
      this.containerSize.height = this.$refs[`section_${this.section.id}`].clientHeight
    },
    updateSliderButton(swiper) {
      if (!this.editable) {
        this.showButtonNext = true
      }
      this.onSlideChange(swiper)
    },
    onSlideChange(swiper) {
      this.showButtonNext = !swiper.isEnd
      this.showButtonPrev = !swiper.isBeginning
    },
    changeSlide(prev = false) {
      if (prev)
        this.$refs.videoSlider.$swiper.slidePrev()
      else
        this.$refs.videoSlider.$swiper.slideNext()
    },
    updateSection() {
      this.updateSectionSizes()
      this.$store.dispatch('landing/updateSection', {section: this.section, index: this.currentIndex})
    },
  },
}
