//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InputNumberCustom from "@/components/form/customField/InputNumberCustom";
import helpers from "@/components/mixins/helpers";
import cloneDeep from 'lodash/cloneDeep';
import {WORK_BOOK} from "@/store/constants";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "WorkBookNetWorthCalculator",
  components: {InputNumberCustom},
  mixins:[
    helpers,
  ],
  props: {
    blockIndex: {
      type: Number,
      default: 0
    },
  },
  computed: {
    ...mapGetters({
      workBooks: 'account/workbook/workBooks',
      currentChapterAnswers: 'account/workbook/currentChapterAnswers',
      currentWorkBookIndex: 'account/workbook/currentWorkBookIndex',
      currentSectionIndex: 'account/workbook/currentSectionIndex',
      currentChapterIndex: 'account/workbook/currentChapterIndex',
    }),
    questionsBlock() {
      return WORK_BOOK[this.currentSectionIndex].chapters[this.currentChapterIndex].questionsBlocks[this.blockIndex]
    },
  },
  methods: {
    ...mapActions({
      changeAnswer: 'account/workbook/changeAnswer',
    }),
    getTotal(fieldIndex) {
      let sum = 0
      this.questionsBlock.questions.forEach((question, questionsIndex) => {
        if (question.fieldIndex === fieldIndex)
          sum += Number(this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers[questionsIndex].value)
      })
      return sum
    },
    getTotalString(fieldIndex) {
      const sum = this.getTotal(fieldIndex)
      return `$${sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    },
    getNetWorthValue() {
      const firstValue = this.getTotal(0)
      const secondValue = this.getTotal(1)

      if (firstValue === 0  || secondValue === 0)
        return 'Value'

      const value = firstValue - secondValue
      return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    },
    getValue(questionId) {
      const savedValue = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId)

      return savedValue.value
    },
    handlerUpdateValue(questionId, value) {
      const savedValue = cloneDeep(this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.find(item => item.id === questionId))
      const questionIndex = this.currentChapterAnswers.questionsBlocks[this.blockIndex].answers.findIndex(item => item.id === questionId)
      savedValue.value = value
      this.changeAnswer({blockIndex: this.blockIndex, questionIndex, value: savedValue.value})
    },
  }
}
