var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vSelect',_vm._b({class:[
    'main-select',
    {error: _vm.error},
    { bordered: _vm.bordered },
    { withTopLabel: _vm.withTopLabel },
    { compacted: _vm.compacted },
    { 'label-class': _vm.labelClass },
    { 'search-franchises-item' : _vm.searchFranchises },
    { 'contact-filter-item' : _vm.contactFilterItem },
    { multiple: _vm.multiple },
    { 'multiple-checked' : _vm.multipleChecked },
    { 'active-step' : _vm.activeStep } ],attrs:{"id":_vm.id === 'franchising-since' ? 'franchising-since':'',"placeholder":_vm.placeholder,"append-to-body":_vm.isAppendToBody,"reduce":_vm.reduceOptionFunction,"options":_vm.options,"value":_vm.value,"label":_vm.optionsLabel,"clearable":_vm.clearable,"components":{ OpenIndicator: _vm.OpenIndicator, Deselect: _vm.Deselect },"searchable":_vm.searchable,"calculate-position":_vm.withPopper,"no-drop":_vm.noDrop,"disabled":_vm.disabled,"multiple":_vm.multiple},on:{"input":function($event){return _vm.$emit('input', $event)},"search":function($event){return _vm.$emit('search', $event)}},scopedSlots:_vm._u([(_vm.withTopLabel)?{key:"header",fn:function(){return [_c('div',{staticClass:"top-label",class:[
        { 'franchising-since' : _vm.id === 'franchising-since' } ]},[_vm._v("\n      "+_vm._s(_vm.placeholder)+"\n    ")])]},proxy:true}:null,(_vm.multiple)?{key:"selected-option",fn:function(ref){
      var title = ref.title;
return [_vm._v("\n    "+_vm._s(_vm.optionsCounter)+"  options selected\n  ")]}}:null],null,true)},'vSelect',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }