//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name:'ViewSwitcherNew',
  props: {
    switchState: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleSwitch (switchState) {
      this.$emit('change', switchState)
    }
  }

}
