//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StarIcon from "@/assets/icons/StarIcon.svg?inline";
import StarIconComponent from "@/components/icons/StarIcon";

export default {
  name: "RatingStars",
  components: {StarIcon, StarIconComponent},
  model:{
    event:'modified',
    props:'rating'
  },
  props: {
    rating: {
      type: Number,
      default: 0
    },
    maxValue: {
      type: Number,
      default: 5
    },
    size: {
      type: String,
      default: '20'
    },
    defaultColor:{
      type: String,
      default: '#9FDBC2'
    },
    editMode:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      currentRating: this.rating,
      selectedRating: 0,
    }
  },
  computed: {
    getSizeInPx() {
      return `${this.size}px`;
    },
  },
  watch:{
    value(newVal){
      this.currentRating = newVal
    },
    rating(newVal){
      this.currentRating = newVal
    },
  },
  methods:{
    getPercentStar(num){
      if (num <= this.currentRating )
        return 100


      if (num > this.currentRating && num -1 < this.currentRating)
        return Math.round((this.currentRating % 1)*100)

      return 0
    },
    getIconFill(num){
      if (this.selectedRating >= num && this.editMode)
        return '#00A769'
      return num <= Math.ceil(this.currentRating)? '#00A769': this.defaultColor
    },
    setSelectedRating(num){
      if(this.editMode)
        this.selectedRating = num
    },
    clearSelectedRating(){
      this.selectedRating = 0;
    },
    finishSelectedRating(num){
      this.$emit('change')
      if(!this.editMode)
        return
      this.currentRating = num;
      this.$emit("modified", num);
    }
  }

}
