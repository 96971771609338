//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vSelect from 'vue-select';
import { createPopper } from '@popperjs/core';
import Arrow from '../icons/Arrow';
import Deselect from '../icons/Deselect';

export default {
  components: {
    vSelect,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    placeholder: {
      type: String,
      default: 'Select...',
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionsLabel: {
      type: String,
      default: () => 'title',
    },
    value: {
      type: [Number, String, Array, Object],
      default: () => '',
    },
    searchable: {
      type: Boolean,
      default: () => true,
    },
    bordered: {
      type: Boolean,
      default: () => false,
    },
    compacted: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: () => 'title',
    },
    labelClass: {
      type: String,
      default:'',
    },
    withTopLabel: {
      type: Boolean,
      default: () => false,
    },
    reduceOptionFunction: {
      type: [String, Number, Function],
      default: () => (option) => option.value,
    },
    noDrop: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    withCustomSelectedLabel: {
      type: Boolean,
      default: false
    },
    searchFranchises: {
      type: Boolean,
      default: false,
    },
    contactFilterItem: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    activeStep: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      OpenIndicator: {
        render: (createElement) => createElement(Arrow),
      },
      Deselect: {
        render: (createElement) => createElement(Deselect),
      },
      placement: 'bottom',
      id:'',
    };
  },
  computed: {
    multipleChecked() {
      return !!this.optionsCounter;
    },
    optionsCounter() {
      if (!this.value) {
        return 0;
      }
      return this.value.length;
    },
    inputListeners() {
      const vm = this;
      // `Object.assign` merges objects together to form a new object
      return {
        // We add all the listeners from the parent
        ...this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        // This ensures that the component works with v-model
        input(event) {
          vm.$emit('input', event);
        },
      };
    },
    isAppendToBody() {
      return !this.searchFranchises;
    }
  },
  created() {
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width;

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'bottom');
            },
          },
        ],
      });

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy();
    }
  },
};
