//
//
//
//
//
//
//

export default {
  name: "LikeIconV3",
  props: {
    active: {
      type: [Boolean, Number],
      default: () => false
    }
  },
  computed: {
    color() {
      return this.active ? '#00A769' : '#9FDBC2';
    }
  }
}
