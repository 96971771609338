//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Loader",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    overlayColor: {
      type: String,
      default: '#f8f8fa',
    },
    color:{
      type: String,
      default: '#00a769'
    },
    height: {
      type: String,
      default: 'max-content',
    },
    animationSize:{
      type: String,
      default: '200px'
    },
    top:{
      type: Boolean,
      default: false,
    },
  },
  methods:{
    loadingStyles(){
      let styles = {};

      if (this.active)
        styles = {
          minHeight: this.height,
          maxHeight: this.height,
          overflow: 'hidden'
        }

      return styles
    }
  }
}
