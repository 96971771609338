//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    messageText: {
      type: String,
      default: ''
    },
    messageFile: {
      type: Object,
      default: () => null
    },
    messageDate: {
      type: String,
      required: true,
    },
    me: {
      type: Boolean,
      default: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formatDate() {
      return this.$moment(this.messageDate).format('MM-DD-yyyy hh:mm A');
    },
  },
  methods: {
    handleClickChatFile() {
      this.$emit('click-chat-file', this.messageFile)
    }
  }
};
