export default {
  methods: {
    /**
     * Horizontal scroll smooth effect
     *
     * @param {Element} element
     * @param {string} direction
     * @param {number} speed
     * @param {number} distance
     * @param {number} step
     */
    smoothHorizontalScroll(element, direction, speed, distance, step) {
      let scrollAmount = 0;
      const slideTimer = setInterval(() => {
        if (direction === 'left') {
          // eslint-disable-next-line no-param-reassign
          element.scrollLeft -= step;
        } else if (direction === 'right') {
          // eslint-disable-next-line no-param-reassign
          element.scrollLeft += step;
        } else {
          throw new Error('set direction left or right');
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
      }, speed);
    },
  },
};
