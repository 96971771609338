//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import helpers from "@/components/mixins/helpers"
import {mapActions, mapGetters} from "vuex"
import {Swiper as SwiperClass, Pagination, Mousewheel, Autoplay, Lazy} from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import 'swiper/swiper.min.css'

SwiperClass.use([Pagination, Mousewheel, Autoplay, Lazy])
getAwesomeSwiper(SwiperClass)
const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass)

export default {
  name: "FranchisorWelcomeBanner",
  components: {
    Swiper,
    SwiperSlide,
  },
  mixins: [
    helpers
  ],
  data: () => ({
    sliderModules: [
      Autoplay
    ],
    sliderOptions: {
      init: false,
      updateOnWindowResize: true,
      slidesPerView: 1,
      spaceBetween: 18,
      loop: true,
      effect: 'fade',
      lazy: true,
      preloadImages: false,
      watchSlidesVisibility: true,
      autoplay: {
        delay: 7500,
        reverseDirection: true,
        disableOnInteraction: false,
      },
      breakpoints: {
        '576': {
          slidesPerView: 1,
          updateOnWindowResize: true,
          spaceBetween: 18,
        },
        '768': {
          slidesPerView: 2,
          updateOnWindowResize: true,
          spaceBetween: 18,
        },
        '992': {
          slidesPerView: 3,
          updateOnWindowResize: true,
          spaceBetween: 18,
        },
        '1310': {
          slidesPerView: 3,
          updateOnWindowResize: true,
          spaceBetween: 18,
        },
        '1400': {
          slidesPerView: 4,
          updateOnWindowResize: true,
          spaceBetween: 18,
        },
      }
    },
  }),
  computed: {
    ...mapGetters({
      franchiseArr: 'business-v2/newFranchises'
    }),
    swiperSlider() {
      return this.$refs.swiperBannerSlider.$swiper
    },
  },
  watch: {
    franchiseArr(newVal) {
      if (newVal.length > 0) {
        this.swiperSlider.init()
        this.swiperSlider.on('orientationchange', this.handlerOrientationChange)
      }
    }
  },
  mounted() {
    this.fetchFranchises()
    this.$echo
        .channel('business-banner')
        .listen('.business.banner', (response) => {
          this.fetchFranchises()
        })
  },
  methods: {
    ...mapActions({
      fetchFranchises: 'business-v2/fetchNewFranchises'
    }),
    handlerOrientationChange(swiper) {
      setTimeout(() => {
        swiper.updateSlides()
        swiper.update()
      }, 500)
    },
  },
}
