//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowCodeBolderIcon from '~/assets/icons/arrows/ArrowCodeBolderIcon.svg?inline'

export default {
  name: "WorkBookTabLine",
  components: {
    ArrowCodeBolderIcon,
  },
  props: {
    tabList: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    currentTabIndex: 0,
  }),
  methods: {
    setActiveTab(tabIndex) {
      this.currentTabIndex = tabIndex
      this.$emit('change', tabIndex)
    },
    getTabName(tab, tabIndex) {
      return tab.name ?? tab.tabName ?? `Tab ${tabIndex}`
    }
  }
}
