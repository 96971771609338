//
//
//
//
//
//
//
//
//
//
//

import Form from "vform"
import ModalText from "~/components/modals/ModalText"

export default {
  components: {
    ModalText
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    hash: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showModal: true,
      title: 'Blog Subscription',
      msg: 'Please wait...',
      subscriber: new Form({
        'type': this.type,
        'hash': this.hash,
      })
    }
  },
  computed: {
    show() {
      if (
        this.hash !== '' &&
        (this.type === 'subscribe' || this.type === 'unsubscribe')
      ) {
        return true
      }
      return false
    }
  },
  async created() {
    if (this.type && this.hash) {
      try {
        const { data } = await this.subscriber.post(`/api/v1/blog-subscribe/confirm`)
        this.msg = data.message
      } catch (e) {
        this.msg = e.response.data.message
      }
    }
  },
  methods: {
    close() {
      this.showModal = false
      this.$router.push('/')
    }
  }
};
