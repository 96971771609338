//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex"
import AccountFranchisorStepCard from "./cards/AccountFranchisorStepCard";
import storeMixin from "../mixins/storeMixin";

export default {
  components: {
    AccountFranchisorStepCard
  },
  mixins: [
    storeMixin
  ],
  props: {
    stepList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      versionApproved: 'account-franchisor/versionApproved',
      paymentPermissions: 'payment/permissions',
      franchiseId: 'account-franchisor/franchiseId',
    })
  },
  methods: {
    showItemNumber(index) {
      return index < 9 ? `0${index + 1}` : `${index + 1}`;
    },
    handleClick(item) {
      if (!this.versionApproved) {
        if (this.user.isMasterAdmin) {
          this.fireModalMessage(
            { message: this.$t('accountFranchisor.accessMessages.notApprovedVersion') }
          )
        } else {
          this.fireModalMessage(
            { message: this.$t('accountFranchisor.accessMessages.notApprovedFranchisor') }
          )
        }

        return
      }

      if (this.user.isFranchisor && !this.paymentPermissions.chat.access) {
        this.fireModalMessage({ message: this.paymentPermissions.chat.message })
        return
      }

      this.$router.push(
        this.buildAccountFranchisorUri(`/account-franchisor/${this.franchiseId}/steps/${item.slug}`)
      )
    }
  },
}
