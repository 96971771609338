import { render, staticRenderFns } from "./TweetList.vue?vue&type=template&id=04b8673c&scoped=true&"
import script from "./TweetList.vue?vue&type=script&lang=js&"
export * from "./TweetList.vue?vue&type=script&lang=js&"
import style0 from "./TweetList.vue?vue&type=style&index=0&id=04b8673c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b8673c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/root/repo/components/Loader.vue').default})
